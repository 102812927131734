/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  Button, FormGroup, FormControlLabel, Checkbox, Select, MenuItem,
} from '@material-ui/core';
import HeaderReceta from '../../TabsPaciente/DiagnosticoPlan/Plan/TerapiaFarmacologica/Receta/Header';
import FooterReceta from '../../TabsPaciente/DiagnosticoPlan/Plan/TerapiaFarmacologica/Receta/Footer';
import { RootState } from '../../../store';
import { getConsultorio, getConsultorios } from '../../../actions/consultorios/actions';

function ConfigurarReceta() {
  const { t } = useTranslation();
  const { consultorios, consultorio } = useSelector((state: RootState) => state.Consultorios);
  const { idOrganizacion } = useSelector((state: RootState) => state.Organizaciones.organizacion);
  const { idMedico, carrera } = useSelector((state: RootState) => state.Me);
  const history = useHistory();
  const dispatch = useDispatch();
  const [idArrConsultorios, setIDArrConsultorios] = useState<number>(0);
  // const [checkboxState, setCheckboxState] = useState({
  //   email: false,
  //   celular: false,
  //   telefono1: false,
  //   telefono2: false,
  //   telefono3: false,
  // });

  // const {
  //   email, celular, telefono1, telefono2, telefono3,
  // } = checkboxState;

  // const handleChangeIncluirEnReceta = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setCheckboxState({ ...checkboxState, [event.target.name]: event.target.checked });
  // };

  useEffect(() => {
    dispatch(getConsultorios(idMedico, idOrganizacion));
  }, [idOrganizacion]);

  useEffect(() => {
    if (consultorios.length) {
      dispatch(getConsultorio(idMedico, consultorios[0].id));
    }
  }, [consultorios]);

  const handleChangeIDArrConsultorios = (event: React.ChangeEvent<{ value: unknown }>) => {
    const val = event.target.value as number;
    setIDArrConsultorios(val);
    dispatch(getConsultorio(idMedico, consultorios[val].id));
  };
  return (
    <div>
      <div className="flex justify-between mb-4">
        <h2 className="text-blue-800 font-normal text-center">{t('visualiza_tu_receta')}</h2>
        <Select
          variant="outlined"
          value={idArrConsultorios}
          onChange={handleChangeIDArrConsultorios}
        >
          {consultorios.map((cons, indx) => (
            <MenuItem value={indx}>{cons.label}</MenuItem>
          ))}
        </Select>
      </div>
      {/*
      <h3 className="text-gray-500 font-normal m-0">
        {t('_deseas_incluir_algunos_de_los_siguientes_datos_en_tus_recetas_')}
      </h3>
      <FormGroup row>
        <FormControlLabel
          control={(
            <Checkbox
              color="primary"
              checked={email}
              onChange={handleChangeIncluirEnReceta}
              name="email"
            />
          )}
          label={t('correo_electronico')}
        />
        <FormControlLabel
          control={(
            <Checkbox
              color="primary"
              checked={celular}
              onChange={handleChangeIncluirEnReceta}
              name="celular"
            />
          )}
          label={t('celular')}
        />
        <FormControlLabel
          control={(
            <Checkbox
              color="primary"
              checked={telefono1}
              onChange={handleChangeIncluirEnReceta}
              name="telefono1"
            />
          )}
          label={t('telefono1')}
        />
        <FormControlLabel
          control={(
            <Checkbox
              color="primary"
              checked={telefono2}
              onChange={handleChangeIncluirEnReceta}
              name="telefono2"
            />
          )}
          label={t('telefono2')}
        />
        <FormControlLabel
          control={(
            <Checkbox
              color="primary"
              checked={telefono3}
              onChange={handleChangeIncluirEnReceta}
              name="telefono3"
            />
          )}
          label={t('telefono3')}
        />
      </FormGroup> */}
      <div>
        <HeaderReceta consultorio={consultorio} />
        <div className="text-center text-8xl mt-10 mb-10">
          <p className="uppercase">{t('contenido')}</p>
        </div>
        <div className="mt-10">
          <FooterReceta consulta={false} consultorio={consultorio} />
        </div>
      </div>
      <div className="flex justify-end mt-4">
        <Button
          variant="contained"
          color="primary"
          type="button"
          size="large"
          onClick={() => history.push('/pacientes')}
          disabled={
            !(
              idMedico > 0
              && consultorio.idConsultorio > 0
              && carrera.cedula
              && carrera.escuela
              && carrera.titulo
            )
          }
        >
          {t('finalizar')}
        </Button>
      </div>
    </div>
  );
}

export default ConfigurarReceta;
