import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Dialog,
  DialogContent,
  Slide,
  IconButton,
  TextField,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
  MenuItem,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CloseIcon from '@material-ui/icons/Close';
import { TransitionProps } from '@material-ui/core/transitions';
import { constDia, constMes, constAnio } from '../../../../../../../constants/fechas';
import useStyles from './styles';
import { IModalAccidenteLaboralProps } from './types';
import { ICatalogosEstandar } from '../../../../../../../common/types';
import { validacionSoloNumeros } from '../../../../../../../constants/validaciones';

const Transition = React.forwardRef(
  (
    // eslint-disable-next-line react/require-default-props
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>,
  ) => <Slide direction="up" ref={ref} {...props} />,
);

function ModalAccidenteLaboral(props: IModalAccidenteLaboralProps) {
  const {
    open,
    callBackClose,
    datosCausaAccidente,
    datosNaturalezaLesion,
    datosTipoIncidencia,
    objArrayAccidentesLaborales,
    setObjArrayAccidentesLaborales,
    agregarValorArrayAccidentesLaborales,
    actualizarDatosGuardadoArray,
  } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const handleClose = (event: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway' || reason === 'backdropClick' || reason === 'escapeKeyDown') {
      return;
    }
    callBackClose(event);
    setObjArrayAccidentesLaborales({
      id: -1,
      fechaDia: '',
      fechaMes: '',
      fechaAnio: '',
      radioCausaAccidente: undefined,
      tipoCausaAccidente: { id: 0, label: '' },
      txtCausaAccidente: '',
      radioNaturalezaLesion: undefined,
      tipoNaturalezaLesion: { id: 0, label: '' },
      txtNaturalezaLesion: '',
      tipoIncidencia: { id: 0, label: '' },
      radioDiscapacidad: undefined,
      diasIncapacidad: undefined,
      comentarios: '',
    });
  };
  const sendToSave = (event: React.SyntheticEvent) => {
    agregarValorArrayAccidentesLaborales();
    callBackClose(event);
  };
  const revisionBotonGuardado = () => {
    let valDatos = false;
    if (
      objArrayAccidentesLaborales.radioCausaAccidente === undefined
      || objArrayAccidentesLaborales.fechaMes === undefined
      || objArrayAccidentesLaborales.fechaMes === ''
      || objArrayAccidentesLaborales.fechaAnio === undefined
      || objArrayAccidentesLaborales.fechaAnio === ''
      || (objArrayAccidentesLaborales.radioCausaAccidente === 'catalogo'
        && objArrayAccidentesLaborales.tipoCausaAccidente.id === 0)
      || (objArrayAccidentesLaborales.radioCausaAccidente === 'texto'
        && objArrayAccidentesLaborales.txtCausaAccidente.length === 0)
    ) {
      valDatos = true;
    }
    return valDatos;
  };
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      onClose={handleClose}
      fullWidth
      maxWidth="lg"
    >
      <div className="flex justify-between items-center pl-4 bg-blue-800 text-white">
        <h2 className="font-normal">{t('antecedentes_laborales_accidente')}</h2>
        <IconButton aria-label="cerrar" color="inherit">
          <CloseIcon onClick={(e) => handleClose(e)} />
        </IconButton>
      </div>
      <DialogContent className="text-center">
        <div className="flex flex-col">
          <h3 className="m-0 my-2 text-gray-600 font-medium text-left">
            {t('antecedentes_laborales_accidente_fecha')}
          </h3>
          <div className="w-full grid grid-cols-9 gap-2">
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="label-dia">{t('dia')}</InputLabel>
              <Select
                labelId="label-dia"
                id="select-dia"
                value={objArrayAccidentesLaborales.fechaDia}
                onChange={(event: React.ChangeEvent<{ value: unknown }>) => setObjArrayAccidentesLaborales({
                  ...objArrayAccidentesLaborales,
                  fechaDia: event.target.value as string,
                })}
                label={t('dia')}
                // error={fechaField.error}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 375,
                    },
                  },
                }}
              >
                {constDia.map((diaSingle, indx) => (
                  <MenuItem value={(indx + 1).toString()} key={diaSingle}>
                    {diaSingle}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText
                className={classes.absoluteBottom}
                style={{ textAlign: 'center', margin: '0', width: '100%' }}
              >
                {/* {fechaField.helperText} */}
              </FormHelperText>
            </FormControl>
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="label-mes">{`${t('mes')}*`}</InputLabel>
              <Select
                labelId="label-mes"
                id="select-mes"
                value={objArrayAccidentesLaborales.fechaMes}
                onChange={(event: React.ChangeEvent<{ value: unknown }>) => setObjArrayAccidentesLaborales({
                  ...objArrayAccidentesLaborales,
                  fechaMes: event.target.value as string,
                })}
                label={t('mes')}
                // error={fechaField.error}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 375,
                    },
                  },
                }}
              >
                {constMes.map((mesSingle, indx) => (
                  <MenuItem value={(indx + 1).toString()} key={mesSingle}>
                    {mesSingle.toUpperCase()}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText
                className={classes.absoluteBottom}
                style={{ textAlign: 'center', margin: '0', width: '100%' }}
              >
                {/* {fechaField.helperText} */}
              </FormHelperText>
            </FormControl>
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="label-año">{`${t('anio')}*`}</InputLabel>
              <Select
                labelId="label-año"
                id="select-año"
                value={objArrayAccidentesLaborales.fechaAnio}
                onChange={(event: React.ChangeEvent<{ value: unknown }>) => setObjArrayAccidentesLaborales({
                  ...objArrayAccidentesLaborales,
                  fechaAnio: event.target.value as string,
                })}
                label={t('anio')}
                // error={fechaField.error}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 375,
                    },
                  },
                }}
              >
                {constAnio().map((anioSingle) => (
                  <MenuItem key={anioSingle} value={anioSingle.toString()}>
                    {anioSingle}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText
                className={classes.absoluteBottom}
                style={{ textAlign: 'center', margin: '0', width: '100%' }}
              >
                {/* {fechaField.helperText} */}
              </FormHelperText>
            </FormControl>
          </div>
        </div>
        <div className="flex justify-start items-center m-0 my-2">
          <h3 className="m-0 text-gray-600 font-medium pr-4">
            {`${t('antecedentes_laborales_accidente_causa')}*`}
          </h3>
          <RadioGroup
            row
            aria-label="tipoAccidente"
            name="tipoAccidente"
            value={objArrayAccidentesLaborales.radioCausaAccidente}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setObjArrayAccidentesLaborales({
                ...objArrayAccidentesLaborales,
                radioCausaAccidente: (event.target as HTMLInputElement).value,
                tipoCausaAccidente: { id: 0, label: '' },
                txtCausaAccidente: '',
              });
            }}
          >
            <FormControlLabel
              value="catalogo"
              control={(
                <Radio
                  color="primary"
                  checked={objArrayAccidentesLaborales.radioCausaAccidente === 'catalogo'}
                />
              )}
              label={t('antecedentes_laborales_accidente_catalogo')}
            />
            <FormControlLabel
              value="texto"
              control={(
                <Radio
                  color="primary"
                  checked={objArrayAccidentesLaborales.radioCausaAccidente === 'texto'}
                />
              )}
              label={t('antecedentes_laborales_accidente_txt')}
            />
          </RadioGroup>
        </div>
        {objArrayAccidentesLaborales.radioCausaAccidente === 'catalogo' ? (
          <Autocomplete
            options={datosCausaAccidente}
            getOptionLabel={(option) => option.label}
            noOptionsText={t('sin_resultados')}
            value={objArrayAccidentesLaborales.tipoCausaAccidente}
            onChange={(e: any, nV: ICatalogosEstandar | null) => {
              setObjArrayAccidentesLaborales({
                ...objArrayAccidentesLaborales,
                tipoCausaAccidente: nV || { id: 0, label: '' },
              });
            }}
            fullWidth
            renderInput={(params) => (
              <TextField {...params} placeholder={t('selecciona')} variant="outlined" />
            )}
            disabled={objArrayAccidentesLaborales.radioCausaAccidente === undefined}
          />
        ) : (
          <TextField
            variant="outlined"
            value={objArrayAccidentesLaborales.txtCausaAccidente}
            placeholder={t('especifica')}
            fullWidth
            InputProps={{
              inputProps: {
                autoComplete: 'off',
                'aria-autocomplete': 'none',
                maxLength: 150,
              },
            }}
            onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
              setObjArrayAccidentesLaborales({
                ...objArrayAccidentesLaborales,
                txtCausaAccidente: event.target.value as string,
              });
            }}
            disabled={objArrayAccidentesLaborales.radioCausaAccidente === undefined}
          />
        )}
        <div className="flex justify-start items-center m-0 my-2">
          <h3 className="m-0 text-gray-600 font-medium pr-4">
            {t('antecedentes_laborales_accidente_tipo')}
          </h3>
          <RadioGroup
            row
            aria-label="tipoAccidente"
            name="tipoAccidente"
            value={objArrayAccidentesLaborales.radioNaturalezaLesion}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setObjArrayAccidentesLaborales({
                ...objArrayAccidentesLaborales,
                radioNaturalezaLesion: (event.target as HTMLInputElement).value,
                tipoNaturalezaLesion: { id: 0, label: '' },
                txtNaturalezaLesion: '',
              });
            }}
          >
            <FormControlLabel
              value="catalogo"
              control={(
                <Radio
                  color="primary"
                  checked={objArrayAccidentesLaborales.radioNaturalezaLesion === 'catalogo'}
                />
              )}
              label={t('antecedentes_laborales_accidente_catalogo')}
            />
            <FormControlLabel
              value="texto"
              control={(
                <Radio
                  color="primary"
                  checked={objArrayAccidentesLaborales.radioNaturalezaLesion === 'texto'}
                />
              )}
              label={t('antecedentes_laborales_accidente_txt')}
            />
          </RadioGroup>
        </div>
        {objArrayAccidentesLaborales.radioNaturalezaLesion === 'catalogo' ? (
          <Autocomplete
            options={datosNaturalezaLesion}
            getOptionLabel={(option) => option.label}
            noOptionsText={t('sin_resultados')}
            value={objArrayAccidentesLaborales.tipoNaturalezaLesion}
            onChange={(e: any, nV: ICatalogosEstandar | null) => {
              setObjArrayAccidentesLaborales({
                ...objArrayAccidentesLaborales,
                tipoNaturalezaLesion: nV || { id: 0, label: '' },
              });
            }}
            fullWidth
            renderInput={(params) => (
              <TextField {...params} placeholder={t('selecciona')} variant="outlined" />
            )}
            disabled={objArrayAccidentesLaborales.radioNaturalezaLesion === undefined}
          />
        ) : (
          <TextField
            variant="outlined"
            value={objArrayAccidentesLaborales.txtNaturalezaLesion}
            placeholder={t('especifica')}
            fullWidth
            InputProps={{
              inputProps: {
                autoComplete: 'off',
                'aria-autocomplete': 'none',
                maxLength: 150,
              },
            }}
            onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
              setObjArrayAccidentesLaborales({
                ...objArrayAccidentesLaborales,
                txtNaturalezaLesion: event.target.value as string,
              });
            }}
            disabled={objArrayAccidentesLaborales.radioNaturalezaLesion === undefined}
          />
        )}
        <div className="flex justify-start items-center m-0 mt-4 mb-2">
          <h3 className="m-0 text-gray-600 font-medium pr-4">
            {t('antecedentes_laborales_incidencia_tipo')}
          </h3>
        </div>
        <Autocomplete
          options={datosTipoIncidencia}
          getOptionLabel={(option) => t(option.label)}
          noOptionsText={t('sin_resultados')}
          value={objArrayAccidentesLaborales.tipoIncidencia}
          onChange={(e: any, nV: ICatalogosEstandar | null) => {
            setObjArrayAccidentesLaborales({
              ...objArrayAccidentesLaborales,
              tipoIncidencia: nV || { id: 0, label: '' },
            });
          }}
          fullWidth
          renderInput={(params) => (
            <TextField {...params} placeholder={t('selecciona')} variant="outlined" />
          )}
        />
        <div className="grid grid-cols-4 m-0 mt-4 mb-2">
          <div className="col-span-3 flex flex-col items-start">
            <h3 className="m-0 text-gray-600 font-medium pr-4">
              {t('antecedentes_laborales_accidente_incapacidad')}
            </h3>
            <RadioGroup
              row
              aria-label="tipoDiscapacidad"
              name="tipoDiscapacidad"
              value={objArrayAccidentesLaborales.radioDiscapacidad}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setObjArrayAccidentesLaborales({
                  ...objArrayAccidentesLaborales,
                  radioDiscapacidad: (event.target as HTMLInputElement).value,
                });
              }}
            >
              <FormControlLabel
                value="sin"
                control={(
                  <Radio
                    color="primary"
                    checked={objArrayAccidentesLaborales.radioDiscapacidad === 'sin'}
                  />
                )}
                label={t('antecedentes_laborales_accidente_incapacidad_sin')}
              />
              <FormControlLabel
                value="temporal"
                control={(
                  <Radio
                    color="primary"
                    checked={objArrayAccidentesLaborales.radioDiscapacidad === 'temporal'}
                  />
                )}
                label={t('antecedentes_laborales_accidente_incapacidad_temporal')}
              />
              <FormControlLabel
                value="parcial"
                control={(
                  <Radio
                    color="primary"
                    checked={objArrayAccidentesLaborales.radioDiscapacidad === 'parcial'}
                  />
                )}
                label={t('antecedentes_laborales_accidente_incapacidad_parcial')}
              />
              <FormControlLabel
                value="total"
                control={(
                  <Radio
                    color="primary"
                    checked={objArrayAccidentesLaborales.radioDiscapacidad === 'total'}
                  />
                )}
                label={t('antecedentes_laborales_accidente_incapacidad_total')}
              />
            </RadioGroup>
          </div>
          <div className="flex justify-start items-center ml-2">
            <h3 className="m-0 text-gray-600 text-left font-medium pr-4">
              {t('incapacidad_dias')}
            </h3>
            <TextField
              id="diasIncapacidad"
              value={objArrayAccidentesLaborales.diasIncapacidad}
              onChange={(e) => {
                const valorNumerico = e.target.value;
                if (valorNumerico === '' || validacionSoloNumeros(valorNumerico)) {
                  setObjArrayAccidentesLaborales({
                    ...objArrayAccidentesLaborales,
                    diasIncapacidad: parseInt(valorNumerico, 10),
                  });
                }
              }}
              inputProps={{
                maxLength: 2,
                autoComplete: 'off',
              }}
              variant="outlined"
              fullWidth
            />
          </div>
        </div>
        <div className="my-2">
          <h3 className="text-gray-600 font-normal m-0 mb-2 text-left">{t('comentarios')}</h3>
          <TextField
            variant="outlined"
            fullWidth
            value={objArrayAccidentesLaborales.comentarios}
            onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
              setObjArrayAccidentesLaborales({
                ...objArrayAccidentesLaborales,
                comentarios: event.target.value as string,
              });
            }}
            inputProps={{ maxLength: 500, autoComplete: 'off' }}
            multiline
            rows="4"
          />
        </div>
        <div className="flex justify-between">
          <p className="text-gray-600">
            * &nbsp;
            {t('campos_obligatorios')}
          </p>
          <div className="flex flex-row">
            <div className="pr-2">
              <Button
                variant="contained"
                color="primary"
                type="button"
                size="large"
                onClick={(e) => {
                  handleClose(e);
                }}
              >
                {t('cancelar')}
              </Button>
            </div>
            <div>
              {objArrayAccidentesLaborales && objArrayAccidentesLaborales.id === -1 ? (
                <Button
                  variant="contained"
                  color="primary"
                  type="button"
                  size="large"
                  onClick={(event) => sendToSave(event)}
                  disabled={revisionBotonGuardado()}
                >
                  {t('guardar')}
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  type="button"
                  size="large"
                  onClick={() => actualizarDatosGuardadoArray()}
                  disabled={revisionBotonGuardado()}
                >
                  {t('actualizar')}
                </Button>
              )}
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default ModalAccidenteLaboral;
