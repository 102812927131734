import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  InputAdornment,
  IconButton,
  Tooltip,
} from '@material-ui/core';
import dayjs from 'dayjs';
import Autocomplete from '@material-ui/lab/Autocomplete';
import LockIcon from '@material-ui/icons/Lock';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { useDispatch, useSelector } from 'react-redux';
import { KeyboardCapslock, Visibility, VisibilityOff } from '@material-ui/icons';
import PhoneInput from 'react-phone-input-2';
import es from 'react-phone-input-2/lang/es.json';
import 'react-phone-input-2/lib/bootstrap.css';
import { setSnackComplete } from '../../../actions/snackbar/types';
import { sendEnviaAdministradorBienvenida } from '../../../utils/sendInfo';
import { validarFecha, validarFechaEsAnterior } from '../../../common/functions';
import { constDia, constMes, constAnio } from '../../../constants/fechas';
import {
  docIdLongitudMax,
  validacionDocIdOnChange,
  validacionEmail,
} from '../../../constants/validaciones';
import {
  IFechaField,
  ITextFieldEstandar,
  ICountryPhoneInput,
  ICatStrEstandar,
} from '../../../common/types';
import { RootState } from '../../../store';
import DialogPassword from '../../../components/ModalDialogs/DialogPassword/index';
import useStyles from './styles';
import { IPerfilAdmonProps } from './types';

dayjs.locale('es');

const PerfilAdministrador = (props: IPerfilAdmonProps) => {
  const { t } = useTranslation();
  const {
    activeStep,
    keyBack,
    objAdministrador,
    objAdminTmp,
    setObjAdminTmp,
    catNacionalidad,
    disableDocId,
    setDisabledSave,
    openAndDeleteAdmon,
  } = props;
  const { email, pais } = useSelector((state: RootState) => state.Me);
  const { idOrganizacion } = useSelector((state: RootState) => state.Organizaciones.organizacion);
  const dispatch = useDispatch();
  const classes = useStyles();
  const [anyoFur] = useState<number[]>(constAnio);
  const [fechaField, setFechaField] = useState<IFechaField>({
    error: false,
    helperText: '',
  });
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [emailError, setEmailError] = useState<any>({ helperText: '', error: false });
  const [docIdField, setDocIdField] = useState<ITextFieldEstandar>({
    helperText: '',
    error: false,
  });
  // password
  const [contrasenia, setContrasenia] = useState<string>('');
  const [contraseniaRepetida, setContraseniaRepetida] = useState<string>('');
  const [errorPass, setErrorPass] = useState({
    password: { error: false, helperText: '' },
    passwordRepetido: { error: false, helperText: '' },
  });
  const [verPassword, setVerPassword] = useState<boolean>(false);
  const [verPasswordRepetido, setVerPasswordRepetido] = useState<boolean>(false);
  const [isPasswordCapsLockOn, setIsPasswordCapsLockOn] = useState(false);
  const [isPasswordRepetidoCapsLockOn, setIsPasswordRepetidoCapsLockOn] = useState(false);
  const handleChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value as string;
    setObjAdminTmp({ ...objAdminTmp, password: val });
  };
  const handleChangePasswordRepetido = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value as string;
    setContraseniaRepetida(val);
  };
  const handleClickVerPasswordRepetido = () => {
    setVerPasswordRepetido((prev) => !prev);
  };
  const handleClickVerPassword = () => {
    setVerPassword((prev) => !prev);
  };
  const handleFocusPassword = () => {
    setErrorPass({
      password: { error: false, helperText: '' },
      passwordRepetido: { error: false, helperText: '' },
    });
  };
  const handleMouseDownPasswordRepetido = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };
  const checkPasswordCapsLock = (event: any) => {
    setIsPasswordCapsLockOn(event.getModifierState('CapsLock'));
  };
  const checkPasswordRepetidoCapsLock = (event: any) => {
    setIsPasswordRepetidoCapsLockOn(event.getModifierState('CapsLock'));
  };
  const handleBlurEmail = (event: React.ChangeEvent<{ value: unknown }>) => {
    const val = event.target.value as string;
    if (!val.length || validacionEmail(val)) {
      setEmailError({ helperText: '', error: false });
    } else {
      setEmailError({ helperText: t('email_incorrecto'), error: true });
    }
  };
  const handleChangeSexo = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value as string;
    setObjAdminTmp({ ...objAdminTmp, sexo: val });
  };
  const handleChangeDate = (event: React.ChangeEvent<{ value: unknown }>, tipo: string) => {
    const dataUpd = { ...objAdminTmp };
    const valAdd = event.target.value as string;
    if (tipo === 'dia') {
      dataUpd.fechaNac.dia = valAdd;
    } else if (tipo === 'mes') {
      dataUpd.fechaNac.mes = valAdd;
    } else if (tipo === 'anio') {
      dataUpd.fechaNac.anio = valAdd;
    }
    setObjAdminTmp(dataUpd);
  };
  const handleChangeTxt = (event: React.ChangeEvent<HTMLInputElement>, tipo: string) => {
    const val = event.target.value as string;
    const dataUpd = { ...objAdminTmp };
    if (tipo === 'nombre') {
      dataUpd.nombre = val;
    } else if (tipo === 'apellidoP') {
      dataUpd.apellidoP = val;
    } else if (tipo === 'apellidoS') {
      dataUpd.apellidoS = val;
    } else if (tipo === 'docId') {
      if (validacionDocIdOnChange(val, pais)) {
        setDocIdField({ helperText: '', error: false });
        dataUpd.docId = val;
      } else {
        setDocIdField({ helperText: t(`doc_id_incorrecto_${pais}`), error: true });
      }
    } else if (tipo === 'email') {
      dataUpd.email = val.toLowerCase();
    }
    setObjAdminTmp(dataUpd);
  };
  const handleChangeNacionalidad = (_event: any, newValue: ICatStrEstandar | null) => {
    if (newValue) {
      setObjAdminTmp({ ...objAdminTmp, nacionalidad: newValue });
    } else {
      setObjAdminTmp({ ...objAdminTmp, nacionalidad: { id: '', label: '' } });
    }
  };
  const limpiarAdministrador = () => setObjAdminTmp({
    nombre: '',
    apellidoP: '',
    apellidoS: '',
    docId: '',
    sexo: '',
    fechaNac: {
      dia: '',
      mes: '',
      anio: '',
    },
    celular: {
      numero: '',
      codigo: '+52',
    },
    telefono: {
      numero: '',
      codigo: '+52',
    },
    email: '',
    emailLog: '',
    nacionalidad: {
      id: '',
      label: '',
    },
    id: -1,
    password: '',
  });

  useEffect(() => {
    const rdia = objAdminTmp.fechaNac.dia;
    const rmes = objAdminTmp.fechaNac.mes;
    const ranio = objAdminTmp.fechaNac.anio;
    if (rdia !== '' && rmes !== '' && ranio !== '') {
      const objF = { ...fechaField };
      objF.error = false;
      objF.helperText = '';
      if (!validarFecha(ranio, rmes, rdia) || !validarFechaEsAnterior(ranio, rmes, rdia)) {
        objF.error = true;
        objF.helperText = t('fecha_invalida');
      }
      setFechaField(objF);
    }
  }, [objAdminTmp.fechaNac.dia, objAdminTmp.fechaNac.mes, objAdminTmp.fechaNac.anio]);
  useEffect(() => {
    if (objAdminTmp.password?.length === 0 || objAdminTmp.password?.length >= 8) {
      setContrasenia(objAdminTmp.password);
      if (objAdminTmp.password?.length === 0) {
        setContraseniaRepetida('');
      } else if (contraseniaRepetida.length !== 0 && objAdminTmp.password !== contraseniaRepetida) {
        setErrorPass({
          password: { error: false, helperText: '' },
          passwordRepetido: { error: true, helperText: t('las_contrasenias_no_coinciden') },
        });
      } else {
        setErrorPass({
          password: { error: false, helperText: '' },
          passwordRepetido: { error: false, helperText: '' },
        });
      }
    } else if (
      objAdminTmp.password?.length !== 0
      && objAdminTmp.password?.length < contrasenia.length
    ) {
      setErrorPass({
        password: { error: true, helperText: t('la_contrasenia_debe_tener_al_menos_8_caracteres') },
        passwordRepetido: { error: false, helperText: '' },
      });
      setContraseniaRepetida('');
    }
  }, [objAdminTmp.password]);
  useEffect(() => {
    if (contraseniaRepetida.length !== 0 && objAdminTmp.password !== contraseniaRepetida) {
      setErrorPass({
        password: { error: false, helperText: '' },
        passwordRepetido: { error: true, helperText: t('las_contrasenias_no_coinciden') },
      });
    } else {
      setErrorPass({
        password: { error: false, helperText: '' },
        passwordRepetido: { error: false, helperText: '' },
      });
    }
  }, [contraseniaRepetida]);
  useEffect(() => {
    setDisabledSave(
      (activeStep === 0 && objAdminTmp.id === -1)
        || fechaField.error
        || objAdminTmp.nombre.length === 0
        || objAdminTmp.apellidoP.length === 0
        || (pais === 'mx' && objAdminTmp.docId.length < 18)
        || (pais === 'uy' && objAdminTmp.docId.length < 6)
        || objAdminTmp.sexo.length === 0
        || objAdminTmp.fechaNac.dia.length === 0
        || objAdminTmp.fechaNac.mes.length === 0
        || objAdminTmp.fechaNac.anio.length === 0
        || objAdminTmp.nacionalidad.id.length === 0
        || objAdminTmp.celular.numero.length === 0
        || objAdminTmp.email.length === 0
        || (activeStep === 2
          && ((objAdminTmp.id === -1
            && objAdminTmp.password?.length < 8
            && objAdminTmp.password !== contraseniaRepetida)
            || (objAdministrador.id !== -1 && objAdminTmp.id === -1))),
    );
  }, [objAdminTmp]);

  return (
    <div className="p-2 bg-white">
      <DialogPassword
        keyBack={keyBack}
        open={showDialog}
        callBackClose={() => {
          setShowDialog(!showDialog);
        }}
      />
      <div className="border-solid border-2 border-gray-300 rounded-lg p-4">
        <div className="flex flex-col justify-center">
          <h1 className="text-blue-500 font-normal m-2 text-center">
            {activeStep === 0
              ? t('administrador_mis_datos')
              : t('administrador_invitar_administrador')}
          </h1>
        </div>
        <div className="grid grid-cols-2 gap-4">
          <div>
            <h4 className="text-gray-500 font-normal mt-0 mb-2">{`${t('nombre(s)')}*`}</h4>
            <TextField
              variant="outlined"
              type="nombre"
              value={objAdminTmp.nombre}
              onChange={(e: any) => handleChangeTxt(e, 'nombre')}
              fullWidth
              inputProps={{ maxLength: 100, autoComplete: 'off', 'aria-autocomplete': 'none' }}
              placeholder={t('especifica')}
              disabled={activeStep === 2 && objAdministrador.id !== -1 && objAdminTmp.id === -1}
            />
          </div>
          <div>
            <h4 className="text-gray-500 font-normal mt-0 mb-2">{`${t('primer_apellido')}*`}</h4>
            <TextField
              variant="outlined"
              type="apellidoP"
              value={objAdminTmp.apellidoP}
              onChange={(e: any) => handleChangeTxt(e, 'apellidoP')}
              fullWidth
              inputProps={{ maxLength: 50, autoComplete: 'off', 'aria-autocomplete': 'none' }}
              placeholder={t('especifica')}
              disabled={activeStep === 2 && objAdministrador.id !== -1 && objAdminTmp.id === -1}
            />
          </div>
          <div>
            <h4 className="text-gray-500 font-normal mt-0 mb-2">{t('segundo_apellido')}</h4>
            <TextField
              variant="outlined"
              type="apellidoS"
              value={objAdminTmp.apellidoS}
              onChange={(e: any) => handleChangeTxt(e, 'apellidoS')}
              fullWidth
              inputProps={{ maxLength: 50, autoComplete: 'off', 'aria-autocomplete': 'none' }}
              placeholder={t('especifica')}
              disabled={activeStep === 2 && objAdministrador.id !== -1 && objAdminTmp.id === -1}
            />
          </div>
          <div>
            <h4 className="text-gray-500 font-normal mt-0 mb-2">{`${t(`doc_id_${pais}`)}*`}</h4>
            <TextField
              variant="outlined"
              type="docId"
              value={objAdminTmp.docId}
              onChange={(e: any) => handleChangeTxt(e, 'docId')}
              fullWidth
              inputProps={{
                maxLength: docIdLongitudMax(pais),
                autoComplete: 'off',
                'aria-autocomplete': 'none',
                style: { textTransform: objAdminTmp.docId.length ? 'uppercase' : 'none' },
              }}
              placeholder={t(`especifica_debe_contener_n_caracteres_${pais}`)}
              error={docIdField.error}
              helperText={docIdField.helperText}
              FormHelperTextProps={{ className: classes.absoluteBottom }}
              disabled={
                (activeStep === 0 && disableDocId)
                || (activeStep === 2 && objAdministrador.id !== -1)
              }
            />
          </div>
        </div>
        <div className="grid grid-cols-4 gap-4 mt-4">
          <div>
            <h4 className="m-0 mb-3 text-gray-500 font-normal">{`${t('sexo')}*`}</h4>
            <RadioGroup row name="sexo" value={objAdminTmp.sexo} onChange={handleChangeSexo}>
              <FormControlLabel
                value="H"
                control={<Radio color="primary" />}
                label={t('hombre')}
                labelPlacement="end"
                disabled={activeStep === 2 && objAdministrador.id !== -1 && objAdminTmp.id === -1}
              />
              <FormControlLabel
                value="M"
                control={<Radio color="primary" />}
                label={t('mujer')}
                labelPlacement="end"
                disabled={activeStep === 2 && objAdministrador.id !== -1 && objAdminTmp.id === -1}
              />
            </RadioGroup>
          </div>
          <div className="w-full grid grid-cols-3 gap-2">
            <h4 className="text-gray-500 font-normal m-0 col-span-3">
              {`${t('fecha_nacimiento')}*`}
            </h4>
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="label-dia">{t('dia')}</InputLabel>
              <Select
                labelId="label-dia"
                id="select-dia"
                value={objAdminTmp.fechaNac.dia}
                onChange={(e) => handleChangeDate(e, 'dia')}
                label={t('dia')}
                error={fechaField.error}
                disabled={activeStep === 2 && objAdministrador.id !== -1 && objAdminTmp.id === -1}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 375,
                    },
                  },
                }}
              >
                {constDia.map((diaSingle) => (
                  <MenuItem value={diaSingle.toString()} key={diaSingle}>
                    {diaSingle}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="label-mes">{t('mes')}</InputLabel>
              <Select
                labelId="label-mes"
                id="select-mes"
                value={objAdminTmp.fechaNac.mes}
                onChange={(e) => handleChangeDate(e, 'mes')}
                label={t('mes')}
                error={fechaField.error}
                disabled={activeStep === 2 && objAdministrador.id !== -1 && objAdminTmp.id === -1}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 375,
                    },
                  },
                }}
              >
                {constMes.map((mesSingle, indx) => (
                  <MenuItem value={(indx + 1).toString()} key={mesSingle}>
                    {mesSingle.toUpperCase()}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText className={classes.absoluteBottom}>
                {fechaField.helperText}
              </FormHelperText>
            </FormControl>
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="label-año">{t('anio')}</InputLabel>
              <Select
                labelId="label-año"
                id="select-año"
                value={objAdminTmp.fechaNac.anio}
                onChange={(e) => handleChangeDate(e, 'anio')}
                label={t('anio')}
                error={fechaField.error}
                disabled={activeStep === 2 && objAdministrador.id !== -1 && objAdminTmp.id === -1}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 375,
                    },
                  },
                }}
              >
                {anyoFur.map((anioSingle) => (
                  <MenuItem key={anioSingle} value={anioSingle.toString()}>
                    {anioSingle}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div>
            <h4 className="text-gray-500 font-normal m-0 mb-2">{`${t('telefono_celular')}*`}</h4>
            <PhoneInput
              preferredCountries={[pais, 'us']}
              country={pais}
              localization={es}
              enableSearch
              searchPlaceholder={t('buscar')}
              searchNotFound={t('sin_resultados')}
              inputStyle={{ width: '100%' }}
              value={`${objAdminTmp.celular.codigo}${objAdminTmp.celular.numero}`}
              onChange={(value, country: ICountryPhoneInput) => {
                const { dialCode } = country;
                const nuevoCel = value.slice(dialCode.length);
                const dataUpd = { ...objAdminTmp };
                dataUpd.celular.numero = nuevoCel;
                dataUpd.celular.codigo = country.dialCode;
                setObjAdminTmp(dataUpd);
              }}
              disabled={activeStep === 2 && objAdministrador.id !== -1 && objAdminTmp.id === -1}
            />
          </div>
          <div>
            <h4 className="text-gray-500 font-normal m-0 mb-2">{t('telefono_fijo')}</h4>
            <PhoneInput
              preferredCountries={[pais, 'us']}
              country={pais}
              localization={es}
              enableSearch
              searchPlaceholder={t('buscar')}
              searchNotFound={t('sin_resultados')}
              inputStyle={{ width: '100%' }}
              value={`${objAdminTmp.telefono.codigo}${objAdminTmp.telefono.numero}`}
              onChange={(value, country: ICountryPhoneInput) => {
                const { dialCode } = country;
                const nuevoCel = value.slice(dialCode.length);
                const dataUpd = { ...objAdminTmp };
                dataUpd.telefono.numero = nuevoCel;
                dataUpd.telefono.codigo = country.dialCode;
                setObjAdminTmp(dataUpd);
              }}
              disabled={activeStep === 2 && objAdministrador.id !== -1 && objAdminTmp.id === -1}
            />
          </div>
        </div>
        <div className="grid grid-cols-4 gap-4 pt-2">
          <div className="col-span-2">
            <h4 className="text-gray-500 font-normal mt-0 mb-2">{`${t('email')}*`}</h4>
            <TextField
              variant="outlined"
              type="email"
              value={objAdminTmp.email}
              placeholder={t('especifica')}
              onChange={(e: any) => handleChangeTxt(e, 'email')}
              fullWidth
              error={emailError.error}
              helperText={emailError.helperText}
              FormHelperTextProps={{ className: classes.absoluteBottom }}
              inputProps={{ maxLength: 100, autoComplete: 'off', 'aria-autocomplete': 'none' }}
              onBlur={handleBlurEmail}
              disabled={activeStep === 0 || (activeStep === 2 && objAdministrador.id !== -1)}
            />
          </div>
          {activeStep === 0 && (
            <div className="flex items-end">
              <Button
                color="primary"
                onClick={() => {
                  setShowDialog(!showDialog);
                }}
                className={classes.noTextTranform}
                startIcon={<LockIcon />}
              >
                {t('medico_perfil_identificacion_cambiar')}
              </Button>
            </div>
          )}
          <div>
            <h4 className="text-gray-500 font-normal mt-0 mb-2">{`${t('nacionalidad')}*`}</h4>
            <Autocomplete
              options={catNacionalidad}
              getOptionLabel={(option) => option.label}
              noOptionsText={t('sin_resultados')}
              value={objAdminTmp.nacionalidad}
              onChange={(e, nV) => handleChangeNacionalidad(e, nV)}
              fullWidth
              autoComplete={false}
              renderInput={(params) => (
                <TextField {...params} placeholder={t('selecciona')} variant="outlined" />
              )}
              disabled={activeStep === 2 && objAdministrador.id !== -1 && objAdminTmp.id === -1}
            />
          </div>
        </div>
        {activeStep === 2 && objAdminTmp.id === -1 && (
          <div className="grid grid-cols-2 gap-4 pt-2">
            <div>
              <h4 className="text-gray-500 font-normal mt-0 mb-2">{`${t('contrasenia')}*`}</h4>
              <TextField
                error={errorPass.password.error}
                variant="outlined"
                type={verPassword ? 'text' : 'password'}
                fullWidth
                value={objAdminTmp.password}
                disabled={objAdministrador.id !== -1}
                onChange={handleChangePassword}
                onFocus={handleFocusPassword}
                onKeyUp={checkPasswordCapsLock}
                onKeyDown={checkPasswordCapsLock}
                onCopy={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
                onCut={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
                onPaste={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
                helperText={errorPass.password.helperText}
                FormHelperTextProps={{ className: classes.absoluteBottom }}
                InputProps={{
                  endAdornment: (
                    <div className="text-blue-500">
                      <InputAdornment position="end">
                        {isPasswordCapsLockOn ? (
                          <Tooltip title={t('mayusculas_activadas')}>
                            <KeyboardCapslock />
                          </Tooltip>
                        ) : (
                          ''
                        )}
                        <IconButton
                          onClick={handleClickVerPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                          color="inherit"
                          disabled={objAdministrador.id !== -1}
                        >
                          {verPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    </div>
                  ),
                  inputProps: {
                    autoComplete: 'off',
                    'aria-autocomplete': 'none',
                    maxLength: '50',
                  },
                }}
              />
            </div>
            <div>
              <h4 className="text-gray-500 font-normal mt-0 mb-2">
                {`${t('repetir_contrasenia')}*`}
              </h4>
              <TextField
                error={errorPass.passwordRepetido.error}
                variant="outlined"
                type={verPasswordRepetido ? 'text' : 'password'}
                onFocus={handleFocusPassword}
                onKeyDown={checkPasswordRepetidoCapsLock}
                onKeyUp={checkPasswordRepetidoCapsLock}
                fullWidth
                value={contraseniaRepetida}
                disabled={objAdminTmp.password.length < 8 || errorPass.password.error}
                onChange={handleChangePasswordRepetido}
                onCopy={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
                onCut={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
                onPaste={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
                helperText={errorPass.passwordRepetido.helperText}
                FormHelperTextProps={{ className: classes.absoluteBottom }}
                autoComplete="off"
                InputProps={{
                  endAdornment: (
                    <div className="text-blue-500">
                      <InputAdornment position="end">
                        {isPasswordRepetidoCapsLockOn ? (
                          <Tooltip title={t('mayusculas_activadas')}>
                            <KeyboardCapslock />
                          </Tooltip>
                        ) : (
                          ''
                        )}
                        <IconButton
                          onClick={handleClickVerPasswordRepetido}
                          onMouseDown={handleMouseDownPasswordRepetido}
                          edge="end"
                          color="inherit"
                          disabled={objAdministrador.id !== -1}
                        >
                          {verPasswordRepetido ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    </div>
                  ),
                  inputProps: {
                    autoComplete: 'off',
                    'aria-autocomplete': 'none',
                    maxLength: '50',
                  },
                }}
              />
            </div>
          </div>
        )}
        <div className="pt-4">
          <h4 className="text-gray-500 font-normal m-0">{t('campo-obligatorio')}</h4>
        </div>
        {activeStep === 2 && (
          <div className="text-right pt-4">
            <Button
              aria-label="cancel"
              color="primary"
              variant="contained"
              disabled={objAdminTmp.id === -1}
              onClick={limpiarAdministrador}
            >
              {`${t('cancelar')}`}
            </Button>
          </div>
        )}
      </div>
      {activeStep === 2 && objAdministrador.id !== -1 && (
        <>
          <div className="p-2" />
          <hr />
          <h2 className="text-blue-500 font-normal m-0 pt-2">{t('administrador_invitado')}</h2>
          <div className="shadow-md border border-solid border-gray-100 rounded mt-4 flex justify-between relative">
            <div className="border-0 border-solid border-blue-500 rounded border-l-8 p-4 pr-5 w-full">
              <h3 className="m-0 overflow-ellipsis overflow-hidden font-normal text-gray-800">
                {`${objAdministrador.nombre} ${objAdministrador.apellidoP} ${objAdministrador.apellidoS}`}
              </h3>
              <h4 className="font-normal text-gray-500 m-0 mr-4">
                {`${t(`doc_id_${pais}`)}: ${objAdministrador.docId.toUpperCase()}`}
              </h4>
              <h4 className="font-normal text-gray-500 m-0 mr-4">
                {`${t('sexo')}: ${objAdministrador.sexo === 'H' ? 'Hombre' : 'Mujer'}`}
              </h4>
              <h4 className="font-normal text-gray-500 m-0 mr-4">
                {`${t('fecha_nacimiento')}: ${
                  objAdministrador.fechaNac.dia
                  && objAdministrador.fechaNac.mes
                  && objAdministrador.fechaNac.anio
                  && dayjs(
                    `${objAdministrador.fechaNac.anio}-${objAdministrador.fechaNac.mes}-${objAdministrador.fechaNac.dia}`,
                  ).format('DD-MMM-YYYY')
                }`}
              </h4>
              <h4 className="font-normal text-gray-500 m-0 mr-4">
                {`${t('telefono_celular')}: ${
                  objAdministrador.celular.codigo + objAdministrador.celular.numero || ''
                } - ${t('telefono_fijo')}: ${
                  objAdministrador.telefono.codigo + objAdministrador.telefono.numero || ''
                }`}
              </h4>
              <div className="flex items-center m-0">
                <h4 className="font-normal m-0 text-gray-500 mr-4 pr-6">
                  {`${t('email')}: ${objAdministrador.email}`}
                </h4>
                <Button
                  color="primary"
                  size="small"
                  onClick={() => {
                    sendEnviaAdministradorBienvenida(objAdministrador.email, email, idOrganizacion)
                      .then((response) => {
                        if (response.status === 200) {
                          dispatch(
                            setSnackComplete({
                              open: true,
                              severity: 'success',
                              mensaje: t('email_enviado_correcto'),
                            }),
                          );
                        } else {
                          dispatch(
                            setSnackComplete({
                              open: true,
                              severity: 'error',
                              mensaje: t('email_enviado_error'),
                            }),
                          );
                        }
                      })
                      .catch((err) => {
                        dispatch(
                          setSnackComplete({
                            open: true,
                            severity: 'error',
                            mensaje: `${t('medico_perfil_error_400')} ${err.toString()}`,
                          }),
                        );
                      });
                  }}
                  className={classes.noTextTranform}
                >
                  {t('reenviar_correo_invitacion')}
                </Button>
              </div>
              <h4 className="font-normal text-gray-500 m-0 mr-4">
                {`${t('nacionalidad')}: ${objAdministrador.nacionalidad.label}`}
              </h4>
            </div>
            <div className="min-w-max self-center">
              <IconButton
                aria-label="edit"
                color="primary"
                onClick={() => {
                  setObjAdminTmp({ ...objAdministrador, password: '*' });
                  window.scrollTo({ top: 370, left: 0, behavior: 'smooth' });
                }}
              >
                <EditIcon />
              </IconButton>
              <IconButton
                aria-label="delete"
                color="primary"
                disabled={objAdminTmp.id !== -1}
                onClick={() => openAndDeleteAdmon(objAdministrador.id)}
              >
                <DeleteIcon />
              </IconButton>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default PerfilAdministrador;
