import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, TextField } from '@material-ui/core';
import dayjs from 'dayjs';
import SearchIcon from '@material-ui/icons/Search';
import Clear from '@material-ui/icons/Clear';
import { validacionDocId } from '../../constants/validaciones';
import { RootState } from '../../store';
// import { ReactComponent as BackupTableIcon } from '../../assets/icons/backup_table.svg';
import { setLoading } from '../../actions/loading/actions';
import { IPacienteTabla } from './types';
import {
  getPacientesTabla,
  getPacientesTablaBusqueda,
  getExistenciaPaciente,
  verificarTokenPaciente,
} from '../../utils/getCatalogos';
import {
  getPaciente,
  resetPaciente,
  setPacienteDocId,
  setPacienteSinDocId,
  setPacienteID,
} from '../../actions/basicosPaciente/actions';
import {
  // crearAsociacionMedicoPacienteConsultorio,
  crearConsultaMedica,
  sendTokenPaciente,
} from '../../utils/sendInfo';
import { setSnackComplete } from '../../actions/snackbar/types';
import { setConsulta } from '../../actions/consulta/actions';
import {
  setIdConsulta as setIdConsultaSession,
  setPaciente as sessionPaciente,
} from '../../utils/common';
import { editConsultasMedicasFinalizarConsulta } from '../../utils/editInfo';
import ModalConsultaAbierta from '../../components/AlertDialogs/AlertDialog';
import DocIdCorrectoPaciente from '../../components/ModalDialogs/DocIdCorrectoPaciente/index';
import IniciarConsultaModal from '../../components/ModalDialogs/IniciarConsulta/index';
import ConsultaNoMedipraxi from '../../components/ModalDialogs/ConsultaNoMedipraxi/index';
import { removeIdConsulta, removePaciente, removeUserSession } from '../../utils/commonStore';
import { resetMe } from '../../actions/me/actions';
import { limpiarRedux } from '../../utils/reduxCommon';
import { setAuth } from '../../actions/auth/actions';
import TablaPacientes from './TablaPacientes';
import IniciarConsulta from './IniciarConsulta';

dayjs.locale('es');

const PacienteComponent = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { idMedico, enOrganizacion, pais } = useSelector((state: RootState) => state.Me);
  const { idConsulta } = useSelector((state: RootState) => state.Consulta);
  const { idPaciente } = useSelector((state: RootState) => state.BasicosPaciente);
  const {
    consultorio: { idConsultorio },
  } = useSelector((state: RootState) => state.Consultorios);
  const { idOrganizacion } = useSelector((state: RootState) => state.Organizaciones.organizacion);
  const columnas = [
    {
      Header: t('pacientes-paciente'),
      accessor: 'paciente',
    },
    {
      Header: t('pacientes-acciones'),
      accessor: 'acciones',
    },
    {
      Header: t('pacientes-datos'),
      accessor: 'datos',
    },
    {
      Header: t('pacientes-consulta'),
      accessor: 'consulta',
    },
    {
      Header: t('pacientes-proxima'),
      accessor: 'proxima',
    },
  ];
  const [filtro, setFiltro] = useState<string>('');
  const [filtroBusqueda, setFiltroBusqueda] = useState<string>('');
  const [filtrado, setFiltrado] = useState<boolean>(false);
  const [arrayTabla, setArrayTabla] = useState<IPacienteTabla[]>([]);
  const [canPreviousPage, setCanPreviousPage] = useState<boolean>(false);
  const [canNextPage, setCanNextPage] = useState<boolean>(false);
  const [totalDatos, setTotalDatos] = useState<number>(0);
  const [pageCount, setPageCount] = useState<number>(0);
  const [pageIndex, setPageIndex] = useState<number>(0);
  const [ordenarPor, setOrdenarPor] = useState<string>('id');
  const [docId, setDocId] = useState<string>('');
  const [error, setError] = useState<boolean>(false);
  const [sinDocId, setSinDocId] = useState<boolean>(false);
  const [modalConsultaAbierta, setModalConsultaAbierta] = useState<boolean>(false);
  const [pasoModal, setPasoModal] = useState<number>(-1);
  const [showVerificaDocId, setShowVerificaDocId] = useState<boolean>(false);
  const [showVerificaPermiso, setShowVerificaPermiso] = useState<boolean>(false);
  const [showSinPermiso, setShowSinPermiso] = useState<boolean>(false);
  const [showNoMedipraxi, setShowNoMedipraxi] = useState<boolean>(false);
  const [pass, setPass] = useState<string>('');
  const rowsPerPage = 20;
  const evalData = (event: React.ChangeEvent<{ value: string }>) => {
    let val = event.target.value;
    if (pais === 'mx') {
      val = val.toUpperCase();
    }
    if (val.length && val.indexOf('-') > -1) {
      setError(false);
    } else if (val.length && validacionDocId(val, pais)) {
      setError(false);
    } else {
      setError(true);
    }
    setDocId(val);
  };
  // Cierres de modal
  const cerrarDocIdCorrectoModal = (
    event: React.SyntheticEvent | React.MouseEvent,
    reason?: string,
  ) => {
    if (reason === 'clickaway' || reason === 'backdropClick') {
      return;
    }
    setShowVerificaDocId(false);
  };
  const cerrarIniciarConsultaModal = (
    event: React.SyntheticEvent | React.MouseEvent,
    reason?: string,
  ) => {
    if (reason === 'clickaway' || reason === 'backdropClick') {
      return;
    }
    setShowSinPermiso(false);
    setShowVerificaPermiso(false);
  };
  const cerrarConsultaNoMedipraxiModal = (
    event: React.SyntheticEvent | React.MouseEvent,
    reason?: string,
  ) => {
    if (reason === 'clickaway' || reason === 'backdropClick') {
      return;
    }
    setShowNoMedipraxi(false);
  };
  const cerrarConsultaModalConsultaAbiertaModal = (
    event: React.SyntheticEvent | React.MouseEvent,
    reason?: string,
  ) => {
    if (reason === 'clickaway' || reason === 'backdropClick') {
      return;
    }
    setModalConsultaAbierta(false);
    setPasoModal(-1);
  };
  const iniciarConsultaPacienteNuevo = () => {
    dispatch(setLoading(true));
    crearConsultaMedica(idMedico.toString(), '-1', idConsultorio.toString())
      .then((response) => response.json())
      .then((result) => {
        if (result.code === 200) {
          dispatch(resetPaciente());
          dispatch(setPacienteDocId(docId));
          dispatch(setPacienteSinDocId(sinDocId));
          dispatch(setPacienteID(-1));
          setIdConsultaSession(result.datos);
          dispatch(setConsulta(result.datos, -1));
          // si el medico no tiene los permisos debe de marcar 404
          history.push('ece');
        } else if (result.code === 300) {
          // el token y el idMedico no son el mismo
          /* Cerrar sesión */
          removeUserSession();
          dispatch(resetMe());
          limpiarRedux();
          dispatch(setAuth({ id: '', suscrito: false, acreditado: false }));
          history.push('/login');
        } else if (result.code === 301) {
          // el medico no tiene subscripcion activa
          /* Resetear y redirigir al checkout */
          removePaciente();
          limpiarRedux();
          dispatch(setAuth({ id: '', suscrito: false, acreditado: true }));
          history.push('/subscripcion');
        }
        dispatch(setLoading(false));
      })
      .catch((err) => {
        dispatch(
          setSnackComplete({
            open: true,
            severity: 'error',
            mensaje: `${t('message-error-warning')} ${err.toString()}`,
          }),
        );
        dispatch(setLoading(false));
      });
  };

  const iniciarConsultaPacienteExistente = (idPaci: number) => {
    // No se activa el setLoading porque al desactivarse desactiva también el de la carga de los datos del paciente y además se hace innecesario por la existencia de este último
    sessionPaciente(idPaci);
    dispatch(setPacienteID(idPaci));
    dispatch(getPaciente(idPaci, idMedico, idOrganizacion));
    crearConsultaMedica(idMedico.toString(), idPaci.toString(), idConsultorio.toString())
      .then((response) => response.json())
      .then((result: any) => {
        if (result.code === 200) {
          setIdConsultaSession(result.datos);
          dispatch(setConsulta(result.datos, idPaci));
          dispatch(setPacienteDocId(docId));
          dispatch(setPacienteSinDocId(sinDocId));
          // si el medico no tiene los permisos debe de marcar 404
          history.push('ece');
        } else if (result.code === 300) {
          // el token y el idMedico no son el mismo
          /* Cerrar sesión */
          removeUserSession();
          dispatch(resetMe());
          limpiarRedux();
          dispatch(setAuth({ id: '', suscrito: false, acreditado: false }));
          history.push('/login');
        } else if (result.code === 301) {
          // el medico no tiene subscripcion activa
          /* Resetear y redirigir al checkout */
          removePaciente();
          limpiarRedux();
          dispatch(setAuth({ id: '', suscrito: false, acreditado: true }));
          history.push('/subscripcion');
        }
      })
      .catch((err) => {
        dispatch(
          setSnackComplete({
            open: true,
            severity: 'error',
            mensaje: `${t('message-error-warning')} ${err.toString()}`,
          }),
        );
      });
  };
  const callBackSendCode = () => {
    sendTokenPaciente({
      idMedico,
      docId,
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.code === 200) {
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'success',
              mensaje: `${t('pin_enviado')}`,
            }),
          );
        } else if (result.code === 204) {
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'info',
              mensaje: `${t('no_se_puede_enviar_el_correo')}`,
            }),
          );
        } else if (result.code === 300) {
          // el token y el idMedico no son el mismo
          /* Cerrar sesión */
          removeUserSession();
          dispatch(resetMe());
          limpiarRedux();
          dispatch(setAuth({ id: '', suscrito: false, acreditado: false }));
          history.push('/login');
        } else if (result.code === 301) {
          // el medico no tiene subscripcion activa
          /* Resetear y redirigir al checkout */
          removePaciente();
          limpiarRedux();
          dispatch(setAuth({ id: '', suscrito: false, acreditado: true }));
          history.push('/subscripcion');
        } else if (result.code === 302) {
          // el paciente y el medico no tienen relacion
          /* Resetear redux paciente y datos paciente y redirigir a paciente */
          removePaciente();
          limpiarRedux();
          history.push('/pacientes');
        }
      })
      .catch(() => {
        dispatch(
          setSnackComplete({
            open: true,
            severity: 'error',
            mensaje: `${t('pin_no_enviado')}`,
          }),
        );
        dispatch(setLoading(false));
      });
  };
  const busquedaPaciente = () => {
    dispatch(setLoading(true));
    getExistenciaPaciente(idMedico, idConsultorio, idOrganizacion, docId)
      .then((response) => response.json())
      .then((result) => {
        if (result.code === 200 || result.code === 203) {
          // si es un codigo 200 significa que existe previamente la relacion medico paciente
          // si nos trae un codigo 203 significa que el paciente no tiene registro de paciente, solo los datos básicos
          iniciarConsultaPacienteExistente(result.datos.id);
        } else if (result.code === 201) {
          // si es un codigo 201 significa que se debe introducir el pin para realizar la relacion
          dispatch(setPacienteID(result.datos.id));
          setShowVerificaPermiso(true);
        } else if (result.code === 202) {
          // si nos trae un codigo 202 significa que no existe el documento y se puede
          // crear un expediente con ese documento
          setShowVerificaDocId(true);
        } else if (result.code === 208) {
          // si nos trae un codigo 208 significa que se excedio el numero de intentos y por lo tanto no se puede
          // creaar jamás la relacion medico paciente
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'error',
              mensaje: t(result.msg),
            }),
          );
          // se mostrará el modal de la consulta abierta
          // setShowSinPermiso(true);
        } else if (result.code === 209) {
          // si nos trae un codigo 209 significa que no existe el PACI- entonces se debe mostrar una galleta
          // de advertencia para verificar datos
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'error',
              mensaje: t('verifica_que_el_usuario_sea_correcto'),
            }),
          );
          dispatch(setLoading(false));
        } else if (result.code === 300) {
          // el token y el idMedico no son el mismo
          /* Cerrar sesión */
          removeUserSession();
          dispatch(resetMe());
          limpiarRedux();
          dispatch(setAuth({ id: '', suscrito: false, acreditado: false }));
          history.push('/login');
        } else if (result.code === 301) {
          // el medico no tiene subscripcion activa
          /* Resetear y redirigir al checkout */
          removePaciente();
          limpiarRedux();
          dispatch(setAuth({ id: '', suscrito: false, acreditado: true }));
          history.push('/subscripcion');
        } else if (result.code === 302) {
          // el paciente y el medico no tienen relacion
          /* Resetear redux paciente y datos paciente y redirigir a paciente */
          removePaciente();
          limpiarRedux();
          history.push('/pacientes');
        } else if (result.msg) {
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'error',
              mensaje: t(result.msg),
            }),
          );
        }
        dispatch(setLoading(false));
      })
      .catch((err) => {
        setShowVerificaDocId(false);
        setShowVerificaPermiso(false);
        setShowSinPermiso(false);
        setShowNoMedipraxi(false);
        dispatch(
          setSnackComplete({
            open: true,
            severity: 'error',
            mensaje: `${t('message-error-warning')} ${err.toString()}`,
          }),
        );
        dispatch(setLoading(false));
      });
  };
  const validacionPassword = () => {
    dispatch(setLoading(true));
    verificarTokenPaciente(
      idMedico,
      docId,
      pass,
      idConsultorio.toString(),
      idOrganizacion.toString(),
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.code === 200) {
          setShowVerificaPermiso(false);
          setPass('');
          iniciarConsultaPacienteExistente(result.datos.id);
        } else if (result.code === 201) {
          setPass('');
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'error',
              mensaje: result.msg,
            }),
          );
          // se mostrará el modal de la consulta abierta
          // setShowSinPermiso(true);
        } else if (result.code === 202) {
          setPass('');
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'error',
              mensaje: `${t('pin_error')} ${result.intentos}/3`,
            }),
          );
        } else if (result.code === 300) {
          // el token y el idMedico no son el mismo
          /* Cerrar sesión */
          removeUserSession();
          dispatch(resetMe());
          limpiarRedux();
          dispatch(setAuth({ id: '', suscrito: false, acreditado: false }));
          history.push('/login');
        } else if (result.code === 301) {
          // el medico no tiene subscripcion activa
          /* Resetear y redirigir al checkout */
          removePaciente();
          limpiarRedux();
          dispatch(setAuth({ id: '', suscrito: false, acreditado: true }));
          history.push('/subscripcion');
        } else if (result.code === 302) {
          // el paciente y el medico no tienen relacion
          /* Resetear redux paciente y datos paciente y redirigir a paciente */
          removePaciente();
          limpiarRedux();
          history.push('/pacientes');
        } else if (result.code === 400) {
          setPass('');
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'error',
              mensaje: `${result.msg}`,
            }),
          );
        }
        dispatch(setLoading(false));
      })
      .catch((err) => {
        setShowVerificaDocId(false);
        setShowVerificaPermiso(false);
        setShowSinPermiso(false);
        setShowNoMedipraxi(false);
        setPass('');
        dispatch(
          setSnackComplete({
            open: true,
            severity: 'error',
            mensaje: `${t('message-error-warning')} ${err.toString()}`,
          }),
        );
        dispatch(setLoading(false));
      });
  };
  const finalizarConsulta = () => {
    const objActualizar = {
      idConsulta,
      idPaciente,
      idMedico,
      idConsultorio,
    };
    editConsultasMedicasFinalizarConsulta(objActualizar)
      .then((response) => response.json())
      .then((result) => {
        if (result.code === 200) {
          setModalConsultaAbierta(false);
          removeIdConsulta();
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'success',
              mensaje: t('consulta_finalizada_correctamente'),
            }),
          );
          limpiarRedux();
          if (pasoModal >= 0) {
            // cualquier seleccion de id de pacientes que ya existen
            iniciarConsultaPacienteExistente(pasoModal);
          } else if (pasoModal === -2) {
            // nueva consulta sin docId
            setShowNoMedipraxi((pas) => !pas);
          } else if (pasoModal === -3) {
            // consulta con un docId ingresado
            busquedaPaciente();
          }
          // history.push('/pacientes');
          // limpiarRedux();
        } else if (result.code === 300) {
          // el token y el idMedico no son el mismo
          /* Cerrar sesión */
          removeUserSession();
          dispatch(resetMe());
          limpiarRedux();
          dispatch(setAuth({ id: '', suscrito: false, acreditado: false }));
          history.push('/login');
        } else if (result.code === 301) {
          // el medico no tiene subscripcion activa
          /* Resetear y redirigir al checkout */
          removePaciente();
          limpiarRedux();
          dispatch(setAuth({ id: '', suscrito: false, acreditado: true }));
          history.push('/subscripcion');
        } else if (result.code === 302) {
          // el paciente y el medico no tienen relacion
          /* Resetear redux paciente y datos paciente y redirigir a paciente */
          removePaciente();
          limpiarRedux();
          history.push('/pacientes');
        } else {
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'error',
              mensaje: `${t('message-error-warning')} ${result.msg}`,
            }),
          );
        }
      })
      .catch((err) => {
        dispatch(
          setSnackComplete({
            open: true,
            severity: 'error',
            mensaje: `${t('message-error-warning')} ${err.toString()}`,
          }),
        );
      });
  };
  const limpiarFiltro = () => {
    if (filtro.length > 0) {
      setOrdenarPor('id');
      setFiltro('');
      setFiltroBusqueda('');
    }
  };
  const gotoPage = (page: number) => {
    setPageIndex(page);
    setCanPreviousPage(page > 0);
    setCanNextPage(page + 1 < pageCount);
  };
  const previousPage = () => {
    const newIndex = pageIndex - 1;
    setPageIndex(newIndex);
    setCanPreviousPage(newIndex > 0);
    setCanNextPage(true);
  };
  const nextPage = () => {
    const newIndex = pageIndex + 1;
    setPageIndex(newIndex);
    setCanPreviousPage(true);
    setCanNextPage(newIndex < pageCount);
  };
  const obtenerPacientes = (filtrar: boolean) => {
    dispatch(setLoading(true));
    if (filtrar) {
      getPacientesTablaBusqueda(idMedico, idOrganizacion, idConsultorio, filtroBusqueda, ordenarPor)
        .then((response) => response.json())
        .then((result) => {
          if (result.code === 200) {
            setArrayTabla(result.datos);
            setTotalDatos(result.datos.length);
            setPageCount(0);
            setPageIndex(0);
            setCanPreviousPage(false);
            setCanNextPage(false);
          } else {
            setArrayTabla([]);
            setTotalDatos(0);
            setPageCount(0);
            setPageIndex(0);
          }
          dispatch(setLoading(false));
        })
        .catch(() => {
          setArrayTabla([]);
          setTotalDatos(0);
          setPageCount(0);
          dispatch(setLoading(false));
        });
    } else {
      getPacientesTabla(idMedico, idOrganizacion, idConsultorio, pageIndex, ordenarPor)
        .then((response) => response.json())
        .then((result) => {
          if (result.code === 200) {
            const pCount = Math.ceil(result.totalDatos / rowsPerPage);
            setArrayTabla(result.datos);
            setTotalDatos(result.totalDatos);
            setPageCount(pCount);
            setCanNextPage(pageIndex + 1 < pCount);
          } else {
            setArrayTabla([]);
            setPageCount(0);
            setCanNextPage(false);
          }
          dispatch(setLoading(false));
        })
        .catch(() => {
          setArrayTabla([]);
          setPageCount(0);
          setCanNextPage(false);
          dispatch(setLoading(false));
        });
    }
  };

  useEffect(() => {
    if (idConsultorio > 0 && idMedico > 0) {
      return obtenerPacientes(false);
    }
    return undefined;
  }, [idOrganizacion, idConsultorio]);
  useEffect(() => {
    if (
      idConsultorio > 0
      && idMedico > 0
      && filtroBusqueda.length < 3
      && (!enOrganizacion || arrayTabla.length === 0)
    ) {
      return obtenerPacientes(false);
    }
    return undefined;
  }, [idMedico, idConsultorio]);
  useEffect(() => {
    if (arrayTabla.length > 0) {
      return obtenerPacientes(filtroBusqueda.length >= 3);
    }
    return undefined;
  }, [pageIndex]);
  useEffect(() => {
    if (arrayTabla.length > 0) {
      if (pageIndex > 0) {
        gotoPage(0);
      } else {
        return obtenerPacientes(filtroBusqueda.length >= 3);
      }
    }
    return undefined;
  }, [ordenarPor]);
  useEffect(() => {
    if (idConsultorio > 0 && idMedico > 0) {
      if (filtroBusqueda.length >= 3) {
        setFiltrado(true);
        return obtenerPacientes(true);
      }
      if (filtrado) {
        setFiltrado(false);
        return obtenerPacientes(false);
      }
    }
    return undefined;
  }, [filtroBusqueda]);
  useEffect(() => {
    dispatch(resetPaciente());
  }, []);

  return (
    <div className="p-4 bg-white w-full">
      <DocIdCorrectoPaciente // Modal que se abre cuando se intenta iniciar una consulta con documento de identidad
        open={showVerificaDocId}
        callBackAceptar={iniciarConsultaPacienteNuevo}
        callBackClose={cerrarDocIdCorrectoModal}
        docId={docId}
      />
      <IniciarConsultaModal // Modal que se abre para solicitar la contraseña del paciente cuando este existe pero no tiene relación con el médico
        open={showVerificaPermiso}
        callBackAceptar={validacionPassword}
        callBackClose={cerrarIniciarConsultaModal}
        callBackSendCode={callBackSendCode}
        pass={pass}
        setPass={setPass}
        showSinPermiso={showSinPermiso}
        setShowSinPermiso={setShowSinPermiso}
      />
      <ConsultaNoMedipraxi // Modal que se abre cuando se intenta iniciar una cosulta sin documento de identidad
        open={showNoMedipraxi}
        callBackAceptar={iniciarConsultaPacienteNuevo}
        callBackClose={cerrarConsultaNoMedipraxiModal}
      />
      <ModalConsultaAbierta // Modal que advierte que hay una consulta abierta y da la opción de finalizarla
        open={modalConsultaAbierta}
        titulo={t('modal_con_abierta')}
        descripcion={t('modal_con_abierta_descripcion')}
        nombreCancelar={t('cancelar')}
        nombreAceptar={t('aceptar')}
        callBackAceptar={finalizarConsulta}
        callBackClose={cerrarConsultaModalConsultaAbiertaModal}
      />
      <IniciarConsulta // Caja donde está el campo de introducir el documento de identidad o de iniciar consulta sin documento
        docId={docId}
        setDocId={setDocId}
        evalData={evalData}
        error={error}
        sinDocId={sinDocId}
        setSinDocId={setSinDocId}
        setPasoModal={setPasoModal}
        setModalConsultaAbierta={setModalConsultaAbierta}
        setShowNoMedipraxi={setShowNoMedipraxi}
        busquedaPaciente={busquedaPaciente}
        idPaciente={idPaciente}
        idConsulta={idConsulta}
        idMedico={idMedico}
      />
      <div className="py-2" />
      <div className="container mx-auto shadow-lg border border-solid border-gray-200 grid grid-cols-3 gap-4 rounded-lg">
        <div />
        <div />
        <div className="p-4">
          <TextField
            placeholder={t('introduce-tres-caracteres')}
            variant="outlined"
            InputProps={{
              startAdornment: <SearchIcon color="inherit" />,
              endAdornment: (
                <Button onClick={limpiarFiltro}>
                  <Clear fontSize="small" color="inherit" />
                </Button>
              ),
            }}
            fullWidth
            onChange={(e) => {
              setFiltro(e.target.value.toString());
              setTimeout(() => {
                setFiltroBusqueda(e.target.value.toString());
              }, 500);
            }}
            value={filtro}
          />
        </div>
      </div>
      <div className="py-2" />
      <div className="container mx-auto shadow-lg border border-solid border-gray-200 pb-2">
        <TablaPacientes
          columns={columnas}
          data={arrayTabla}
          totalDatos={totalDatos}
          pageIndex={pageIndex}
          pageCount={pageCount}
          gotoPage={gotoPage}
          previousPage={previousPage}
          nextPage={nextPage}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          ordenarPor={ordenarPor}
          setOrdenarPor={setOrdenarPor}
          setPasoModal={setPasoModal}
          setModalConsultaAbierta={setModalConsultaAbierta}
          iniciarConsultaPacienteExistente={iniciarConsultaPacienteExistente}
          agendarPaciente={() => {
            history.push('/agenda');
          }}
          idMedico={idMedico}
          idPaciente={idPaciente}
          idConsulta={idConsulta}
          idConsultorio={idConsultorio}
        />
      </div>
    </div>
  );
};

export default PacienteComponent;
