import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import {
  Button, StepConnector, Stepper, Step, StepLabel, StepIconProps,
} from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { ReactComponent as AdmonEmpresa } from '../../../assets/icons/admon_organizacion.svg';
import { ReactComponent as AdmonInvitar } from '../../../assets/icons/admon_invitar.svg';
import { ReactComponent as AdmonConsultorios } from '../../../assets/icons/admon_consultorio.svg';
import { ReactComponent as AdmonPermisos } from '../../../assets/icons/admon_roles.svg';
import { ReactComponent as AdmonMedico } from '../../../assets/icons/admon_equipo.svg';
import { ReactComponent as AdmonPaciente } from '../../../assets/icons/admon_paciente.svg';
import Organizacion from './Organizacion';
import PerfilOtroAdministrador from '../PerfilAdministrador';
import MisConsultorios from './MisConsultorios';
import RolesPermisos from './RolesPermisos';
import EquipoMedico from './EquipoMedico';
import Pacientes from './Pacientes';
import { IMiOrganizacionProps } from './types';

function MiOrganizacion(propsGen: IMiOrganizacionProps) {
  const { t } = useTranslation();
  const [steps] = useState([
    t('administrador_organizacion'),
    t('administrador_invitar_administrador'),
    t('administrador_consultorio'),
    t('administrador_roles'),
    t('administrador_equipo'),
    t('administrador_pacientes'),
  ]);
  const {
    keyBack,
    objAdministrador,
    objAdminTmp,
    setObjAdminTmp,
    disableDocId,
    disabledNext,
    objOrganizacion,
    setObjOrganizacion,
    urlLogo,
    setUrlLogo,
    catCfdi,
    catAGEE,
    catAGEM,
    catAGEL,
    catAGEA,
    catNacionalidad,
    activeStep,
    setActiveStep,
    openAndDeleteAdmon,
    objConsultorio,
    setObjConsultorio,
    consultorios,
    setConsultorios,
    catTipoEstablecimiento,
    disabledSave,
    setDisabledSave,
    setDisabledNext,
    objRolPermisos,
    setObjRolPermisos,
    rolesPermisos,
    openAndDeleteRol,
    tipoRoles,
    arrayRoles,
    catTablaCsSecciones,
    licenciasDisponibles,
    csvArray,
    setCsvArray,
    errorArray,
    setErrorArray,
    stringInformacionCambios,
    csvPacientesArray,
    setCsvPacientesArray,
    setStringInformacionCambios,
  } = propsGen;
  const [idActualizarConsultorio, setIdActualizarConsultorio] = useState<number>(-1);
  const ColorlibConnector = withStyles({
    alternativeLabel: {
      top: 22,
    },
    active: {
      '& $line': {
        backgroundColor: '#1E40AF',
      },
    },
    completed: {
      '& $line': {
        backgroundColor: '#1E40AF',
      },
    },
    line: {
      height: 3,
      border: 0,
      backgroundColor: '#1E40AF',
      borderRadius: 1,
    },
  })(StepConnector);
  const useColorlibStepIconStyles = makeStyles({
    root: {
      backgroundColor: '#ccc',
      zIndex: 1,
      color: '#fff',
      width: 50,
      height: 50,
      display: 'flex',
      borderRadius: '50%',
      justifyContent: 'center',
      alignItems: 'center',
    },
    active: {
      boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
      backgroundColor: '#1E40AF',
    },
  });
  const ColorlibStepIcon = (props: StepIconProps) => {
    const classes = useColorlibStepIconStyles();
    const { active } = props;
    const icons: { [index: string]: React.ReactElement } = {
      1: <AdmonEmpresa />,
      2: <AdmonInvitar />,
      3: <AdmonConsultorios />,
      4: <AdmonPermisos />,
      5: <AdmonMedico />,
      6: <AdmonPaciente />,
    };
    return (
      <div
        className={clsx(classes.root, {
          [classes.active]: active,
        })}
      >
        {/* eslint-disable react/destructuring-assignment */}
        {icons[String(props.icon)]}
      </div>
    );
  };
  useEffect(() => {
    if (activeStep === 4) {
      setIdActualizarConsultorio(-1);
    }
  }, [activeStep]);

  return (
    <div className="w-full mb-4">
      <div className="flex content-center justify-center">
        <Button disabled={activeStep === 1} onClick={() => setActiveStep(activeStep - 1)}>
          <ArrowBackIosIcon />
        </Button>
        <Stepper alternativeLabel activeStep={activeStep - 1} connector={<ColorlibConnector />}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <Button
          onClick={() => setActiveStep(activeStep + 1)}
          disabled={activeStep === 6 || disabledNext}
        >
          <ArrowForwardIosIcon />
        </Button>
      </div>
      {activeStep === 1 && (
        <Organizacion
          objOrganizacion={objOrganizacion}
          setObjOrganizacion={setObjOrganizacion}
          catCfdi={catCfdi}
          catAGEE={catAGEE}
          catAGEM={catAGEM}
          catAGEL={catAGEL}
          catAGEA={catAGEA}
          urlLogo={urlLogo}
          setUrlLogo={setUrlLogo}
          setDisabledSave={setDisabledSave}
          setDisabledNext={setDisabledNext}
        />
      )}
      {activeStep === 2 && (
        <PerfilOtroAdministrador
          activeStep={activeStep}
          keyBack={keyBack}
          objAdministrador={objAdministrador}
          objAdminTmp={objAdminTmp}
          setObjAdminTmp={setObjAdminTmp}
          catNacionalidad={catNacionalidad}
          disableDocId={disableDocId}
          setDisabledSave={setDisabledSave}
          openAndDeleteAdmon={openAndDeleteAdmon}
        />
      )}
      {activeStep === 3 && (
        <MisConsultorios
          idActualizar={idActualizarConsultorio}
          setIdActualizar={setIdActualizarConsultorio}
          objConsultorio={objConsultorio}
          setObjConsultorio={setObjConsultorio}
          consultorios={consultorios}
          setConsultorios={setConsultorios}
          catAGEE={catAGEE}
          catTipoEstablecimiento={catTipoEstablecimiento}
          setDisabledSave={setDisabledSave}
          setDisabledNext={setDisabledNext}
        />
      )}
      {activeStep === 4 && (
        <RolesPermisos
          objRolPermisos={objRolPermisos}
          setObjRolPermisos={setObjRolPermisos}
          tipoRoles={tipoRoles}
          arrayRoles={arrayRoles}
          catTablaCsSecciones={catTablaCsSecciones}
          setDisabledSave={setDisabledSave}
          setDisabledNext={setDisabledNext}
          rolesPermisos={rolesPermisos}
          openAndDeleteRol={openAndDeleteRol}
        />
      )}
      {activeStep === 5 && (
        <EquipoMedico
          licenciasDisponibles={licenciasDisponibles}
          catNacionalidad={catNacionalidad}
          rolesPermisos={rolesPermisos}
          setDisabledSave={setDisabledSave}
          setDisabledNext={setDisabledNext}
          csvArray={csvArray}
          setCsvArray={setCsvArray}
          errorArray={errorArray}
          setErrorArray={setErrorArray}
          stringInformacionCambios={stringInformacionCambios}
          setStringInformacionCambios={setStringInformacionCambios}
          disabledSave={disabledSave}
        />
      )}
      {activeStep === 6 && (
        <Pacientes
          catNacionalidad={catNacionalidad}
          setDisabledSave={setDisabledSave}
          setDisabledNext={setDisabledNext}
          csvPacientesArray={csvPacientesArray}
          setCsvPacientesArray={setCsvPacientesArray}
          errorArray={errorArray}
          setErrorArray={setErrorArray}
          stringInformacionCambios={stringInformacionCambios}
          setStringInformacionCambios={setStringInformacionCambios}
        />
      )}
    </div>
  );
}

export default MiOrganizacion;
