import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs';
import {
  Checkbox,
  IconButton,
  Tooltip,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
} from '@material-ui/core';
import DateDayjsUtils from '@date-io/dayjs';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { useSelector, useDispatch } from 'react-redux';
import ErrorIcon from '@material-ui/icons/Error';
import { useTable } from 'react-table';
import MaUTable from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import DateRangeIcon from '@material-ui/icons/DateRange';
import { getCatVacunaNombre, getCatVacunaDosis } from '../../../../../utils/getCatalogos';
import { guardarInmunizaciones } from '../../../../../utils/sendInfo';
import { setLoading } from '../../../../../actions/loading/actions';
import { RootState } from '../../../../../store';
import { IInmunizacionProps } from './types';
import { ICatalogosEstandar } from '../../../../../common/types';
import useStyles from './styles';
import ModalVacuna from './ModalVacuna/index';
import { setSnackComplete } from '../../../../../actions/snackbar/types';
import { setRequest } from '../../../../../actions/request/types';

const columnas = [
  {
    Header: 'Tipo de vacuna',
    accessor: 'tipo',
  },
  {
    Header: 'Aplicada',
    accessor: 'aplicada',
  },
  {
    Header: 'Dosis',
    accessor: 'dosis',
  },
  // {
  //   Header: 'Edad recomendada',
  //   accessor: 'edad',
  // },
  {
    Header: 'Edad / Intervalo',
    accessor: 'redaccion',
  },
  {
    Header: 'Fecha',
    accessor: 'fecha',
  },
  {
    Header: 'Desactivar alarma',
    accessor: 'desactivar',
  },
];

const Table = (val: any) => {
  // Use the state and functions returned from useTable to build your UI
  const {
    columns,
    data,
    selRota,
    setSelRota,
    selVaricela,
    setSelVaricela,
    inmunizaciones,
    setInmunizaciones,
    enGestacion,
  } = val;
  const {
    getTableProps, headerGroups, rows, prepareRow,
  } = useTable({
    columns,
    data,
  });
  const classes = useStyles();
  const { t } = useTranslation();
  const handleDateChange = (date: any, id: number) => {
    const resp = [...inmunizaciones];
    if (date) {
      if (date.$D && date.$M && date.$y) {
        resp[resp.findIndex((x) => x.id === id)].fecha = dayjs(date).toISOString();
        resp[resp.findIndex((x) => x.id === id)].aplicada = true;
      } else {
        resp[resp.findIndex((x) => x.id === id)].fecha = null;
        resp[resp.findIndex((x) => x.id === id)].aplicada = false;
      }
    } else {
      resp[resp.findIndex((x) => x.id === id)].fecha = null;
      resp[resp.findIndex((x) => x.id === id)].aplicada = null;
    }
    setInmunizaciones(resp);
  };
  const handleChangeRadio = (event: React.ChangeEvent<HTMLInputElement>, select: string) => {
    if (select === 'rota') {
      setSelRota((event.target as HTMLInputElement).value);
    } else if (select === 'varicela') {
      setSelVaricela((event.target as HTMLInputElement).value);
    }
  };
  // cambio solicito Carluis
  // // validar entre los estilos que se deben de usar
  // const selectorEstilo = (numerico: number, sError: boolean, posicion: string) => {
  //   if (posicion === 'inicio') {
  //     if (numerico % 2 === 0 && sError) {
  //       return classes.rowErrorInit;
  //     } if (!(numerico % 2 === 0) && sError) {
  //       return classes.row2ErrorInit;
  //     } if (numerico % 2 === 0) {
  //       return classes.row;
  //     }
  //     return classes.row2;
  //   } if (posicion === 'final') {
  //     if (numerico % 2 === 0 && sError) {
  //       return classes.rowErrorEnd;
  //     } if (!(numerico % 2 === 0) && sError) {
  //       return classes.row2ErrorEnd;
  //     } if (numerico % 2 === 0) {
  //       return classes.row;
  //     }
  //     return classes.row2;
  //   } if (numerico % 2 === 0 && sError) {
  //     return classes.rowError;
  //   } if (!(numerico % 2 === 0) && sError) {
  //     return classes.row2Error;
  //   } if (numerico % 2 === 0) {
  //     return classes.row;
  //   }
  //   return classes.row2;
  // };
  const selectorEstilo = (numerico: number, sError: boolean) => {
    if (numerico % 2 === 0 && sError) {
      return classes.rowError;
    }
    if (!(numerico % 2 === 0) && sError) {
      return classes.row2Error;
    }
    if (numerico % 2 === 0) {
      return classes.row;
    }
    return classes.row2;
  };
  // Render the UI for your table
  return (
    <MaUTable {...getTableProps()}>
      <TableHead>
        {headerGroups.map((headerGroup) => (
          <TableRow {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <TableCell {...column.getHeaderProps()} className={classes.header}>
                {column.render('Header')}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableHead>
      <TableBody>
        {rows.map((row, inx) => {
          const {
            totalGrupo, lugarGrupo, tipo, grupo, id,
          } = data[inx];
          let error = false;
          if (data[inx].desactivar === true) {
            error = false;
          } else if (data[inx].error === 'true') {
            error = true;
          }
          prepareRow(row);
          return (
            <TableRow {...row.getRowProps()}>
              {row.cells.map((cell) => {
                const rowIndex = parseInt(grupo, 10) || 0;
                if (cell.column && cell.column.id === 'tipo') {
                  if (totalGrupo === lugarGrupo) {
                    if (tipo === 'Rotavirus Monovalente' || tipo === 'Rotavirus Pentavalente') {
                      return (
                        <TableCell
                          {...cell.getCellProps()}
                          className={
                            rowIndex % 2 === 0 ? `${classes.rowTitle}` : `${classes.rowTitle2}`
                          }
                          rowSpan={totalGrupo}
                        >
                          Rotavirus
                          <div className="flex self-center">
                            <RadioGroup
                              aria-label="realiza"
                              name="realiza"
                              value={selRota}
                              onChange={(e) => handleChangeRadio(e, 'rota')}
                              row
                            >
                              <FormControlLabel
                                value="M"
                                control={<Radio color="primary" />}
                                label={t('antecedentes-pnp-inmunizaciones-esquema-monovalente')}
                              />
                              <FormControlLabel
                                value="P"
                                control={<Radio color="primary" />}
                                label={t('antecedentes-pnp-inmunizaciones-esquema-pentavalente')}
                              />
                            </RadioGroup>
                          </div>
                        </TableCell>
                      );
                    }
                    if (
                      tipo === 'Varicela (lugar de alta incidencia)'
                      || tipo === 'Varicela (lugar de baja incidencia)'
                    ) {
                      return (
                        <TableCell
                          {...cell.getCellProps()}
                          className={
                            rowIndex % 2 === 0 ? `${classes.rowTitle}` : `${classes.rowTitle2}`
                          }
                          rowSpan={totalGrupo}
                        >
                          Varicela
                          <div className="flex self-center">
                            <RadioGroup
                              aria-label="realiza"
                              name="realiza"
                              value={selVaricela}
                              onChange={(e) => handleChangeRadio(e, 'varicela')}
                              row
                            >
                              <FormControlLabel
                                value="A"
                                control={<Radio color="primary" />}
                                label={t('antecedentes-pnp-inmunizaciones-esquema-alta')}
                              />
                              <FormControlLabel
                                value="B"
                                control={<Radio color="primary" />}
                                label={t('antecedentes-pnp-inmunizaciones-esquema-baja')}
                              />
                            </RadioGroup>
                          </div>
                        </TableCell>
                      );
                    }
                    return (
                      <TableCell
                        {...cell.getCellProps()}
                        className={
                          rowIndex % 2 === 0 ? `${classes.rowTitle}` : `${classes.rowTitle2}`
                        }
                        rowSpan={totalGrupo}
                      >
                        {cell.render('Cell')}
                      </TableCell>
                    );
                  }
                  // se comenta porque al parecer en el diseño se esta rompiendo
                  // por lo nuevos cambios en el catálogo 03/02/2022
                  // if (enGestacion) {
                  //   if (tipo === 'Virus de influenza') {
                  //     return (
                  //       <TableCell
                  //         {...cell.getCellProps()}
                  //         className={
                  //           rowIndex % 2 === 0 ? `${classes.rowTitle}` : `${classes.rowTitle2}`
                  //         }
                  //         rowSpan={totalGrupo}
                  //       >
                  //         {`${cell.render('Cell')}³`}
                  //       </TableCell>
                  //     );
                  //   }
                  //   return (
                  //     <TableCell
                  //       {...cell.getCellProps()}
                  //       className={
                  //         rowIndex % 2 === 0 ? `${classes.rowTitle}` : `${classes.rowTitle2}`
                  //       }
                  //       rowSpan={totalGrupo}
                  //     >
                  //       {`${cell.render('Cell')}²`}
                  //     </TableCell>
                  //   );
                  // }
                  return null;
                }
                if (cell.column && cell.column.id === 'aplicada') {
                  return (
                    <TableCell
                      {...cell.getCellProps()}
                      // className={selectorEstilo(rowIndex, error, 'inicio')}
                      className={selectorEstilo(rowIndex, error)}
                    >
                      <Checkbox
                        style={{ textAlign: 'left', width: '100%' }}
                        onChange={(e) => {
                          const resp = [...inmunizaciones];
                          resp[resp.findIndex((x) => x.id === id)].aplicada = e.target.checked;
                          setInmunizaciones(resp);
                        }}
                        checked={cell && cell.value}
                        color="primary"
                      />
                    </TableCell>
                  );
                }
                if (cell.column && cell.column.id === 'dosis') {
                  return (
                    <TableCell
                      {...cell.getCellProps()}
                      // className={selectorEstilo(rowIndex, error, 'centro')}
                      className={selectorEstilo(rowIndex, error)}
                    >
                      <div className="flex items-center justify-center">
                        {error && (
                          <div className="inline-block">
                            <Tooltip
                              title={`${t('antecedentes-pnp-inmunizaciones-advertencia')}`}
                              placement="top"
                            >
                              <IconButton
                                aria-label="error"
                                size="small"
                                className={classes.noTextTranform}
                              >
                                <ErrorIcon />
                              </IconButton>
                            </Tooltip>
                          </div>
                        )}
                        <span className="w-16">{cell.render('Cell')}</span>
                        {/* {cell.render('Cell')} */}
                      </div>
                    </TableCell>
                  );
                }
                if (cell.column && cell.column.id === 'desactivar') {
                  return (
                    <TableCell
                      {...cell.getCellProps()}
                      // className={selectorEstilo(rowIndex, error, 'final')}
                      className={selectorEstilo(rowIndex, error)}
                    >
                      <Checkbox
                        style={{ textAlign: 'center', width: '100%' }}
                        onChange={(e) => {
                          const resp = [...inmunizaciones];
                          resp[resp.findIndex((x) => x.id === id)].desactivar = e.target.checked;
                          setInmunizaciones(resp);
                        }}
                        checked={cell && cell.value}
                        color="primary"
                      />
                    </TableCell>
                  );
                }
                if (cell.column && cell.column.id === 'fecha') {
                  return (
                    <TableCell
                      {...cell.getCellProps()}
                      // className={selectorEstilo(rowIndex, error, 'centro')}
                      className={selectorEstilo(rowIndex, error)}
                    >
                      <MuiPickersUtilsProvider locale="es" utils={DateDayjsUtils}>
                        <KeyboardDatePicker
                          variant="inline"
                          format="DD/MM/YYYY"
                          margin="normal"
                          PopoverProps={{ style: { ...{ left: '+300px' } } }}
                          value={cell.value && cell.value.length > 0 ? cell.value : null}
                          onChange={(e) => handleDateChange(e, id)}
                          maxDate={dayjs()}
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                          invalidDateMessage={t('fecha_invalida')}
                          maxDateMessage={t('fecha_max_no_permitida')}
                        />
                      </MuiPickersUtilsProvider>
                    </TableCell>
                  );
                }
                if (enGestacion && cell.column && cell.column.id === 'edad') {
                  if (tipo === 'Virus de influenza') {
                    return (
                      <TableCell
                        {...cell.getCellProps()}
                        className={rowIndex % 2 === 0 ? `${classes.row}` : `${classes.row2}`}
                        rowSpan={totalGrupo}
                      >
                        {t('antecedentes-pnp-inmunizaciones-embarazo-anual')}
                      </TableCell>
                    );
                  }
                  return (
                    <TableCell
                      {...cell.getCellProps()}
                      className={rowIndex % 2 === 0 ? `${classes.row}` : `${classes.row2}`}
                      rowSpan={totalGrupo}
                    >
                      {t('antecedentes-pnp-inmunizaciones-embarazo-semanas')}
                    </TableCell>
                  );
                }
                return (
                  <TableCell
                    {...cell.getCellProps()}
                    // className={selectorEstilo(rowIndex, error, 'centro')}
                    className={selectorEstilo(rowIndex, error)}
                  >
                    {cell.render('Cell')}
                  </TableCell>
                );
              })}
            </TableRow>
          );
        })}
      </TableBody>
    </MaUTable>
  );
};

function Inmunizaciones(props: IInmunizacionProps) {
  const {
    isPlan, enGestacion, setEnGestacion, inmunizaciones, setInmunizaciones,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const { idMedico } = useSelector((state: RootState) => state.Me);
  const {
    idPaciente,
    sexo,
    edad: { claveEdad, edad },
  } = useSelector((state: RootState) => state.BasicosPaciente);
  const { idConsulta } = useSelector((state: RootState) => state.Consulta);
  const {
    consultorio: { idConsultorio },
  } = useSelector((state: RootState) => state.Consultorios);
  const { secciones } = useSelector((state: RootState) => state.Me);
  const [enGestacionTemp, setEnGestacionTemp] = useState<'' | 'si' | 'no'>(enGestacion);
  const [catVacunaNombre, setCatVacunaNombre] = useState<ICatalogosEstandar[]>([]);
  const [catVacunaDosis, setCatVacunaDosis] = useState<ICatalogosEstandar[]>([]);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [liTraExpand, setLiTraExpand] = useState(false);
  const [selRota, setSelRota] = useState<string>('M');
  const [selVaricela, setSelVaricela] = useState<string>('A');
  const title = (id: number) => {
    if (id === 1) {
      return (
        <h2 className="text-gray-800 font-normal m-0">
          {t('antecedentes-pnp-inmunizaciones-esquema-1')}
        </h2>
      );
    }
    if (id === 2) {
      return (
        <h2 className="text-gray-800 font-normal m-0">
          {t('antecedentes-pnp-inmunizaciones-esquema-2')}
        </h2>
      );
    }
    if (id === 3) {
      return (
        <h2 className="text-gray-800 font-normal m-0">
          {t('antecedentes-pnp-inmunizaciones-esquema-3')}
        </h2>
      );
    }
    if (id === 4) {
      return (
        <h2 className="text-gray-800 font-normal m-0">
          {t('antecedentes-pnp-inmunizaciones-esquema-4')}
        </h2>
      );
    }
    return null;
  };
  const parrafosPiePagina = (id: number) => {
    if (id === 1) {
      return (
        <div className="text-left pt-4">
          <p className="text-gray-600 mp-2 mt-2">
            {`${t('adaptado_de')}:`}
            &nbsp;
            {t('antecedentes-pnp-inmunizaciones-esquema-1-1')}
          </p>
          <p className="text-gray-600 mp-2 mt-2">
            {`${t('obtenido_de')}:`}
            &nbsp;
            <a
              href="https://vacunacion.org/esquemas_2020/ESQUEMA_NINOS.pdf"
              target="_blank"
              rel="noreferrer"
            >
              https://vacunacion.org/esquemas_2020/ESQUEMA_NINOS.pdf
            </a>
          </p>
          <p className="text-gray-600 mp-2 mt-2">{t('antecedentes-pnp-inmunizaciones-nota-1-1')}</p>
        </div>
      );
    }
    if (id === 2) {
      const arrayNotas = [1, 2, 3, 4, 5];
      return (
        <div className="text-left pt-4">
          <p className="text-gray-600 mp-2 mt-2">
            {`${t('adaptado_de')}:`}
            &nbsp;
            {t('antecedentes-pnp-inmunizaciones-esquema-2-1')}
          </p>
          <p className="text-gray-600 mp-2 mt-2">
            {`${t('obtenido_de')}:`}
            &nbsp;
            <a
              href="https://vacunacion.org/esquemas_2020/ESQUEMA_ADOLESCENTES.pdf"
              target="_blank"
              rel="noreferrer"
            >
              https://vacunacion.org/esquemas_2020/ESQUEMA_ADOLESCENTES.pdf
            </a>
          </p>
          <div className="grid grid-flow-col grid-cols-2 grid-rows-3 gap-4">
            {arrayNotas.map((x) => (
              <p className="text-gray-600 m-0" key={`nota-${x + 1}`}>
                {t(`antecedentes-pnp-inmunizaciones-nota-2-${x}`)}
              </p>
            ))}
          </div>
        </div>
      );
    }
    if (id === 3) {
      const arrayNotas = [1, 2, 3, 4, 5, 6];
      return (
        <div className="text-left pt-4">
          <p className="text-gray-600 mp-2 mt-2">
            {`${t('adaptado_de')}:`}
            &nbsp;
            {t('antecedentes-pnp-inmunizaciones-esquema-3-1')}
          </p>
          <p className="text-gray-600 mp-2 mt-2">
            {`${t('obtenido_de')}:`}
            &nbsp;
            <a
              href="https://vacunacion.org/esquemas_2020/ESQUEMA_ADULTOS.pdf"
              target="_blank"
              rel="noreferrer"
            >
              https://vacunacion.org/esquemas_2020/ESQUEMA_ADULTOS.pdf
            </a>
          </p>
          <div className="grid grid-flow-col grid-cols-2 grid-rows-3 gap-4">
            {arrayNotas.map((x) => (
              <p className="text-gray-600 m-0" key={`nota-${x + 1}`}>
                {t(`antecedentes-pnp-inmunizaciones-nota-3-${x}`)}
              </p>
            ))}
          </div>
        </div>
      );
    }
    if (id === 4) {
      const arrayNotas = [1, 2, 3, 4, 5];
      return (
        <div className="text-left pt-4">
          <p className="text-gray-600 mp-2 mt-2">
            {`${t('adaptado_de')}:`}
            &nbsp;
            {t('antecedentes-pnp-inmunizaciones-esquema-4-1')}
          </p>
          <p className="text-gray-600 mp-2 mt-2">
            {`${t('obtenido_de')}:`}
            &nbsp;
            <a
              href="https://vacunacion.org/esquemas_2020/ESQUEMA_MAYORES.pdf"
              target="_blank"
              rel="noreferrer"
            >
              https://vacunacion.org/esquemas_2020/ESQUEMA_MAYORES.pdf
            </a>
          </p>
          <div className="grid grid-flow-col grid-cols-2 grid-rows-3 gap-4">
            {arrayNotas.map((x) => (
              <p className="text-gray-600 m-0" key={`nota-${x + 1}`}>
                {t(`antecedentes-pnp-inmunizaciones-nota-4-${x}`)}
              </p>
            ))}
          </div>
        </div>
      );
    }
    if (id === 5) {
      const arrayNotas = [1, 2, 3];
      return (
        <div className="text-left pt-4">
          <p className="text-gray-600 mp-2 mt-2">
            {`${t('adaptado_de')}:`}
            &nbsp;
            {t('antecedentes-pnp-inmunizaciones-esquema-5-1')}
          </p>
          <p className="text-gray-600 mp-2 mt-2">
            {`${t('obtenido_de')}:`}
            &nbsp;
            <a
              href="https://vacunacion.org/esquemas_2020/ESQUEMA_EMBARAZO.pdf"
              target="_blank"
              rel="noreferrer"
            >
              https://vacunacion.org/esquemas_2020/ESQUEMA_EMBARAZO.pdf
            </a>
          </p>
          <div className="grid gap-4">
            {arrayNotas.map((x) => (
              <p className="text-gray-600 m-0" key={`nota-${x + 1}`}>
                {t(`antecedentes-pnp-inmunizaciones-nota-5-${x}`)}
              </p>
            ))}
          </div>
        </div>
      );
    }
    return null;
  };
  useEffect(() => {
    getCatVacunaNombre().then((result: ICatalogosEstandar[]) => {
      setCatVacunaNombre(result);
    });
    getCatVacunaDosis().then((result: ICatalogosEstandar[]) => {
      setCatVacunaDosis(result);
      dispatch(setLoading(false));
    });
    setLiTraExpand(false);
  }, []);
  const sendToSave = () => {
    const arraySend: any = [];
    inmunizaciones.forEach((value) => {
      if (value.aplicada || value.desactivar) {
        arraySend.push(value);
      }
    });
    if (arraySend.length === 0) {
      dispatch(
        setSnackComplete({
          open: true,
          severity: 'error',
          mensaje: t('message-error-guardado'),
        }),
      );
    } else {
      dispatch(
        setRequest({
          type: 'send',
          requestFunction: guardarInmunizaciones({
            idMedico,
            idPaciente,
            data: arraySend,
            enGestacion: enGestacionTemp,
            idConsulta,
            idConsultorio,
          }),
          successFunction: () => {
            setEnGestacion(enGestacionTemp);
          },
        }),
      );
    }
  };
  return (
    <div className="relative py-4">
      <ModalVacuna
        open={openModal}
        callBackClose={() => setOpenModal(false)}
        catVacunaNombre={catVacunaNombre}
        catVacunaDosis={catVacunaDosis}
        inmunizaciones={inmunizaciones}
        setInmunizaciones={setInmunizaciones}
      />
      <div className="text-left pb-2">
        <h2 className="text-blue-800 font-normal m-0">{t('antecedentes-pnp-inmunizaciones')}</h2>
      </div>
      {isPlan ? (
        <div className="flex self-center content-center justify-between py-2">
          <h2 className="text-gray-800 font-normal m-0">
            {t('antecedentes-pnp-inmunizaciones-registrar')}
          </h2>
          {secciones.length > 0
            && secciones.find((valor) => valor.idSeccion >= 2 && valor.idSeccion <= 6) && (
              <div>
                <Button
                  color="primary"
                  size="small"
                  className={classes.noTextTransform}
                  onClick={() => history.push('/agenda')}
                  startIcon={<DateRangeIcon />}
                >
                  {t('antecedentes-pnp-inmunizaciones-agendar')}
                </Button>
              </div>
          )}
        </div>
      ) : null}
      <div className="pb-2">
        {sexo === 'M' && claveEdad === 5 && edad > 9 && edad < 61 ? (
          <div className="flex items-center">
            <h3 className="font-normal m-0 text-gray-600 pr-4">{t('en_gestacion')}</h3>
            <RadioGroup
              row
              name="gestacion"
              value={enGestacionTemp}
              onChange={(e) => setEnGestacionTemp(e.target.value as 'si' | 'no')}
            >
              <FormControlLabel
                value="si"
                control={<Radio color="primary" />}
                label={t('si')}
                labelPlacement="end"
              />
              <FormControlLabel
                value="no"
                control={<Radio color="primary" />}
                label={t('no')}
                labelPlacement="end"
              />
            </RadioGroup>
          </div>
        ) : null}
      </div>
      {enGestacion ? (
        <div className="pb-4">
          <div className="pb-4 flex justify-center">
            <h2 className="text-gray-600 m-0 font-normal">
              {t('antecedentes-pnp-inmunizaciones-esquema-5')}
            </h2>
          </div>
          <div className="border border-solid border-gray-300 rounded p-4 text-left pb-4">
            <h3 className="text-gray-800 font-normal m-0">
              {`${t('antecedentes-pnp-inmunizaciones-esquema')}¹`}
            </h3>
            <div className="p-4">
              <Table
                columns={columnas}
                data={
                  inmunizaciones && inmunizaciones.length > 0
                    ? inmunizaciones.filter(
                      (x) => x.esquema === 5 || x.tipo === 'Virus de influenza',
                    )
                    : []
                }
                selRota={selRota}
                setSelRota={setSelRota}
                selVaricela={selVaricela}
                setSelVaricela={setSelVaricela}
                inmunizaciones={inmunizaciones}
                setInmunizaciones={setInmunizaciones}
                gestacion={enGestacion}
              />
            </div>
            {parrafosPiePagina(5)}
          </div>
        </div>
      ) : null}
      <div className="pb-4 flex justify-center">
        {title(inmunizaciones && inmunizaciones.length > 0 ? inmunizaciones[0].esquema : 0)}
        {enGestacion ? (
          <>
            <Button onClick={() => setLiTraExpand((prevVal: boolean) => !prevVal)} color="primary">
              {liTraExpand ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
            </Button>
          </>
        ) : null}
      </div>
      {enGestacion && !liTraExpand ? null : (
        <div>
          <div className="border border-solid border-gray-300 rounded p-4 text-left pb-4">
            <h3 className="text-gray-800 font-normal m-0">
              {t('antecedentes-pnp-inmunizaciones-esquema')}
            </h3>
            <div className="p-4">
              <Table
                columns={columnas}
                data={
                  inmunizaciones && inmunizaciones.length > 0
                    ? inmunizaciones.filter(
                      (x) => (x.tipo === 'Rotavirus Monovalente'
                            && selRota === 'M'
                            && x.otras === 0
                            && x.esquema !== 5)
                          || (x.tipo === 'Rotavirus Pentavalente'
                            && selRota === 'P'
                            && x.otras === 0
                            && x.esquema !== 5)
                          || (x.tipo === 'Varicela (lugar de alta incidencia)'
                            && selVaricela === 'A'
                            && x.otras === 0
                            && x.esquema !== 5)
                          || (x.tipo === 'Varicela (lugar de baja incidencia)'
                            && selVaricela === 'B'
                            && x.otras === 0
                            && x.esquema !== 5)
                          || (x.tipo !== 'Rotavirus Monovalente'
                            && x.tipo !== 'Rotavirus Pentavalente'
                            && x.tipo !== 'Varicela (lugar de alta incidencia)'
                            && x.tipo !== 'Varicela (lugar de baja incidencia)'
                            && x.otras === 0
                            && x.esquema !== 5),
                    )
                    : []
                }
                selRota={selRota}
                setSelRota={setSelRota}
                selVaricela={selVaricela}
                setSelVaricela={setSelVaricela}
                inmunizaciones={inmunizaciones}
                setInmunizaciones={setInmunizaciones}
                gestacion={enGestacion}
              />
            </div>
          </div>
          <div className="p-4" />
          <div className="border border-solid border-gray-300 rounded p-4 text-left pb-4">
            <h3 className="text-gray-800 font-normal m-0">
              {t('antecedentes-pnp-inmunizaciones-adicionales')}
            </h3>
            <div className="p-4">
              <Table
                columns={columnas}
                data={
                  inmunizaciones && inmunizaciones.length > 0
                    ? inmunizaciones.filter(
                      (x) => (x.tipo === 'Rotavirus Monovalente'
                            && selRota === 'M'
                            && x.otras === 1)
                          || (x.tipo === 'Rotavirus Pentavalente'
                            && selRota === 'P'
                            && x.otras === 1)
                          || (x.tipo === 'Varicela (lugar de alta incidencia)'
                            && selVaricela === 'A'
                            && x.otras === 1)
                          || (x.tipo === 'Varicela (lugar de baja incidencia)'
                            && selVaricela === 'B'
                            && x.otras === 1)
                          || (x.tipo !== 'Rotavirus Monovalente'
                            && x.tipo !== 'Rotavirus Pentavalente'
                            && x.tipo !== 'Varicela (lugar de alta incidencia)'
                            && x.tipo !== 'Varicela (lugar de baja incidencia)'
                            && x.otras === 1),
                    )
                    : []
                }
                selRota={selRota}
                setSelRota={setSelRota}
                selVaricela={selVaricela}
                setSelVaricela={setSelVaricela}
                inmunizaciones={inmunizaciones}
                setInmunizaciones={setInmunizaciones}
                gestacion="no"
              />
            </div>
            <div className="flex justify-end m-0 mr-4">
              <Button
                color="primary"
                size="small"
                className={classes.noTextTransform}
                startIcon={<AddCircleOutlineIcon />}
                onClick={() => {
                  setOpenModal(true);
                }}
              >
                {t('antecedentes-pnp-inmunizaciones-esquema-modal-agregar')}
              </Button>
            </div>
          </div>
          {parrafosPiePagina(
            inmunizaciones && inmunizaciones.length > 0 ? inmunizaciones[0].esquema : 0,
          )}
        </div>
      )}
      <div className="text-right">
        <Button
          variant="contained"
          color="primary"
          type="button"
          size="large"
          onClick={() => sendToSave()}
        >
          {t('guardar')}
        </Button>
      </div>
    </div>
  );
}

export default Inmunizaciones;
