import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTable } from 'react-table';
import {
  FormControl,
  FormLabel,
  Select,
  MenuItem,
  ListSubheader,
  Checkbox,
  TextField,
  IconButton,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  withStyles,
} from '@material-ui/core';
import { CheckboxProps } from '@material-ui/core/Checkbox';
import InfoIcon from '@material-ui/icons/Info';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import {} from '../../../../utils/getCatalogos';
import { IPropsRolesPermisos } from './types';
import { IRolSeleccionado, ITipoRol } from '../../types';
import useStyles from './styles';
import { HtmlTooltip } from '../../../../utils/ComponentesJSX';

const WhiteCheckbox = withStyles({
  root: {
    color: 'white',
    '&$checked': {
      color: 'white',
    },
  },
})((props: CheckboxProps) => <Checkbox color="default" {...props} />);

function RolesPermisos(props: IPropsRolesPermisos) {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    objRolPermisos,
    setObjRolPermisos,
    tipoRoles,
    arrayRoles,
    catTablaCsSecciones,
    setDisabledSave,
    setDisabledNext,
    rolesPermisos,
    openAndDeleteRol,
  } = props;
  const columnas = [
    {
      Header: t('administrador_roles_componentes_seccion'),
      accessor: 'seccion',
    },
    {
      Header: t('administrador_roles_componentes_subseccion'),
      accessor: 'subSeccion',
    },
    {
      Header: t('administrador_roles_componentes_tab'),
      accessor: 'tab',
    },
    {
      Header: t('administrador_roles_componentes_subtab'),
      accessor: 'subTab',
    },
    {
      Header: t('administrador_roles_componentes_lectura'),
      accessor: 'lectura',
    },
    {
      Header: t('administrador_roles_componentes_escritura'),
      accessor: 'escritura',
    },
  ];
  const [showTable, setShowTable] = useState<boolean>(false);
  const [tipoRolSeleccionado, setTipoRolSeleccionado] = useState<string>('');

  useEffect(() => {
    if (
      objRolPermisos.arrayRolesSeleccionados.find(
        (element) => element.checkEscritura || element.checkLectura,
      )
    ) {
      setDisabledSave(false);
    } else {
      setDisabledSave(true);
    }
    setDisabledNext(false);
  }, [objRolPermisos]);
  useEffect(() => {
    setShowTable(false);
  }, [rolesPermisos]);

  const reviewDisabled = () => {
    if (objRolPermisos.checkNuevoRol && objRolPermisos.nombre && objRolPermisos.tipoRol) {
      return false;
    }
    return true;
  };
  const reviewCheckHeader = (txtId: string) => {
    if (
      (txtId === 'lectura' && objRolPermisos.checkGenLectura)
      || (txtId === 'escritura' && objRolPermisos.checkGenEscritura)
    ) {
      return true;
    }
    return false;
  };
  const reviewCheckTable = (txtId: string, arrayId: number) => {
    if (
      txtId === 'lectura'
      && objRolPermisos.arrayRolesSeleccionados.find(
        (valueArray) => valueArray.id === objRolPermisos.arrayRolesSeleccionados[arrayId].id
          && valueArray.checkLectura,
      )
    ) {
      return true;
    }
    if (
      txtId === 'escritura'
      && objRolPermisos.arrayRolesSeleccionados.find(
        (valueArray) => valueArray.id === objRolPermisos.arrayRolesSeleccionados[arrayId].id
          && valueArray.checkEscritura,
      )
    ) {
      return true;
    }
    return false;
  };
  const changeCheckHeader = (txtId: string, active: boolean) => {
    if (txtId === 'lectura' && !active) {
      setObjRolPermisos({
        ...objRolPermisos,
        checkGenLectura: active,
        arrayRolesSeleccionados: objRolPermisos.arrayRolesSeleccionados.map((valorArrayRol) => ({
          ...valorArrayRol,
          checkLectura: false,
        })),
      });
    } else if (txtId === 'lectura' && active) {
      setObjRolPermisos({
        ...objRolPermisos,
        checkGenLectura: active,
        arrayRolesSeleccionados: objRolPermisos.arrayRolesSeleccionados.map((valorArrayRol) => ({
          ...valorArrayRol,
          checkLectura: true,
        })),
      });
    } else if (txtId === 'escritura' && !active) {
      setObjRolPermisos({
        ...objRolPermisos,
        checkGenEscritura: active,
        arrayRolesSeleccionados: objRolPermisos.arrayRolesSeleccionados.map((valorArrayRol) => ({
          ...valorArrayRol,
          checkEscritura: false,
        })),
      });
    } else if (txtId === 'escritura' && active) {
      setObjRolPermisos({
        ...objRolPermisos,
        checkGenEscritura: active,
        arrayRolesSeleccionados: objRolPermisos.arrayRolesSeleccionados.map((valorArrayRol) => ({
          ...valorArrayRol,
          checkEscritura: true,
        })),
      });
    }
  };
  const changeCheckTable = (txtId: string, arrayId: number, active: boolean) => {
    setObjRolPermisos({
      ...objRolPermisos,
      checkGenEscritura: txtId === 'escritura' ? false : objRolPermisos.checkGenEscritura,
      checkGenLectura: txtId === 'lectura' ? false : objRolPermisos.checkGenLectura,
      arrayRolesSeleccionados: objRolPermisos.arrayRolesSeleccionados.map((valorArray) => {
        if (
          valorArray.id === objRolPermisos.arrayRolesSeleccionados[arrayId].id
          && txtId === 'lectura'
        ) {
          return { ...valorArray, checkLectura: active };
        }
        if (
          valorArray.id === objRolPermisos.arrayRolesSeleccionados[arrayId].id
          && txtId === 'escritura'
        ) {
          return { ...valorArray, checkEscritura: active };
        }
        return valorArray;
      }),
    });
  };
  const cleanTable = () => {
    setObjRolPermisos({
      id: -1,
      checkNuevoRol: false,
      nombre: '',
      tipoRol: {
        id: -1,
        tipo: '',
        grupo: '',
        secciones: [],
      },
      asignado: false,
      checkGenLectura: false,
      checkGenEscritura: false,
      arrayRolesSeleccionados: [],
    });
    setShowTable(false);
  };

  const TableDraw = (val: any) => {
    // Use the state and functions returned from useTable to build your UI
    const { columns, data, nombreColumna } = val;
    const {
      getTableProps, headerGroups, rows, prepareRow,
    } = useTable({
      columns,
      data,
    });
    // Render the UI for your table
    return (
      <Table {...getTableProps()}>
        <TableHead>
          <TableRow>
            <TableCell align="center" colSpan={4} className={classes.header}>
              {t('administrador_roles_componentes')}
            </TableCell>
            <TableCell align="center" colSpan={2} className={classes.header}>
              {nombreColumna}
            </TableCell>
          </TableRow>
          {headerGroups.map((headerGroup) => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => {
                if (column.id === 'lectura' || column.id === 'escritura') {
                  return (
                    <TableCell {...column.getHeaderProps()} className={classes.header}>
                      <div className="flex items-center">
                        <WhiteCheckbox
                          checked={reviewCheckHeader(column.id)}
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) => changeCheckHeader(column.id, event.target.checked)}
                          name="administrador_roles_medico_m"
                        />
                        {column.render('Header')}
                      </div>
                    </TableCell>
                  );
                }
                return (
                  <TableCell {...column.getHeaderProps()} className={classes.header}>
                    {column.render('Header')}
                  </TableCell>
                );
              })}
            </TableRow>
          ))}
        </TableHead>
        <TableBody>
          {rows.map((row, rowId) => {
            prepareRow(row);
            return (
              <TableRow {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  const rowIndex = parseInt(cell.row.id, 10) || 0;
                  if (
                    cell.column
                    && (cell.column.id === 'lectura' || cell.column.id === 'escritura')
                  ) {
                    return (
                      <TableCell
                        {...cell.getCellProps()}
                        className={rowIndex % 2 === 0 ? `${classes.row}` : 'inherit'}
                      >
                        <Checkbox
                          checked={reviewCheckTable(cell.column.id, rowId)}
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) => changeCheckTable(cell.column.id, rowId, event.target.checked)}
                          name="administrador_roles_medico_m"
                          color="primary"
                        />
                      </TableCell>
                    );
                  }
                  return (
                    <TableCell
                      {...cell.getCellProps()}
                      className={rowIndex % 2 === 0 ? `${classes.row}` : 'inherit'}
                    >
                      {cell.render('Cell')}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    );
  };

  return (
    <div className="p-2">
      <div className="border-solid border-2 border-gray-300 rounded-lg p-4">
        <div className="flex flex-col justify-center">
          <h1 className="text-blue-500 font-normal m-2 text-center">{t('administrador_roles')}</h1>
        </div>
        <div className="grid grid-cols-6 gap-4">
          <div className="flex items-center">
            <span className="text-gray-500">{`- ${t('administrador_roles_medico_m')} `}</span>
          </div>
          <div className="flex">
            <HtmlTooltip title={<p>{t('administrador_roles_medico_tool')}</p>}>
              <IconButton size="small">
                <InfoIcon color="primary" fontSize="small" />
              </IconButton>
            </HtmlTooltip>
          </div>
        </div>
        <div className="grid grid-cols-6 gap-4">
          <div className="flex items-center">
            <span className="text-gray-500">{`- ${t('administrador_roles_enfermero_m')} `}</span>
          </div>
          <div className="flex">
            <HtmlTooltip title={<p>{t('administrador_roles_enfermero_tool')}</p>}>
              <IconButton size="small">
                <InfoIcon color="primary" fontSize="small" />
              </IconButton>
            </HtmlTooltip>
          </div>
        </div>
        <div className="grid grid-cols-6 gap-4">
          <div className="flex items-center">
            <span className="text-gray-500">{`- ${t('administrador_roles_administrativo_m')} `}</span>
          </div>
          <div className="flex">
            <HtmlTooltip title={<p>{t('administrador_roles_administrativo_tool')}</p>}>
              <IconButton size="small">
                <InfoIcon color="primary" fontSize="small" />
              </IconButton>
            </HtmlTooltip>
          </div>
        </div>
        {rolesPermisos.length > 0 && (
          <>
            <hr />
            <h3 className="text-gray-500 font-normal my-2">
              {t('administrador_roles_perfiles_creados')}
            </h3>
            {rolesPermisos.map((valorRol, inxArray) => (
              <>
                <div className="grid grid-cols-4 gap-4">
                  <div className="flex items-center">
                    <span className="text-gray-500">
                      {`- ${t('administrador_roles_nombre_rol')}: ${valorRol.nombre}`}
                    </span>
                  </div>
                  <div className="flex items-center">
                    <span className="text-gray-500">
                      {`${t('administrador_roles_tipo_rol')}: ${t(
                        `administrador_roles_${valorRol.tipoRol.tipo}`,
                      )}`}
                    </span>
                  </div>
                  <div className="flex items-center">
                    <span className="text-gray-500">
                      {`${t('administrador_roles_id_rol')}: ${valorRol.id}`}
                    </span>
                  </div>
                  <div className="flex items-center justify-end">
                    <IconButton
                      aria-label="edit"
                      color="primary"
                      onClick={() => {
                        setTipoRolSeleccionado(valorRol.tipoRol.tipo);
                        setObjRolPermisos(rolesPermisos[inxArray]);
                        setShowTable(true);
                        document
                          .getElementById('titulo-nuevo-rol')
                          ?.scrollIntoView({ behavior: 'smooth' });
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                    <HtmlTooltip
                      title={`${t('no_se_puede_eliminar_rol')}`}
                      disableHoverListener={!valorRol.asignado}
                    >
                      <span>
                        <IconButton
                          aria-label="delete"
                          color="primary"
                          onClick={() => openAndDeleteRol(rolesPermisos[inxArray].id)}
                          disabled={valorRol.asignado}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </span>
                    </HtmlTooltip>
                  </div>
                </div>
              </>
            ))}
          </>
        )}
        <hr />
        <h3 className="text-gray-500 font-normal m-0 mb-4" id="titulo-nuevo-rol">
          {t('administrador_roles_nuevo_rol')}
        </h3>
        <div className="grid grid-cols-6 justify-items-stretch gap-4">
          <div className="flex items-center col-span-3">
            <div className="pt-2">
              <Checkbox
                checked={objRolPermisos.checkNuevoRol}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setTipoRolSeleccionado('');
                  setObjRolPermisos({ ...objRolPermisos, checkNuevoRol: event.target.checked });
                }}
                name="administrador_roles_nuevo_rol"
                color="primary"
              />
            </div>
            <div className="w-full">
              <FormLabel>{t('administrador_roles_nombre_rol')}</FormLabel>
              <TextField
                variant="outlined"
                fullWidth
                inputProps={{ autocomplete: 'off', 'aria-autocomplete': 'none', maxLength: 250 }}
                placeholder={t('administrador_roles_nombre_rol')}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setObjRolPermisos({ ...objRolPermisos, nombre: event.target.value });
                }}
                value={objRolPermisos.nombre}
                disabled={!objRolPermisos.checkNuevoRol}
              />
            </div>
          </div>
          {objRolPermisos.checkNuevoRol && (
            <>
              <div className="flex items-center col-span-2">
                <FormControl variant="outlined" fullWidth>
                  <FormLabel>
                    <span className="pr-4">{t('administrador_roles_tipo_rol')}</span>
                    {tipoRolSeleccionado !== '' && (
                      <HtmlTooltip
                        title={(
                          <p>
                            {tipoRoles.find((val: ITipoRol) => val.tipo === tipoRolSeleccionado)
                              ?.grupo === 'salud'
                              ? t('administrador_roles_rsalud_tool')
                              : t('administrador_roles_radministrativo_tool')}
                          </p>
                        )}
                      >
                        <IconButton size="small">
                          <InfoIcon color="primary" fontSize="small" />
                        </IconButton>
                      </HtmlTooltip>
                    )}
                  </FormLabel>
                  <Select
                    displayEmpty
                    id="rolSeleccionado"
                    value={tipoRolSeleccionado}
                    onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                      const val = event.target.value as string;
                      if (val) {
                        setTipoRolSeleccionado(val);
                      }
                    }}
                    placeholder={t('administrador_roles_tipo')}
                    disabled={objRolPermisos.id !== -1}
                  >
                    <ListSubheader>{t('administrador_roles_administrativo')}</ListSubheader>
                    {tipoRoles
                      .filter((rol: ITipoRol) => rol.grupo === 'administrativo')
                      .map((rol: ITipoRol) => (
                        <MenuItem value={rol.tipo}>{t(`administrador_roles_${rol.tipo}`)}</MenuItem>
                      ))}
                    <ListSubheader>{t('administrador_roles_salud')}</ListSubheader>
                    {tipoRoles
                      .filter((rol: ITipoRol) => rol.grupo === 'salud')
                      .map((rol: ITipoRol) => (
                        <MenuItem value={rol.tipo}>{t(`administrador_roles_${rol.tipo}`)}</MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </div>
              <div className="flex flex-col items-start justify-center">
                <Button
                  color="primary"
                  size="small"
                  onClick={() => {
                    const tipoRol = tipoRoles.find(
                      (val: ITipoRol) => val.tipo === tipoRolSeleccionado,
                    );
                    if (tipoRol) {
                      setObjRolPermisos({
                        ...objRolPermisos,
                        tipoRol,
                        arrayRolesSeleccionados: arrayRoles.filter((rol: IRolSeleccionado) => tipoRol.secciones.includes(rol.id)),
                      });
                      setShowTable(true);
                    }
                  }}
                  disabled={reviewDisabled()}
                  className={classes.noTextTranform}
                  startIcon={<AddCircleOutlineIcon />}
                >
                  {t('administrador_roles_agrega')}
                </Button>
                <Button
                  color="primary"
                  size="small"
                  onClick={cleanTable}
                  className={classes.noTextTranform}
                  startIcon={<CancelOutlinedIcon />}
                >
                  {t('administrador_roles_cancela')}
                </Button>
              </div>
            </>
          )}
        </div>
        {showTable && (
          <div className="pt-2">
            <TableDraw
              columns={columnas}
              data={catTablaCsSecciones.filter((valCat) => tipoRoles
                .find((val) => val.tipo === tipoRolSeleccionado)
                ?.secciones.includes(valCat.id))}
              nombreColumna={objRolPermisos.nombre}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default RolesPermisos;
