import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import SaveIcon from '@material-ui/icons/Save';
import SyncIcon from '@material-ui/icons/Sync';
import { RootState } from '../../../../../../../store';
import useStyles from '../../../../../../../common/styles';
import { ICatalogosEstandar } from '../../../../../../../common/types';
import {
  sendTipoExamen,
  sendOcupacion,
  sendRestriccion,
  sendCausaNoAptitud,
} from '../../../../../../../utils/sendInfo';
import { IFormularioCertificadosProps } from './types';
import {
  getCatalogoCausasNoAptitud,
  getCatalogoOcupacion,
  getCatalogoRestricciones,
  getCatalogoTipoExamen,
} from '../../../../../../../utils/getCatalogos';
import DeleteDialog from '../../../../../../../components/DeleteDialog';
import { setRequest } from '../../../../../../../actions/request/types';

function FormularioCatalogo({
  tipoCatalogo,
  catCategorias,
  setCatTiposExamen,
  setCatPuestos,
  setCatRestricciones,
  setCatCausasNoAptitud,
  objCatalogo,
  setObjCatalogo,
}: IFormularioCertificadosProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { idMedico } = useSelector((state: RootState) => state.Me);
  const { idPaciente } = useSelector((state: RootState) => state.BasicosPaciente);
  const { idOrganizacion } = useSelector((state: RootState) => state.Organizaciones.organizacion);
  const [saveAlert, setSaveAlert] = useState<boolean>(false);

  const handleSaveAlertClose = (
    event: React.SyntheticEvent | React.MouseEvent,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setSaveAlert(false);
  };
  const handleChangeCategoria = (event: any, newValue: ICatalogosEstandar | null) => {
    setObjCatalogo({
      ...objCatalogo,
      categoria: newValue || { id: 0, label: '' },
    });
  };
  const handleChangeNombre = (event: React.ChangeEvent<HTMLInputElement>) => {
    setObjCatalogo({
      ...objCatalogo,
      label: event.target.value as string,
    });
  };

  const disableSave = () => (tipoCatalogo === 'tipo_examen' && objCatalogo.categoria.id === 0)
    || objCatalogo.label.length === 0;

  const handleAddCatalogo = () => {
    let sendFunc = sendTipoExamen;
    if (tipoCatalogo === 'puesto') {
      sendFunc = sendOcupacion;
    }
    if (tipoCatalogo === 'restricciones') {
      sendFunc = sendRestriccion;
    }
    if (tipoCatalogo === 'causas_no_aptitud') {
      sendFunc = sendCausaNoAptitud;
    }
    let dataToSend = {};
    if (tipoCatalogo === 'tipo_examen') {
      dataToSend = {
        datos: {
          id: objCatalogo.id,
          label: objCatalogo.label,
          idCategoria: objCatalogo.categoria.id,
        },
        idMedico,
        idPaciente,
        idOrganizacion,
      };
    } else {
      dataToSend = {
        datos: {
          id: objCatalogo.id,
          label: objCatalogo.label,
        },
        idMedico,
        idPaciente,
        idOrganizacion,
      };
    }
    dispatch(
      setRequest({
        type: 'send',
        requestFunction: sendFunc(dataToSend),
        successFunction: () => {
          if (tipoCatalogo === 'tipo_examen') {
            dispatch(
              setRequest({
                type: 'get',
                requestFunction: getCatalogoTipoExamen(idOrganizacion),
                successFunction: (result: any) => setCatTiposExamen(result.datos),
              }),
            );
          } else if (tipoCatalogo === 'puesto') {
            dispatch(
              setRequest({
                type: 'get',
                requestFunction: getCatalogoOcupacion(idOrganizacion),
                successFunction: (result: any) => setCatPuestos(result.datos),
              }),
            );
          } else if (tipoCatalogo === 'restricciones') {
            dispatch(
              setRequest({
                type: 'get',
                requestFunction: getCatalogoRestricciones(idOrganizacion),
                successFunction: (result: any) => setCatRestricciones(result.datos),
              }),
            );
          } else if (tipoCatalogo === 'causas_no_aptitud') {
            dispatch(
              setRequest({
                type: 'get',
                requestFunction: getCatalogoCausasNoAptitud(idOrganizacion),
                successFunction: (result: any) => setCatCausasNoAptitud(result.datos),
              }),
            );
          }
        },
      }),
    );
  };

  return (
    <div className="p-4 bg-white">
      <DeleteDialog
        open={saveAlert}
        titulo={t('_atencion_')}
        descripcion={t('opcion_no_podras_cambiar_despues_de_guardar')}
        callBackAceptar={handleAddCatalogo}
        callBackClose={handleSaveAlertClose}
      />
      <div className="border-solid border-2 border-gray-300 rounded-lg p-4">
        <div
          className={`${
            tipoCatalogo === 'tipo_examen' ? 'grid grid-cols-2' : ''
          } gap-x-4 gap-y-2 mb-6 mt-4 items-center`}
        >
          {tipoCatalogo === 'tipo_examen' && (
            <h3 className="text-gray-600 font-medium m-0">{`${t('categoria')}*`}</h3>
          )}
          <h3 className="text-gray-600 font-medium m-0">{`${t('nombre_opcion')}*`}</h3>
          {tipoCatalogo === 'tipo_examen' && (
            <Autocomplete
              options={catCategorias}
              getOptionLabel={(option) => option.label}
              fullWidth
              noOptionsText={t('sin_resultados')}
              value={objCatalogo.categoria}
              onChange={(e, nV) => handleChangeCategoria(e, nV)}
              renderInput={(params) => (
                <TextField {...params} placeholder={t('selecciona')} variant="outlined" />
              )}
            />
          )}
          <TextField
            variant="outlined"
            onChange={handleChangeNombre}
            value={objCatalogo.label}
            FormHelperTextProps={{ className: classes.absoluteBottom }}
            fullWidth
            inputProps={{ maxLength: 50, autoComplete: 'off', 'aria-autocomplete': 'none' }}
          />
        </div>
        <div className="flex justify-between my-4 items-center">
          <span className="text-gray-500">
            *
            {t('campos_obligatorios')}
          </span>
          <Button
            color="primary"
            variant="contained"
            startIcon={objCatalogo.id === -1 ? <SaveIcon /> : <SyncIcon />}
            onClick={() => setSaveAlert(true)}
            disabled={disableSave()}
          >
            {objCatalogo.id === -1 ? t('guardar') : t('actualizar')}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default FormularioCatalogo;
