import React, { useEffect, useState } from 'react';
import { createStyles, Theme, WithStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Dialog,
  DialogContent,
  DialogContentText,
  Slide,
  FormControl,
  Select,
  MenuItem,
  TextField,
  ButtonGroup,
  Button,
  FormControlLabel,
  Checkbox,
  RadioGroup,
  Radio,
  CircularProgress,
} from '@material-ui/core';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import Autocomplete from '@material-ui/lab/Autocomplete';
import DateDayjsUtils from '@date-io/dayjs';
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import PhoneInput from 'react-phone-input-2';
/** importacion de validaciones */
/** Importacion de estilos */
import es from 'react-phone-input-2/lang/es.json';
import 'react-phone-input-2/lib/bootstrap.css';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import CalendarTodayRoundedIcon from '@material-ui/icons/CalendarTodayRounded';
import InfoIcon from '@material-ui/icons/Info';
import { TransitionProps } from '@material-ui/core/transitions';
import { HtmlTooltip } from '../../../utils/ComponentesJSX';
import { RootState } from '../../../store';
import { validacionEmail, validacionSoloNumeros } from '../../../constants/validaciones';
import { setSnackComplete } from '../../../actions/snackbar/types';
import { getPacientesAgendaBusqueda } from '../../../utils/getCatalogos';
import { ITextFieldEstandar, ICountryPhoneInput } from '../../../common/types';
import { IAgendaDialogoProps, IPaciente } from './types';
import { useStyles } from './styles';
import { removePaciente, removeUserSession } from '../../../utils/commonStore';
import { resetMe } from '../../../actions/me/actions';
import { limpiarRedux } from '../../../utils/reduxCommon';
import { setAuth } from '../../../actions/auth/actions';

const styles = (theme: Theme) => createStyles({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: '#111827',
  },
});

const Transition = React.forwardRef(
  (
    // eslint-disable-next-line react/require-default-props
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>,
    // eslint-disable-next-line react/jsx-props-no-spreading
  ) => <Slide direction="up" ref={ref} {...props} />,
);

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: (event: React.SyntheticEvent | React.MouseEvent, reason?: string | undefined) => void;
}

function AgendaDialogo(props: IAgendaDialogoProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  dayjs.extend(isSameOrBefore);
  dayjs.extend(isSameOrAfter);
  const {
    open,
    titulo,
    callBackClose,
    arrayTipo,
    setDatoGuardar,
    handleFinAgregar,
    datoGuardar,
    handleDeleteCita,
  } = props;
  const { idMedico, pais } = useSelector((state: RootState) => state.Me);
  const { consultorio, consultorios } = useSelector((state: RootState) => state.Consultorios);
  const { idOrganizacion } = useSelector((state: RootState) => state.Organizaciones.organizacion);
  const [arraySelRepetir] = useState<string[]>([
    t('calendar-no-repite'),
    t('calendar-all-days'),
    t('calendar-semana'),
    t('calendar-mes'),
    t('calendar-anualmente'),
  ]);
  // 1 - repite dias, 2 - repite semanas, 3 - repite mes, 4 - repite año
  const [emailField, setEmailField] = useState<ITextFieldEstandar>({
    helperText: '',
    error: false,
  });
  const [labelIndique, setLabelIndique] = useState<string>('');
  // para el autocomplete
  const [openAutocomplete, setOpenAutocomplete] = useState<boolean>(false);
  const [loadingAutocomplete, setLoadingAutocomplete] = useState<boolean>(false);
  const [inputAutocomplete, setInputAutcomplete] = useState<string>('');
  const [catAutocomplete, setCatAutocomplete] = useState<IPaciente[]>([]);
  const [catCmpFind, setCatCmpFind] = useState<any[]>([]);
  const handleChangeTipo = (event: React.ChangeEvent<{ value: unknown }>) => {
    const val = event.target.value as number;
    const editDato = { ...datoGuardar };
    const objSave = arrayTipo.find((x) => x.id === val);
    editDato.tipo = objSave;
    editDato.title = objSave?.label ? objSave?.label : '';
    setDatoGuardar(editDato);
  };
  const handleChangeNuevoPaciente = (event: React.ChangeEvent<{ value: unknown }>) => {
    const val = event.target.value as string;
    const editDato = { ...datoGuardar };
    editDato.nuevoPaciente = val;
    if (datoGuardar && datoGuardar.tipo && datoGuardar.tipo.id === 0) {
      editDato.title = val;
    }
    setDatoGuardar(editDato);
  };
  const handleChangePrimero = (event: React.ChangeEvent<HTMLInputElement>) => {
    const editDato = { ...datoGuardar };
    editDato.allDay = event.target.checked;
    setDatoGuardar(editDato);
  };
  const handleChangeRadio = (event: React.ChangeEvent<HTMLInputElement>) => {
    const upRadio = {
      ...datoGuardar,
      radio: (event.target as HTMLInputElement).value,
      idAuto: {
        id: 0,
        nombre: '',
        docId: '',
      },
    };
    setDatoGuardar(upRadio);
  };
  const handleChangeEmail = (event: React.ChangeEvent<{ value: unknown }>) => {
    const upEmail = { ...datoGuardar, email: event.target.value as string };
    setDatoGuardar(upEmail);
    setEmailField({ helperText: '', error: false });
  };
  const handleBlurEmail = (event: React.ChangeEvent<{ value: unknown }>) => {
    const val = event.target.value as string;
    if (!val.length || validacionEmail(val)) {
      setEmailField({ helperText: '', error: false });
    } else {
      setEmailField({ helperText: t('email_incorrecto'), error: true });
    }
  };
  const handleChangeDescripcion = (e: React.ChangeEvent<{ value: unknown }>) => {
    const upDescripcion = { ...datoGuardar, descripcion: e.target.value as string };
    setDatoGuardar(upDescripcion);
  };
  const clearData = () => {
    setEmailField({
      helperText: '',
      error: false,
    });
    const clrData = { ...datoGuardar };
    clrData.title = '';
    clrData.allDay = false;
    clrData.tipo = {
      id: 0,
      label: '',
    };
    clrData.idAuto = {
      id: 0,
      nombre: '',
      docId: '',
    };
    clrData.telefono = '';
    clrData.ladaTel = '';
    clrData.email = '';
    clrData.selector = -1;
    clrData.descripcion = '';
    clrData.conteoEvento = '';
    clrData.radio = 'pre';
    setDatoGuardar(clrData);
  };
  const agregarElemento = () => {
    handleFinAgregar();
    setCatAutocomplete([]);
    setInputAutcomplete('');
    clearData();
    callBackClose();
  };
  const handleDisabled = () => {
    if (
      emailField.error
      || (datoGuardar.tab !== 'evento' && datoGuardar.title === '')
      || (datoGuardar.tab === 'evento'
        && (datoGuardar.tipo?.id === 0
          || (datoGuardar.radio === 'new' && datoGuardar.nuevoPaciente === '')
          || (datoGuardar.radio === 'pre' && datoGuardar.idAuto.id === 0)))
      || (datoGuardar.selector > 0 && datoGuardar.conteoEvento === '')
    ) {
      return true;
    }
    return false;
  };
  // para el autocomplete
  const handleChangeGenerico = (event: any, newValue: IPaciente | null, reason: string) => {
    const updAutocomplete = { ...datoGuardar };
    if (reason === 'clear') {
      setOpenAutocomplete(true);
      updAutocomplete.idAuto = { id: 0, nombre: '', docId: '' };
      updAutocomplete.telefono = '';
      updAutocomplete.ladaTel = '';
      updAutocomplete.email = '';
    }
    if (newValue) {
      setLoadingAutocomplete(true);
      updAutocomplete.idAuto = newValue;
      const datToDatos = catCmpFind.find((val) => val.id === newValue.id);
      updAutocomplete.telefono = datToDatos && datToDatos.datos && datToDatos.datos.celular ? datToDatos.datos.celular : '';
      updAutocomplete.ladaTel = datToDatos && datToDatos.datos && datToDatos.datos.ladaTel
        ? `'${datToDatos.datos.ladaTel}'`
        : '';
      updAutocomplete.email = datToDatos && datToDatos.datos && datToDatos.datos.email ? datToDatos.datos.email : '';
    } else {
      updAutocomplete.idAuto = { id: 0, nombre: '', docId: '' };
      updAutocomplete.telefono = '';
      updAutocomplete.ladaTel = '';
      updAutocomplete.email = '';
    }
    setDatoGuardar(updAutocomplete);
  };
  useEffect(() => {
    if (inputAutocomplete && inputAutocomplete.length >= 3) {
      const abortController = new AbortController();
      const { signal } = abortController;
      setLoadingAutocomplete(true);
      getPacientesAgendaBusqueda(
        idMedico,
        idOrganizacion,
        consultorio.idConsultorio,
        inputAutocomplete,
        signal,
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.code === 200) {
            const arrayDatos = result.datos.map((selVal: any) => selVal.paciente);
            setCatAutocomplete(arrayDatos);
            setCatCmpFind(result.datos);
          } else if (result.code === 300) {
            // el token y el idMedico no son el mismo
            /* Cerrar sesión */
            removeUserSession();
            dispatch(resetMe());
            limpiarRedux();
            dispatch(setAuth({ id: '', suscrito: false, acreditado: false }));
            history.push('/login');
          } else if (result.code === 301) {
            // el medico no tiene subscripcion activa
            /* Resetear y redirigir al checkout */
            removePaciente();
            limpiarRedux();
            dispatch(setAuth({ id: '', suscrito: false, acreditado: true }));
            history.push('/subscripcion');
          } else if (result.code === 302) {
            // el paciente y el medico no tienen relacion
            /* Resetear redux paciente y datos paciente y redirigir a paciente */
            removePaciente();
            limpiarRedux();
            history.push('/pacientes');
          } else {
            setCatAutocomplete([]);
            setCatCmpFind([]);
          }
          setLoadingAutocomplete(false);
        })
        .catch(() => {
          setCatAutocomplete([]);
          setCatCmpFind([]);
          setLoadingAutocomplete(false);
        });
      return () => abortController.abort();
    }
    return undefined;
  }, [inputAutocomplete]);
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      onClose={() => {
        clearData();
        callBackClose();
      }}
      aria-labelledby="alert-dialog-titulo"
      aria-describedby="alert-dialo-descripcion"
      fullWidth
      maxWidth="md"
    >
      <div className="flex justify-between items-center pl-4 bg-blue-800 text-white">
        <h2 className="font-normal">{titulo}</h2>
        <IconButton
          aria-label="cerrar"
          color="inherit"
          onClick={() => {
            clearData();
            callBackClose();
          }}
        >
          <CloseIcon />
        </IconButton>
      </div>
      <DialogContent>
        <DialogContentText id="alert-dialog-descripcion">
          <div className="pb-2">
            <ButtonGroup
              variant="contained"
              color="primary"
              aria-label="contained primary button group"
              size="large"
            >
              <Button
                classes={datoGuardar.tab === 'evento' ? { root: classes.buttonSelected } : {}}
                onClick={() => {
                  clearData();
                  setDatoGuardar({
                    ...datoGuardar,
                    tab: 'evento',
                    tipo: {
                      id: 0,
                      label: '',
                    },
                    title: '',
                  });
                }}
              >
                {t('calendar-evento')}
              </Button>
              <Button
                classes={datoGuardar.tab === 'fuera' ? { root: classes.buttonSelected } : {}}
                onClick={() => {
                  clearData();
                  setDatoGuardar({
                    ...datoGuardar,
                    tab: 'fuera',
                    tipo: {
                      id: 0,
                      label: '',
                    },
                    title: '',
                  });
                }}
              >
                {t('calendar-consultorio')}
              </Button>
              <Button
                classes={datoGuardar.tab === 'tarea' ? { root: classes.buttonSelected } : {}}
                onClick={() => {
                  clearData();
                  setDatoGuardar({
                    ...datoGuardar,
                    tab: 'tarea',
                    tipo: {
                      id: 0,
                      label: '',
                    },
                    title: '',
                  });
                }}
              >
                {t('calendar-tarea')}
              </Button>
              <Button
                classes={datoGuardar.tab === 'recordatorio' ? { root: classes.buttonSelected } : {}}
                onClick={() => {
                  clearData();
                  setDatoGuardar({
                    ...datoGuardar,
                    tab: 'recordatorio',
                    tipo: {
                      id: 0,
                      label: '',
                    },
                    title: '',
                  });
                }}
              >
                {t('calendar-recordatorio')}
              </Button>
            </ButtonGroup>
          </div>
          {datoGuardar.tab === 'evento' ? (
            <>
              <div className=" flex items-center py-2">
                <h4 className="text-gray-500 font-normal m-0">{t('calendar-agenda-ubicacion')}</h4>
                <HtmlTooltip title={<p>{t('calendar-agenda-ubicacion-tooltip')}</p>}>
                  <IconButton size="small">
                    <InfoIcon color="primary" fontSize="small" />
                  </IconButton>
                </HtmlTooltip>
              </div>
              <p className="text-gray-600 m-0 pd-2">
                {consultorios.find((elemento) => elemento.id === datoGuardar.idConsultorio)?.label}
              </p>
              <div className="pb-2 pt-2">
                <h4 className="text-gray-500 font-normal mt-0 mb-2">
                  {`${t('calendar-t-evento')}*`}
                </h4>
                <FormControl variant="outlined" fullWidth>
                  <Select
                    displayEmpty
                    labelId="tipo"
                    id="tipo"
                    value={
                      datoGuardar && datoGuardar.tipo && datoGuardar.tipo.id
                        ? datoGuardar.tipo.id
                        : 0
                    }
                    onChange={handleChangeTipo}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 375,
                        },
                      },
                    }}
                  >
                    <MenuItem value={0} disabled>
                      <span className="text-gray-300">{t('selecciona')}</span>
                    </MenuItem>
                    {arrayTipo.map((tipo, inx) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <MenuItem value={tipo.id} key={inx}>
                        {tipo.label.toUpperCase()}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className="pb-2 flex items-center">
                <h4 className="text-gray-500 font-normal m-0 pr-4">
                  {`${t('calendar-paciente')}*`}
                </h4>
                <RadioGroup
                  aria-label="paciente"
                  color="primary"
                  name="paciente"
                  value={datoGuardar.radio}
                  onChange={handleChangeRadio}
                  row
                >
                  <FormControlLabel
                    value="pre"
                    control={<Radio color="primary" />}
                    label={t('calendar-preexistente')}
                  />
                  <FormControlLabel
                    value="new"
                    control={<Radio color="primary" />}
                    label={t('calendar-nuevo')}
                  />
                </RadioGroup>
              </div>
              <div className="pb-2">
                {datoGuardar.radio === 'pre' ? (
                  <Autocomplete
                    open={openAutocomplete}
                    onOpen={() => {
                      setOpenAutocomplete(true);
                    }}
                    onClose={() => {
                      setOpenAutocomplete(false);
                    }}
                    getOptionLabel={(option) => (option.id > 0 ? `${option.nombre} (${option.docId})` : '')}
                    options={catAutocomplete}
                    loading={loadingAutocomplete}
                    loadingText="Buscando..."
                    noOptionsText={
                      inputAutocomplete.length <= 2
                        ? t('introduce-tres-caracteres')
                        : t('sin_resultados')
                    }
                    value={datoGuardar.idAuto}
                    onChange={(e, nV, r) => handleChangeGenerico(e, nV, r)}
                    onInputChange={(e, newInputValue) => {
                      if (newInputValue.length < 3) {
                        setCatAutocomplete([]);
                      }
                      setInputAutcomplete(newInputValue);
                    }}
                    fullWidth
                    filterOptions={(x) => x}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder={t('selecciona')}
                        variant="outlined"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {loadingAutocomplete ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                  />
                ) : (
                  <TextField
                    variant="outlined"
                    type="nuevo"
                    value={datoGuardar.nuevoPaciente}
                    onChange={handleChangeNuevoPaciente}
                    fullWidth
                    inputProps={{
                      maxLength: 100,
                      autoComplete: 'off',
                      'aria-autocomplete': 'none',
                    }}
                    placeholder={t('calendar-nuevo')}
                  />
                )}
              </div>
              <div className="grid grid-cols-6 gap-4 pb-2">
                <div className="col-span-2">
                  <h4 className="text-gray-500 font-normal mt-0 mb-2">{t('telefono')}</h4>
                  <PhoneInput
                    preferredCountries={[pais, 'us']}
                    country={pais}
                    localization={es}
                    enableSearch
                    searchPlaceholder={t('buscar')}
                    searchNotFound={t('sin_resultados')}
                    inputStyle={{ width: '100%' }}
                    value={`${datoGuardar.ladaTel}${datoGuardar.telefono}`}
                    onChange={(nValue, country: ICountryPhoneInput) => {
                      const { dialCode } = country;
                      const nuevoCel = nValue.slice(dialCode.length);
                      const phoneAct = {
                        ...datoGuardar,
                        telefono: nuevoCel,
                        ladaTel: country.dialCode,
                      };
                      setDatoGuardar(phoneAct);
                    }}
                  />
                </div>
                <div />
                <div className="col-span-3">
                  <h4 className="text-gray-500 font-normal mt-0 mb-2">{t('email')}</h4>
                  <TextField
                    variant="outlined"
                    type="email"
                    value={datoGuardar.email}
                    onChange={handleChangeEmail}
                    fullWidth
                    error={emailField.error}
                    helperText={emailField.helperText}
                    FormHelperTextProps={{ className: classes.absoluteBottom }}
                    inputProps={{
                      maxLength: 150,
                      autoComplete: 'off',
                      'aria-autocomplete': 'none',
                    }}
                    onBlur={handleBlurEmail}
                  />
                </div>
              </div>
            </>
          ) : (
            <div className="pb-2 pt-2">
              <h4 className="text-gray-500 font-normal mt-0 mb-2">
                {datoGuardar.tab === 'tarea'
                  ? `${t('calendar-tipo-tarea')}:*`
                  : `${t('calendar-nombre-evento')}:*`}
              </h4>
              <FormControl variant="outlined" fullWidth>
                <TextField
                  variant="outlined"
                  value={datoGuardar.title}
                  onChange={(e) => {
                    const conteoEv = { ...datoGuardar, title: e.target.value };
                    setDatoGuardar(conteoEv);
                  }}
                  fullWidth
                  inputProps={{ maxLength: 100, autoComplete: 'off', 'aria-autocomplete': 'none' }}
                  placeholder={t('especifica')}
                />
              </FormControl>
            </div>
          )}
          <h4 className="text-gray-500 font-normal mt-0 mb-2">{t('calendar-fyh')}</h4>
          <div className="grid grid-cols-6 gap-4 pb-2">
            <div className="col-span-2 m-0">
              <MuiPickersUtilsProvider locale="es" utils={DateDayjsUtils}>
                <KeyboardDateTimePicker
                  value={datoGuardar.start}
                  onChange={(e: any) => {
                    if (e && datoGuardar.end && dayjs(e.toDate()).isSameOrBefore(datoGuardar.end)) {
                      const dato = { ...datoGuardar };
                      dato.start = new Date(e.toDate());
                      setDatoGuardar(dato);
                    } else if (e && datoGuardar.end) {
                      const dato = { ...datoGuardar };
                      dato.start = new Date(e.toDate());
                      dato.end = new Date(dayjs(e).add(1, 'hour').toDate());
                      setDatoGuardar(dato);
                      dispatch(
                        setSnackComplete({
                          open: true,
                          severity: 'error',
                          mensaje: `${t('calendar-subir-fecha')}`,
                        }),
                      );
                    } else if (e) {
                      const dato = { ...datoGuardar };
                      dato.start = new Date(e.toDate());
                      setDatoGuardar(dato);
                    }
                  }}
                  format="DD/MM/YYYY hh:mm a"
                  label={t('calendar-desde')}
                  invalidDateMessage={t('calendar-error-fecha')}
                  keyboardIcon={<CalendarTodayRoundedIcon color="primary" />}
                />
              </MuiPickersUtilsProvider>
            </div>
            <div />
            <div className="col-span-2 m-0">
              <MuiPickersUtilsProvider locale="es" utils={DateDayjsUtils}>
                <KeyboardDateTimePicker
                  value={datoGuardar.end}
                  onChange={(e) => {
                    if (e && dayjs(e.toDate()).isSameOrAfter(datoGuardar.start)) {
                      const dato = { ...datoGuardar };
                      dato.end = new Date(e.toDate());
                      setDatoGuardar(dato);
                    } else if (e) {
                      dispatch(
                        setSnackComplete({
                          open: true,
                          severity: 'error',
                          mensaje: `${t('calendar-hasta-hora')}`,
                        }),
                      );
                    }
                  }}
                  minDate={datoGuardar.start}
                  format="DD/MM/YYYY hh:mm a"
                  label={t('calendar-hasta')}
                  minDateMessage={t('calendar-hasta-error')}
                  invalidDateMessage={t('calendar-error-fecha')}
                  keyboardIcon={<CalendarTodayRoundedIcon color="primary" />}
                />
              </MuiPickersUtilsProvider>
            </div>
          </div>
          <div className="py-2 grid grid-cols-6 gap-4 place-content-end">
            <div className="place-self-end justify-self-auto">
              <FormControlLabel
                value={datoGuardar.allDay}
                control={(
                  <Checkbox
                    name="allday"
                    color="primary"
                    checked={datoGuardar.allDay}
                    onChange={handleChangePrimero}
                  />
                )}
                label={t('calendar-all-day')}
              />
            </div>
            <div className="col-span-2 place-self-end justify-self-auto">
              <p className="h-2" />
              <FormControl variant="outlined" fullWidth>
                <Select
                  value={datoGuardar.selector >= 0 ? datoGuardar.selector : '0'}
                  onChange={(e: React.ChangeEvent<{ value: unknown }>) => {
                    const valId = e.target.value as number;
                    if (valId === 1) {
                      setLabelIndique(`${t('calendar-indique-dias')}:*`);
                    } else if (valId === 2) {
                      setLabelIndique(`${t('calendar-indique-semana')}:*`);
                    } else if (valId === 3) {
                      setLabelIndique(`${t('calendar-indique-mes')}:*`);
                    } else if (valId === 4) {
                      setLabelIndique(`${t('calendar-indique-anio')}:*`);
                    }
                    const upSelector = { ...datoGuardar, selector: valId };
                    setDatoGuardar(upSelector);
                  }}
                  displayEmpty
                >
                  {arraySelRepetir.map((label, indx) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <MenuItem value={indx} key={indx}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            {datoGuardar.selector >= 1 ? (
              <div className="grid grid-cols-2 gap-4 col-span-2">
                <h4 className="text-gray-500 font-normal m-0 col-span-2">{labelIndique}</h4>
                <TextField
                  variant="outlined"
                  type="conteoEvento"
                  value={datoGuardar.conteoEvento}
                  onChange={(e) => {
                    const valConteo = e.target.value;
                    if (valConteo === '' || validacionSoloNumeros(valConteo)) {
                      const updConteoEvent = { ...datoGuardar, conteoEvento: valConteo };
                      setDatoGuardar(updConteoEvent);
                    }
                  }}
                  fullWidth
                  inputProps={{ maxLength: 2, autoComplete: 'off', 'aria-autocomplete': 'none' }}
                />
              </div>
            ) : null}
          </div>
          <div className="py-4">
            <h4 className="text-gray-500 font-normal mt-0 mb-2 col-span-2">{t('descripcion')}</h4>
            <TextField
              variant="outlined"
              type="descripcion"
              value={datoGuardar.descripcion}
              onChange={handleChangeDescripcion}
              fullWidth
              inputProps={{ maxLength: 300, autoComplete: 'off', 'aria-autocomplete': 'none' }}
              multiline
              rows={3}
            />
          </div>
          <div className="flex justify-between">
            <h4 className="text-gray-500 font-normal mt-0 mb-2 col-span-2">
              {t('campo-obligatorio')}
            </h4>
            <div>
              {datoGuardar.idAgenda !== -1 && (
                <Button
                  color="primary"
                  size="small"
                  onClick={handleDeleteCita}
                  className={classes.noTextTranform}
                >
                  {t('calendar-agenda-eliminar-cita')}
                </Button>
              )}
              <Button
                variant="contained"
                color="primary"
                type="button"
                size="large"
                onClick={agregarElemento}
                disabled={handleDisabled()}
              >
                {t('guardar')}
              </Button>
            </div>
          </div>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
}

export default AgendaDialogo;
