import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { setSnackComplete } from '../../actions/snackbar/types';
import { obtenerURLArchivo } from '../../utils/getFiles';
import { IRequestFile } from '../../actions/requestFile/actions';
import { setRequestFile } from '../../actions/requestFile/types';

function FileRequestComponent(props: IRequestFile) {
  const {
    type,
    idMedico,
    idPaciente,
    idArchivo,
    nombreArchivo,
    pathArchivo,
    tipoArchivo,
    successFunction,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const requestFileInitial: IRequestFile = {
    type: null,
    idMedico: null,
    idPaciente: null,
    idArchivo: -1,
    nombreArchivo: null,
    pathArchivo: '',
    tipoArchivo: '',
  };

  const getFile = () => {
    obtenerURLArchivo(idMedico, idPaciente, idArchivo, nombreArchivo, pathArchivo, tipoArchivo)
      .then((response) => response.json())
      .then((result) => {
        if (result.code === 200 && result.data.url) {
          if (successFunction) {
            successFunction(result.data.url);
          } else {
            window.open(result.data.url, '_blank')?.focus();
          }
        } else {
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'error',
              mensaje: `${t('message-error-file')}${result.msg ? `: ${result.msg}` : ''}`,
            }),
          );
        }
      })
      .catch((err) => {
        dispatch(
          setSnackComplete({
            open: true,
            severity: 'error',
            mensaje: `${t('message-error-file')}: ${err}`,
          }),
        );
      });
  };

  const sendFile = () => {
    obtenerURLArchivo(idMedico, idPaciente, idArchivo, nombreArchivo, pathArchivo, tipoArchivo)
      .then((response) => response.json())
      .then((result) => {
        if (result.code === 200 && result.data.url) {
          if (successFunction) {
            successFunction(result.data.url);
          } else {
            window.open(result.data.url, '_blank')?.focus();
          }
        } else {
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'error',
              mensaje: `${t('message-error-file')}${result.msg ? `: ${result.msg}` : ''}`,
            }),
          );
        }
        dispatch(setRequestFile(requestFileInitial));
      })
      .catch((err) => {
        dispatch(
          setSnackComplete({
            open: true,
            severity: 'error',
            mensaje: `${t('message-error-file')}: ${err}`,
          }),
        );
        dispatch(setRequestFile(requestFileInitial));
      });
  };

  useEffect(() => {
    if (type !== null) {
      if (type === 'get') {
        getFile();
      } else {
        sendFile();
      }
    }
  }, [type]);

  return <></>;
}

export default FileRequestComponent;
