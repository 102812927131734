import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FormControlLabel, Checkbox } from '@material-ui/core';
import { RootState } from '../../../../../store';
import { getCatalogoAlergias } from '../../../../../utils/getCatalogos';
import { ICatalogosEstandar } from '../../../../../common/types';
import AlergiasForm from './AlergiasForm';
import ListaAlergias from './ListaAlergias';
import DeleteDialog from '../../../../../components/DeleteDialog';
import { alergiasEliminarAlergia } from '../../../../../utils/deleteInfo';
import { getPaciente } from '../../../../../actions/basicosPaciente/actions';
import { alergiasGuardarSinAntecedentes } from '../../../../../utils/sendInfo';
import { IAlergiasProps } from './types';
import { setRequest } from '../../../../../actions/request/types';

function Alergias(props: IAlergiasProps) {
  const {
    sinAntecedentes, setSinAntecedentes, alergias, setAlergias,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { idPaciente } = useSelector((state: RootState) => state.BasicosPaciente);
  const { idMedico } = useSelector((state: RootState) => state.Me);
  const { idConsulta } = useSelector((state: RootState) => state.Consulta);
  const {
    consultorio: { idConsultorio },
  } = useSelector((state: RootState) => state.Consultorios);
  const { idOrganizacion } = useSelector((state: RootState) => state.Organizaciones.organizacion);
  const [catAlergias, setCatAlergias] = useState<ICatalogosEstandar[]>([]);
  const [alergiaForm, setAlergiaForm] = useState<ICatalogosEstandar>({ id: 0, label: '' });
  const [gradoForm, setGradoForm] = useState<string>('');
  const [deleteAlert, setDeleteAlert] = useState<boolean>(false);
  const [editAlert, setEditAlert] = useState<boolean>(false);
  const [cambioAlert, setCambioAlert] = useState<boolean>(false);
  const [indexToDelete, setIndexToDelete] = useState<number>(-1);
  const [idActualizar, setIdActualizar] = useState<number>(-1);
  const [idBD, setIDBD] = useState<number>(-1);

  /* funcion para cerrar la alerta de borrado */
  const handleDeleteAlertClose = (
    event: React.SyntheticEvent | React.MouseEvent,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setDeleteAlert(false);
  };
  /* funcion para cerrar la alerta de edición */
  const handleEditAlertClose = (
    event: React.SyntheticEvent | React.MouseEvent,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setEditAlert(false);
  };
  /* funcion para cerrar la alerta de activar sin antecedentes de alergias */
  const handleCambioAlertClose = (
    event: React.SyntheticEvent | React.MouseEvent,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setCambioAlert(false);
  };
  /* funcion para editar una alergia, puede ser llamada directamente en caso de que no
  exita datos en el formulario de alergias (por eso puede recibir un parametro indice opcional)
  o no recibir ningun parametro pero si no recibe parametro necesita que se setee el idActualizar */
  const editAlergia = (indice = idActualizar) => {
    const { alergia, grado, idBD: indiceBD } = alergias[indice];
    setAlergiaForm(alergia);
    setGradoForm(grado);
    setIDBD(indiceBD);
    setEditAlert(false);
  };
  /* funciton para eliminar una alergia, como esta solamente es llamada por el modal de eliminar
  y el callback de eliminar no puede tener parametros, es por eso que se necesita setear
  el indexToDelete junto con el modal de eliminar, para que cuando este modal llame a la función
  esta funcion pueda funcionar sin ningun parametro dado, ya que toma el valor en indexToDelete */
  const deleteAlergia = () => {
    dispatch(
      setRequest({
        type: 'delete',
        requestFunction: alergiasEliminarAlergia({
          idPaciente,
          idMedico,
          idConsultorio,
          idBD: alergias[indexToDelete].idBD,
          idConsulta,
        }),
        successFunction: () => {
          dispatch(getPaciente(idPaciente, idMedico, idOrganizacion));
          setAlergias((listaPrev) => listaPrev.filter((_ale, indx) => indx !== indexToDelete));
          setIndexToDelete(-1);
        },
      }),
    );
    setDeleteAlert(false);
  };
  /* function que hace el cambio cuando se selecciona el checkbox sin antecedentes de alergias
  sabemos que este checkbox debe estar seleccionado cuando se llama a esta funcion por lo tanto
  es por eso que cuando logramos hacer el cambio a la BD cambiamos el checkbox a true */
  const handleCambioAntecedentesAlergias = () => {
    // aqui se debe mandar al endpoint para cambiar sin antecedentes
    dispatch(
      setRequest({
        type: 'send',
        requestFunction: alergiasGuardarSinAntecedentes({
          idPaciente,
          idMedico,
          idConsultorio,
        }),
        successFunction: (result: any) => {
          setSinAntecedentes(result.datos);
        },
      }),
    );
    setCambioAlert(false);
  };

  useEffect(() => {
    dispatch(
      setRequest({
        type: 'get',
        requestFunction: getCatalogoAlergias(),
        successFunction: (result: any) => setCatAlergias(result.datos),
      }),
    );
  }, []);

  return (
    <>
      <DeleteDialog
        open={deleteAlert}
        titulo={t('_atencion_')}
        descripcion={t('antecedentes-pnp-historias-eliminar')}
        callBackAceptar={deleteAlergia}
        callBackClose={handleDeleteAlertClose}
      />
      <DeleteDialog
        open={editAlert}
        titulo={t('_atencion_')}
        descripcion={t('si_quieres_editar_este_diagnostico_se_borrara_el_que_estas_creando')}
        callBackAceptar={editAlergia}
        callBackClose={handleEditAlertClose}
      />
      <DeleteDialog
        open={cambioAlert}
        titulo={t('_atencion_')}
        descripcion={t('_estas_seguro__si_existe_registros_anteriores_se_eliminaran')}
        callBackAceptar={handleCambioAntecedentesAlergias}
        callBackClose={handleCambioAlertClose}
      />
      <div>
        <h2 className="text-blue-800 font-normal m-0">{t('alergias')}</h2>
        <FormControlLabel
          control={(
            <Checkbox
              checked={sinAntecedentes}
              onChange={() => {
                if (sinAntecedentes) {
                  setSinAntecedentes(false);
                } else {
                  setCambioAlert(true);
                }
              }}
              name="sinAntecedentes"
              color="primary"
            />
          )}
          label={t('antecedentes-pp-alergias-sin-ant')}
          disabled={!!alergias.length}
        />
        {!sinAntecedentes && (
          <AlergiasForm
            catAlergias={catAlergias}
            alergiaForm={alergiaForm}
            gradoForm={gradoForm}
            setAlergiaForm={setAlergiaForm}
            setGradoForm={setGradoForm}
            setAlergias={setAlergias}
            alergias={alergias}
            idBD={idBD}
            idPaciente={idPaciente}
            setIDBD={setIDBD}
          />
        )}
      </div>
      {!!alergias.length && (
        <div className="mt-4 bg-white rounded p-4 shadow">
          <h3 className="text-blue-500 font-normal m-0 mb-4">{t('lista_de_alergias_referidas')}</h3>
          {alergias.map((alergiaObj, index) => (
            <ListaAlergias
              alergiaObj={alergiaObj}
              key={alergiaObj.idBD}
              index={index}
              idBD={idBD}
              setDeleteAlert={setDeleteAlert}
              setEditAlert={setEditAlert}
              setIndexToDelete={setIndexToDelete}
              setIdActualizar={setIdActualizar}
              editAlergia={editAlergia}
              gradoForm={gradoForm}
              alergiaForm={alergiaForm}
            />
          ))}
        </div>
      )}
    </>
  );
}

export default Alergias;
