import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import Alcohol from './Alcohol';
import Cafeina from './Cafeina';
import Tabaco from './Tabaco';
import OtrasDrogas from './OtrasDrogas';
import ResumenCambios from '../../../../../components/ResumenCambios';
import BotonesGuardar from '../../../../../components/BotonesGuardar';
import { RootState } from '../../../../../store';
import {
  antecedentesNoPatologicosGuardarToxicomania,
  antecedentesNoPatologicosUsuarioPacienteGuardarToxicomania,
  antecedentesNoPatologicosUsuarioGuardarToxicomania,
} from '../../../../../utils/sendInfo';
import {
  IAlcohol,
  ICafeina,
  ITabaco,
  IToxicomania,
  IToxicomaniaProps,
  toxicomaniaInitial,
} from './types';
import { setRequest } from '../../../../../actions/request/types';

function Toxicomania(props: IToxicomaniaProps) {
  const {
    setActiveStep,
    setActiveSubStep,
    enGestacion,
    setEnGestacion,
    toxicomania,
    setToxicomania,
    cambiosUsuario,
    toxicomaniaUsuario,
    setToxicomaniaUsuario,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    sexo,
    idPaciente,
    idUsuario,
    edad: { claveEdad, edad },
  } = useSelector((state: RootState) => state.BasicosPaciente);
  const {
    idMedico,
    idUsuario: idMiUsuario,
    esPaciente,
  } = useSelector((state: RootState) => state.Me);
  const { idConsulta } = useSelector((state: RootState) => state.Consulta);
  const {
    consultorio: { idConsultorio },
  } = useSelector((state: RootState) => state.Consultorios);
  // const edadPaciente = parseInt(calculateEdad(anio, mes, dia), 10);
  const [enGestacionTemp, setEnGestacionTemp] = useState<'' | 'si' | 'no'>(enGestacion);
  const [toxicomaniaTemp, setToxicomaniaTemp] = useState<IToxicomania>(toxicomaniaInitial);

  const hayCambios: boolean = idUsuario > 0 && cambiosUsuario.filter((c: string) => c.length > 0).length > 0;

  const getDatosExpediente = (datos: IToxicomania) => ({
    idMedico,
    idPaciente,
    idUsuario,
    idConsultorio,
    idConsulta,
    toxicomania: datos,
    enGestacion: enGestacionTemp,
  });
  const getDatosUsuario = (datos: IToxicomania) => ({
    idUsuario: esPaciente ? idMiUsuario : idUsuario,
    toxicomania: datos,
    enGestacion: enGestacionTemp,
  });

  // Guarda los datos del expediente del paciente, y si este tiene usuario también guarda los datos en el usuario
  const guardarDatosExpediente = () => {
    const datosToSend = getDatosExpediente(toxicomaniaTemp);
    const sendFunctions = [antecedentesNoPatologicosGuardarToxicomania(datosToSend)];
    if (!esPaciente && idUsuario > 0) {
      sendFunctions.push(antecedentesNoPatologicosUsuarioPacienteGuardarToxicomania(datosToSend));
    }
    dispatch(
      setRequest({
        type: 'send',
        multiple: true,
        requestFunctions: sendFunctions,
        successFunctions: [
          () => {
            setToxicomania({ ...toxicomaniaTemp, loaded: true });
            setEnGestacion(enGestacionTemp);
          },
          () => setToxicomaniaUsuario({ ...toxicomaniaTemp, loaded: true }),
        ],
      }),
    );
  };
  // Guarda los datos del usuario del paciente cuando el usuario es el paciente
  const guardarDatosUsuario = () => {
    dispatch(
      setRequest({
        type: 'send',
        requestFunction: antecedentesNoPatologicosUsuarioGuardarToxicomania(
          getDatosUsuario(toxicomaniaTemp),
        ),
        successFunction: () => {
          setToxicomania({ ...toxicomaniaTemp, loaded: true });
          if (sexo === 'H') {
            setActiveStep('exploracion-fisica');
          } else {
            setActiveSubStep('gineco-obstetricos');
          }
        },
      }),
    );
  };
  // Guarda los datos del usuario en el expediente cuando el médico acepta los cambios
  const guardarDatosDeUsuarioAExpediente = () => {
    dispatch(
      setRequest({
        type: 'send',
        requestFunction: antecedentesNoPatologicosGuardarToxicomania(
          getDatosExpediente(toxicomaniaUsuario),
        ),
        successFunction: () => {
          setToxicomania(toxicomaniaUsuario);
          setToxicomaniaTemp(toxicomaniaUsuario);
        },
      }),
    );
  };
  // Guarda los datos del expediente en el usuario cuando el médico rechaza los cambios
  const guardarDatosDeExpedienteAUsuario = () => {
    dispatch(
      setRequest({
        type: 'send',
        requestFunction: antecedentesNoPatologicosUsuarioPacienteGuardarToxicomania(
          getDatosExpediente(toxicomania),
        ),
        successFunction: () => setToxicomaniaUsuario(toxicomania),
      }),
    );
  };

  const getCafeina = (cafeina: ICafeina) => {
    const newCafeina = { ...cafeina };
    let cafeinaAlDia = 0;
    if (newCafeina.tazaDeCafe || newCafeina.lataDeBebidaEnergetica) {
      const tazaCantidad = parseInt(newCafeina.tazaDeCafe, 10) || 0;
      const lataCantidad = parseInt(newCafeina.lataDeBebidaEnergetica, 10) || 0;
      cafeinaAlDia = (tazaCantidad + lataCantidad) * 80;
    }
    newCafeina.cafeinaAlDia = cafeinaAlDia > 0 ? cafeinaAlDia.toString() : '';
    newCafeina.alarmaConsumoExcesivo = (enGestacion === 'si' && cafeinaAlDia >= 240)
      || (enGestacion !== 'si' && cafeinaAlDia >= 400);
    return newCafeina;
  };

  const getTabaco = (tabaco: ITabaco) => {
    const newTabaco = { ...tabaco };
    let ipa = 0;
    let alarmaTabaquismo: '' | 'leve' | 'moderado' | 'intenso' = '';
    if (newTabaco.cigarrillosDia && newTabaco.edadInicio) {
      let aniosFumador = 0;
      const edadInicio = parseInt(newTabaco.edadInicio, 10) || 0;
      const cigarrillosDia = parseInt(newTabaco.cigarrillosDia, 10) || 0;
      if (newTabaco.consumoTabaco === 'ex-fumador' && newTabaco.edadTermino) {
        const edadTermino = parseInt(newTabaco.edadTermino, 10) || 0;
        aniosFumador = edadTermino - edadInicio;
      } else if (newTabaco.consumoTabaco === 'activo') {
        aniosFumador = edad - edadInicio;
      }
      ipa = (cigarrillosDia * aniosFumador) / 20;
      if (ipa > 0) {
        if (ipa <= 4.9) {
          alarmaTabaquismo = 'leve';
        } else if (ipa <= 14.9) {
          alarmaTabaquismo = 'moderado';
        } else if (ipa > 14.9) {
          alarmaTabaquismo = 'intenso';
        }
      }
    }
    newTabaco.ipa = ipa > 0 ? ipa.toString() : '';
    newTabaco.alarmaTabaquismo = alarmaTabaquismo;
    return newTabaco;
  };

  const getAlcohol = (alcohol: IAlcohol) => {
    const newAlcohol = { ...alcohol };
    let alarmaConsumoIntensivo = false;
    let alarmaPosibleTrastorno = false;
    const totalTragos = newAlcohol.arrTipoAlcohol.cantidadAlcohol
      .map((cant) => (cant ? parseInt(cant, 10) : 0))
      .reduce((acc, current) => acc + current, 0);
    const sumatoriaAlcohol = newAlcohol.arrTipoAlcohol.tipoAlcohol
      .map(
        (elem, indx) => elem.grUnidad * (parseInt(newAlcohol.arrTipoAlcohol.cantidadAlcohol[indx], 10) || 0),
      )
      .reduce((acc, current) => acc + current, 0);
    const totalAproxAlcohol = Math.round(sumatoriaAlcohol * 10) / 10;
    if (alcohol.frecuencia !== '') {
      if (sexo === 'M') {
        if (totalAproxAlcohol >= 52 && alcohol.consumeEnMenosDeDosHoras === 'si') {
          alarmaConsumoIntensivo = true;
        }
      } else if (totalAproxAlcohol >= 65 && alcohol.consumeEnMenosDeDosHoras === 'si') {
        alarmaConsumoIntensivo = true;
      }
    }
    if (alcohol.frecuencia === 'diario') {
      if (sexo === 'M') {
        if (totalAproxAlcohol >= 39) {
          alarmaPosibleTrastorno = true;
        }
      } else if (totalAproxAlcohol >= 52) {
        alarmaPosibleTrastorno = true;
      }
    } else if (sexo === 'M' && alcohol.frecuencia !== '') {
      if (totalAproxAlcohol >= 52 && alcohol.consumeEnMenosDeDosHoras === 'si') {
        alarmaPosibleTrastorno = true;
      }
    } else if (
      alcohol.totalAproxAlcohol
      && totalAproxAlcohol >= 78
      && alcohol.consumeEnMenosDeDosHoras === 'si'
      && alcohol.frecuencia !== ''
    ) {
      alarmaPosibleTrastorno = true;
    }
    newAlcohol.totalTragos = totalTragos > 0 ? totalTragos.toString() : '';
    newAlcohol.totalAproxAlcohol = totalAproxAlcohol > 0 ? totalAproxAlcohol.toString() : '';
    newAlcohol.alarmaConsumoIntensivo = alarmaConsumoIntensivo;
    newAlcohol.alarmaPosibleTrastorno = alarmaPosibleTrastorno;
    return newAlcohol;
  };

  useEffect(() => {
    if (toxicomania.loaded && !toxicomaniaTemp.loaded) {
      const newToxicomania = { ...toxicomania };
      if (
        toxicomania.cafeina.consumoCafeina === 'activo'
        && !toxicomania.cafeina.cafeinaAlDia
        && (toxicomania.cafeina.tazaDeCafe || toxicomania.cafeina.lataDeBebidaEnergetica)
      ) {
        newToxicomania.cafeina = getCafeina(toxicomania.cafeina);
      }
      if (
        toxicomania.tabaco.consumoTabaco !== 'niega'
        && !toxicomania.tabaco.ipa
        && toxicomania.tabaco.cigarrillosDia
        && toxicomania.tabaco.edadInicio
      ) {
        newToxicomania.tabaco = getTabaco(toxicomania.tabaco);
      }
      if (
        toxicomania.alcohol.consumoAlcohol === 'activo'
        && !toxicomania.alcohol.totalAproxAlcohol
        && toxicomania.alcohol.arrTipoAlcohol.tipoAlcohol
      ) {
        newToxicomania.alcohol = getAlcohol(toxicomania.alcohol);
      }
      setToxicomaniaTemp(newToxicomania);
      setEnGestacionTemp(enGestacion);
    }
  }, [toxicomania]);

  return (
    <div className="py-4">
      <h2 className="text-blue-800 font-normal m-0">{t('toxicomania')}</h2>
      {hayCambios && <ResumenCambios cambios={cambiosUsuario} />}
      {sexo === 'M' && claveEdad === 5 && edad > 9 && edad < 61 && (
        <>
          <h3 className="mt-4 mb-2 text-gray-600 font-medium">{t('en_gestacion')}</h3>
          <RadioGroup
            row
            name="en gestacion"
            value={enGestacionTemp}
            onChange={(event) => setEnGestacionTemp(event.target.value as 'si' | 'no')}
          >
            <FormControlLabel
              value="si"
              control={<Radio color="primary" />}
              label={t('si')}
              labelPlacement="end"
              disabled={hayCambios}
            />
            <FormControlLabel
              value="no"
              control={<Radio color="primary" />}
              label={t('no')}
              labelPlacement="end"
              disabled={hayCambios}
            />
          </RadioGroup>
        </>
      )}
      <Cafeina
        hayCambios={hayCambios}
        enGestacion={enGestacionTemp}
        toxicomania={toxicomaniaTemp}
        setToxicomania={setToxicomaniaTemp}
        getCafeina={getCafeina}
      />
      <Tabaco
        hayCambios={hayCambios}
        toxicomania={toxicomaniaTemp}
        setToxicomania={setToxicomaniaTemp}
        getTabaco={getTabaco}
      />
      <Alcohol
        hayCambios={hayCambios}
        toxicomania={toxicomaniaTemp}
        setToxicomania={setToxicomaniaTemp}
        getAlcohol={getAlcohol}
      />
      <OtrasDrogas
        hayCambios={hayCambios}
        toxicomania={toxicomaniaTemp}
        setToxicomania={setToxicomaniaTemp}
      />
      <BotonesGuardar
        hayCambios={hayCambios}
        aceptarCallback={guardarDatosDeUsuarioAExpediente}
        cancelarCallback={guardarDatosDeExpedienteAUsuario}
        guardarCallback={esPaciente ? guardarDatosUsuario : guardarDatosExpediente}
        continuar={esPaciente && sexo === 'H'}
      />
    </div>
  );
}

export default Toxicomania;
