import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  TextField,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Checkbox,
  FormHelperText,
  InputAdornment,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import PhoneInput from 'react-phone-input-2';
/** Importacion de estilos */
import es from 'react-phone-input-2/lang/es.json';
import 'react-phone-input-2/lib/bootstrap.css';
import LockIcon from '@material-ui/icons/Lock';
import useStyles, { InputDisabled } from './styles';
/** Importacion de tipos de typescript */
import { IDatosPersonalesProps, IDatosPersonales, datosPersonalesInitial } from './types';
/** importancion de las fechas para la fecha de nacimiento */
import { constDia, constMes, constAnio } from '../../../../constants/fechas';
/** importacion de funciones */
import { validarFecha, validarFechaEsAnterior, edadCalculada } from '../../../../common/functions';
import {
  getCatalogoEscolaridad,
  getCatalogoEstadoCivil,
  getCatalogoTipoIdentificacion,
  getCatalogoGrupoEtnico,
  getCatalogoNacionalidad,
  getCatalogoReligion,
  getCatalogoTiempoOcupacion,
  getCatalogoTipoOcupacion,
  // getDatosUsuarioPacienteEmail,
} from '../../../../utils/getCatalogos';
/** importacion de validaciones */
import {
  docIdLongitudMax,
  validacionDocId,
  validacionEmail,
  validacionDocIdOnChange,
  validacionApellidos,
  validacionNombres,
} from '../../../../constants/validaciones';
import AlertDialog from '../../../../components/AlertDialogs/AlertDialog';
import AccesoPIN from '../../../../components/ModalDialogs/AccesoPIN';
import ResumenCambios from '../../../../components/ResumenCambios';
import BotonesGuardar from '../../../../components/BotonesGuardar';
import {
  guardarTipoEmpleado,
  updateDatosPersonales,
  updateDatosPersonalesPaciente,
  updateDatosPersonalesUsuario,
} from '../../../../utils/sendInfo';
import { setRequest } from '../../../../actions/request/types';
import {
  getPaciente,
  getUsuarioPaciente,
  setPacienteEdad,
  setPacienteNombre,
  setPacienteSexo,
  setPacienteTelefonos,
  setPacienteTipoEmpleado,
} from '../../../../actions/basicosPaciente/actions';
import { RootState } from '../../../../store';
import {
  ICatalogosEstandar,
  ICatStrEstandar,
  IEdad,
  IFechasFields,
  ITextFieldEstandar,
  ICountryPhoneInput,
  edadInitial,
} from '../../../../common/types';
import DialogoPassword from '../../../../components/ModalDialogs/DialogPassword';
import { getMeMedico } from '../../../../actions/me/actions';
import { setIdPacienteConsulta } from '../../../../actions/consulta/actions';

/** Formulario datos personales de informacion de paciente */
function DatosPersonales(props: IDatosPersonalesProps) {
  const {
    setActiveSubStep,
    datosPersonales,
    setDatosPersonales,
    cambiosUsuario,
    datosPersonalesUsuario,
    setDatosPersonalesUsuario,
    setEnGestacion,
    setInmunizaciones,
    tipoPaciente,
  } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    idPaciente, idUsuario, docId, sinDocId, tipoEmpleado,
  } = useSelector(
    (state: RootState) => state.BasicosPaciente,
  );
  const {
    idMedico,
    pais,
    idUsuario: idMiUsuario,
    esPaciente,
    email,
  } = useSelector((state: RootState) => state.Me);
  const { idConsulta, idPaciente: idPacienteConsulta } = useSelector(
    (state: RootState) => state.Consulta,
  );
  const {
    consultorio: { idConsultorio },
  } = useSelector((state: RootState) => state.Consultorios);
  const {
    organizacion: { idOrganizacion },
  } = useSelector((state: RootState) => state.Organizaciones);
  /** States del functional component */
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [modalPin, setModalPin] = useState<boolean>(false);
  const [pinStr, setPinStr] = useState<string>('');
  const [edad, setEdad] = useState<IEdad>(edadInitial);
  const [avisoPaciente, setAvisoPaciente] = useState<boolean>(false);
  /** States de comprobacion de fields */
  const [nombreField, setNombreField] = useState<ITextFieldEstandar>({
    helperText: '',
    error: false,
  });
  const [primerApellidoField, setPrimerApellidoField] = useState<ITextFieldEstandar>({
    helperText: '',
    error: false,
  });
  const [segundoApellidoField, setSegundoApellidoField] = useState<ITextFieldEstandar>({
    helperText: '',
    error: false,
  });
  const [fechaField, setFechaField] = useState<IFechasFields>({
    dia: {
      error: false,
      helperText: '',
    },
    mes: {
      error: false,
      helperText: '',
    },
    anio: {
      error: false,
      helperText: '',
    },
  });
  const [docIdField, setDocIdField] = useState<ITextFieldEstandar>({
    helperText: '',
    error: false,
  });
  const [emailField, setEmailField] = useState<ITextFieldEstandar>({
    helperText: '',
    error: false,
  });
  /** states de componentes que se deshabilitan */
  const [refCelularField, setRefCelularField] = useState<boolean>(false);
  const [refEmailField, setRefEmailField] = useState<boolean>(false);
  /** states de la snackbar */
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const [alertMensaje, setAlertMensaje] = useState<string>('');

  /** Catalogos */
  const [catNacionalidad, setCatNacionalidad] = useState<Array<ICatStrEstandar>>([]);
  const [catGrupoEtnico, setCatGrupoEtnico] = useState<Array<ICatalogosEstandar>>([]);
  const [catTipoIdentificacion, setCatTipoIdentificacion] = useState<Array<ICatalogosEstandar>>([]);
  const [catEscolaridad, setCatEscolaridad] = useState<Array<ICatalogosEstandar>>([]);
  const [catTipoOcupacion, setCatTipoOcupacion] = useState<Array<ICatalogosEstandar>>([]);
  const [catTiempoOcupacion, setCatTiempoOcupacion] = useState<Array<ICatalogosEstandar>>([]);
  const [catReligion, setCatReligion] = useState<Array<ICatalogosEstandar>>([]);
  const [catEstadoCivil, setCatEstadoCivil] = useState<Array<ICatalogosEstandar>>([]);
  /** states para mostrar o no diferentes campos */
  const [ocupacionShow, setOcupacionShow] = useState(false);
  const [referenciaShow, setReferenciaShow] = useState(false);
  const [otraReligionShow, setOtraReligionShow] = useState(false);
  const [tiempoOcupacionShow, setTiempoOcupacionShow] = useState<boolean>(false);
  const [datosPersonalesTemp, setDatosPersonalesTemp] = useState<IDatosPersonales>(datosPersonalesInitial);

  const hayCambios: boolean = idUsuario > 0 && cambiosUsuario.filter((c: string) => c.length > 0).length > 0;

  /** handlers de desabilitacion o no de otros campos */
  const handleOcupacionShow = () => {
    setOcupacionShow(true);
  };

  const handleOcupacionHide = () => {
    setDatosPersonalesTemp({
      ...datosPersonalesTemp,
      tiempoOcupacion: { id: 0, label: '' },
      tipoOcupacion: { id: 0, label: '' },
    });
    setOcupacionShow(false);
    setTiempoOcupacionShow(false);
  };

  const handleReferenciaShow = () => {
    setReferenciaShow(true);
  };

  const handleReferenciaHide = () => {
    setReferenciaShow(false);
    setDatosPersonalesTemp({
      ...datosPersonalesTemp,
      otroComoEntero: '',
    });
  };

  const handleAlertClose = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === 'clickaway' || reason === 'backdropClick' || reason === 'escapeKeyDown') {
      return;
    }
    setAlertOpen(false);
  };

  /** Handlers del componente */
  const handleNombreChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const val = event.target.value as string;
    setDatosPersonalesTemp({
      ...datosPersonalesTemp,
      nombre: val,
    });
    if (validacionNombres(val) || !val.length) {
      setNombreField({ helperText: '', error: false });
    } else {
      setNombreField({ helperText: t('nombre_incorrecto'), error: true });
    }
  };

  const handleChangePrimerApellido = (event: React.ChangeEvent<{ value: unknown }>) => {
    const val = event.target.value as string;
    setDatosPersonalesTemp({
      ...datosPersonalesTemp,
      primerApellido: val,
    });
    if (!val.length || validacionApellidos(val)) {
      setPrimerApellidoField({ helperText: '', error: false });
    } else {
      setPrimerApellidoField({ helperText: t('primer_apellido_incorrecto'), error: true });
    }
  };

  const handleChangeSegundoApellido = (event: React.ChangeEvent<{ value: unknown }>) => {
    const val = event.target.value as string;
    setDatosPersonalesTemp({
      ...datosPersonalesTemp,
      segundoApellido: val,
    });
    if (!val.length || validacionApellidos(val)) {
      setSegundoApellidoField({ helperText: '', error: false });
    } else {
      setSegundoApellidoField({ helperText: t('segundo_apellido_incorrecto'), error: true });
    }
  };

  const handleChangeSexo = (event: React.ChangeEvent<{ value: unknown }>) => {
    setDatosPersonalesTemp({
      ...datosPersonalesTemp,
      sexo: event.target.value as string,
    });
  };

  const handleChangeDate = (event: React.ChangeEvent<{ value: unknown }>, tipo: string) => {
    const dataUpd = { ...datosPersonalesTemp };
    const valAdd = (event.target.value as string).toString();
    if (tipo === 'dia') {
      dataUpd.fechaNac.dia = valAdd;
    } else if (tipo === 'mes') {
      dataUpd.fechaNac.mes = valAdd;
    } else if (tipo === 'anio') {
      dataUpd.fechaNac.anio = valAdd;
    }
    setDatosPersonalesTemp(dataUpd);
  };

  const handleChangeNacionalidad = (_event: any, newValue: ICatStrEstandar | null) => {
    if (newValue) {
      setDatosPersonalesTemp({
        ...datosPersonalesTemp,
        nacionalidad: newValue,
      });
    } else {
      setDatosPersonalesTemp({
        ...datosPersonalesTemp,
        nacionalidad: { id: '', label: '' },
      });
    }
  };

  const handleChangeGrupoEtnico = (_event: any, newValue: ICatalogosEstandar | null) => {
    if (newValue) {
      setDatosPersonalesTemp({
        ...datosPersonalesTemp,
        grupoEtnico: newValue,
      });
    } else {
      setDatosPersonalesTemp({
        ...datosPersonalesTemp,
        grupoEtnico: { id: 0, label: '' },
      });
    }
  };

  const handleChangeDocId = (event: React.ChangeEvent<{ value: unknown }>) => {
    const val = event.target.value as string;
    // setDatosPersonalesTemp({
    //   ...datosPersonalesTemp,
    //   docId: val,
    // });
    if (validacionDocIdOnChange(val, pais)) {
      setDocIdField({ helperText: '', error: false });
    } else {
      setDocIdField({ helperText: t(`doc_id_incorrecto_${pais}`), error: true });
    }
  };

  const handleBlurDocId = (event: React.ChangeEvent<{ value: unknown }>) => {
    const val = event.target.value as string;
    if (val.length && validacionDocId(val, pais)) {
      setDocIdField({ helperText: '', error: false });
    } else {
      setDocIdField({ helperText: t(`doc_id_incorrecto_${pais}`), error: true });
    }
  };

  const handleChangeTipoIdentificacion = (event: any, newValue: ICatalogosEstandar | null) => {
    setDatosPersonalesTemp({
      ...datosPersonalesTemp,
      tipoIdentificacion: newValue !== null ? newValue : { id: 0, label: '' },
    });
  };

  const handleChangeNumeroDocumento = (event: React.ChangeEvent<{ value: unknown }>) => {
    setDatosPersonalesTemp({
      ...datosPersonalesTemp,
      numeroDocumento: event.target.value as string,
    });
  };

  const handleChangeEscolaridad = (event: any, newValue: ICatalogosEstandar | null) => {
    setDatosPersonalesTemp({
      ...datosPersonalesTemp,
      escolaridad: newValue !== null ? newValue : { id: 0, label: '' },
    });
  };

  const handleChangeOcupacion = (event: React.ChangeEvent<{ value: unknown }>) => {
    setDatosPersonalesTemp({
      ...datosPersonalesTemp,
      ocupacion: event.target.value as string,
    });
  };

  const handleChangeTipoOcupacion = (event: any, newValue: ICatalogosEstandar | null) => {
    if (newValue) {
      setDatosPersonalesTemp({
        ...datosPersonalesTemp,
        tipoOcupacion: newValue,
      });
      setTiempoOcupacionShow(true);
    } else {
      setDatosPersonalesTemp({
        ...datosPersonalesTemp,
        tipoOcupacion: { id: 0, label: '' },
        tiempoOcupacion: { id: 0, label: '' },
      });
      setTiempoOcupacionShow(false);
    }
  };

  const handleChangeTiempoOcupacion = (event: any, newValue: ICatalogosEstandar | null) => {
    setDatosPersonalesTemp({
      ...datosPersonalesTemp,
      tiempoOcupacion: newValue !== null ? newValue : { id: 0, label: '' },
    });
  };

  const handleChangeReligion = (event: any, newValue: ICatalogosEstandar | null) => {
    const otra = newValue !== null && newValue.label === 'OTRA';
    setDatosPersonalesTemp({
      ...datosPersonalesTemp,
      religion: newValue !== null ? newValue : { id: 0, label: '' },
      otraReligion: otra ? '' : datosPersonalesTemp.otraReligion,
    });
    setOtraReligionShow(otra);
  };

  const handleChangeOtraReligion = (event: React.ChangeEvent<{ value: string }>) => {
    setDatosPersonalesTemp({
      ...datosPersonalesTemp,
      otraReligion: event.target.value,
    });
  };

  const handleChangeEstadoCivil = (event: any, newValue: ICatalogosEstandar | null) => {
    setDatosPersonalesTemp({
      ...datosPersonalesTemp,
      estadoCivil: newValue !== null ? newValue : { id: 0, label: '' },
    });
  };

  const handleChangeEmail = (event: React.ChangeEvent<{ value: unknown }>) => {
    const val = event.target.value as string;
    setDatosPersonalesTemp({
      ...datosPersonalesTemp,
      email: val ? val.trim().toLowerCase() : '',
    });
    setEmailField({ helperText: '', error: false });
  };

  const handleBlurEmail = (event: React.ChangeEvent<{ value: unknown }>) => {
    const val = event.target.value as string;
    if (!val.length || validacionEmail(val)) {
      setEmailField({ helperText: '', error: false });
    } else {
      setEmailField({ helperText: t('email_incorrecto'), error: true });
    }
  };

  const handleChangeComoEntero = (event: React.ChangeEvent<{ value: unknown }>) => {
    setDatosPersonalesTemp({
      ...datosPersonalesTemp,
      comoEntero: event.target.value as string,
    });
  };

  const handleChangeOtroComoEntero = (event: React.ChangeEvent<{ value: unknown }>) => {
    setDatosPersonalesTemp({
      ...datosPersonalesTemp,
      otroComoEntero: event.target.value as string,
    });
  };

  const handleToggleDisableCelular = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRefCelularField(event.target.checked);
    setDatosPersonalesTemp({
      ...datosPersonalesTemp,
      noTieneCelular: event.target.checked,
      celular: {
        numero: '',
        codigo: '',
      },
    });
  };

  const handleToggleDisableEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRefEmailField(event.target.checked);
    setDatosPersonalesTemp({
      ...datosPersonalesTemp,
      noTieneEmail: event.target.checked,
      email: '',
    });
    setEmailField({ helperText: '', error: false });
  };

  const handleChangeAviso = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAvisoPaciente(event.target.checked);
  };

  const getData = (datos: IDatosPersonales, idUser?: number) => ({
    idMedico,
    idPaciente,
    idUsuario: idUser,
    idConsulta,
    idConsultorio,
    idOrganizacion,
    nombre: datos.nombre.trim().split(/\s+/).join(' '),
    primerApellido: datos.primerApellido.trim().split(/\s+/).join(' '),
    segundoApellido: datos.segundoApellido.trim().split(/\s+/).join(' '),
    sexo: datos.sexo,
    fechaDia: datos.fechaNac.dia,
    fechaMes: datos.fechaNac.mes,
    fechaAnio: datos.fechaNac.anio,
    nacionalidad: datos.nacionalidad.id,
    grupoEtnico: datos.grupoEtnico ? datos.grupoEtnico.id : 0,
    estadoCivil: datos.estadoCivil ? datos.estadoCivil.id : 0,
    escolaridad: datos.escolaridad ? datos.escolaridad.id : 0,
    religion: datos.religion ? datos.religion.id : 0,
    otraReligion: datos.otraReligion,
    docId: datos.docId,
    noPoseeDocId: datos.noPoseeDocId,
    tipoIdentificacion: datos.tipoIdentificacion ? datos.tipoIdentificacion.id : 0,
    numeroDocumento: datos.numeroDocumento,
    ocupacion: datos.ocupacion,
    tipoOcupacion: datos.tipoOcupacion ? datos.tipoOcupacion.id : 0,
    tiempoOcupacion: datos.tiempoOcupacion ? datos.tiempoOcupacion.id : 0,
    ladaCel: datos.celular.codigo,
    celular: datos.celular.numero,
    noTieneCelular: datos.noTieneCelular,
    ladaTel: datos.fijo.codigo,
    fijo: datos.fijo.numero,
    email: datos.email,
    noTieneEmail: datos.noTieneEmail,
    comoEntero: datos.comoEntero,
    otroComoEntero: datos.otroComoEntero,
  });

  // Guarda los datos del expediente del paciente, y si este tiene usuario también guarda los datos en el usuario
  const guardarDatosExpediente = () => {
    const dataPersonal = getData(datosPersonalesTemp, idUsuario);
    const requestFunctions = [updateDatosPersonales(dataPersonal)];
    const successFunctions = [
      (result: any) => {
        if (idPaciente === -1) {
          setInmunizaciones(result.inmunizaciones);
        }
        if (datosPersonalesTemp.sexo === 'H') {
          setEnGestacion('');
        }
        dispatch(getPaciente(parseInt(result.datos, 10), idMedico, idOrganizacion));
        if (!idPacienteConsulta) {
          dispatch(setIdPacienteConsulta(parseInt(result.datos, 10)));
        }
        setDatosPersonales({ ...datosPersonalesTemp, loaded: true });
        if (result.pin) {
          setModalPin(true);
          setPinStr(result.pin);
        }
      },
    ];
    if (!esPaciente && idUsuario && idUsuario > 0) {
      requestFunctions.push(updateDatosPersonalesPaciente(dataPersonal));
      successFunctions.push(() => {
        setDatosPersonalesUsuario({
          ...datosPersonalesTemp,
          loaded: true,
        });
      });
    }
    dispatch(
      setRequest({
        type: 'send',
        multiple: true,
        requestFunctions,
        successFunctions,
        showMsgSuccess: true,
      }),
    );
  };
  // Guarda los datos del usuario del paciente cuando el usuario es el paciente
  const guardarDatosUsuario = () => {
    dispatch(
      setRequest({
        type: 'send',
        requestFunction: updateDatosPersonalesUsuario(getData(datosPersonalesTemp, idMiUsuario)),
        successFunction: () => {
          setDatosPersonales({ ...datosPersonalesTemp, loaded: true });
          if (datosPersonalesTemp.sexo === 'H') {
            setEnGestacion('');
          }
          dispatch(getMeMedico(email));
          dispatch(getUsuarioPaciente(idMedico));
          dispatch(
            setPacienteNombre(
              `${datosPersonalesTemp.nombre} ${datosPersonalesTemp.primerApellido} ${datosPersonalesTemp.segundoApellido}`,
            ),
          );
          dispatch(setPacienteSexo(datosPersonalesTemp.sexo));
          dispatch(setPacienteEdad(edad));
          dispatch(
            setPacienteTelefonos([
              datosPersonales.celular.numero
                ? `${datosPersonales.celular.codigo}${datosPersonales.celular.numero}`
                : '',
              datosPersonales.fijo.numero
                ? `${datosPersonales.fijo.codigo}${datosPersonales.fijo.numero}`
                : '',
            ]),
          );
          setActiveSubStep('datos-ubicacion');
        },
      }),
    );
  };
  // Guarda los datos del usuario en el expediente cuando el médico acepta los cambios
  const guardarDatosDeUsuarioAExpediente = () => {
    dispatch(
      setRequest({
        type: 'send',
        requestFunction: updateDatosPersonales(getData(datosPersonalesUsuario)),
        successFunction: () => {
          setDatosPersonales(datosPersonalesUsuario);
          setDatosPersonalesTemp(datosPersonalesUsuario);
          if (datosPersonalesUsuario.sexo === 'H') {
            setEnGestacion('');
          }
        },
      }),
    );
  };
  // Guarda los datos del expediente en el usuario cuando el médico rechaza los cambios
  const guardarDatosDeExpedienteAUsuario = () => {
    dispatch(
      setRequest({
        type: 'send',
        requestFunction: updateDatosPersonalesPaciente(getData(datosPersonales, idUsuario)),
        successFunction: () => setDatosPersonalesUsuario(datosPersonales),
      }),
    );
  };

  const saveTipoEmpleado = () => {
    if (tipoPaciente) {
      dispatch(
        setRequest({
          type: 'send',
          requestFunction: guardarTipoEmpleado({
            idMedico,
            idPaciente,
            idConsultorio,
            idOrganizacion,
            tipoEmpleado: tipoPaciente,
          }),
          successFunction: () => {
            dispatch(setPacienteTipoEmpleado(tipoPaciente));
          },
        }),
      );
    }
  };

  const verificarErrorValidacionGeneral = datosPersonalesTemp.nombre.trim() === ''
    || datosPersonalesTemp.primerApellido.trim() === ''
    || datosPersonalesTemp.sexo === ''
    || datosPersonalesTemp.fechaNac.dia.length === 0
    || datosPersonalesTemp.fechaNac.mes.length === 0
    || datosPersonalesTemp.fechaNac.anio.length === 0
    || datosPersonalesTemp.nacionalidad.id.length === 0
    || (datosPersonalesTemp.celular.numero.trim() === ''
      ? !datosPersonalesTemp.noTieneCelular
      : false)
    || (datosPersonalesTemp.email.trim() === '' ? !datosPersonalesTemp.noTieneEmail : false)
    || emailField.error
    || (!avisoPaciente && !(idPaciente > 0));
  const verificarErrorValidacionObligatorios = !(
    datosPersonalesTemp.nombre.length > 0
    && datosPersonalesTemp.primerApellido.length > 0
    && datosPersonalesTemp.sexo.length > 0
    && datosPersonalesTemp.fechaNac.dia.length > 0
    && datosPersonalesTemp.fechaNac.mes.length > 0
    && datosPersonalesTemp.fechaNac.anio.length > 0
    && datosPersonalesTemp.nacionalidad.id.length > 0
    && (datosPersonalesTemp.docId.length > 0 || datosPersonalesTemp.noPoseeDocId)
    && (datosPersonalesTemp.celular.numero.length > 0 || datosPersonalesTemp.noTieneCelular)
    && (datosPersonalesTemp.email.length > 0 || datosPersonalesTemp.noTieneEmail)
    && !verificarErrorValidacionGeneral
    && (avisoPaciente || idPaciente > 0)
  );
  const handleSubmitForm = () => {
    if (esPaciente) {
      guardarDatosUsuario();
    } else {
      if (verificarErrorValidacionObligatorios) {
        setAlertOpen(true);
        setAlertMensaje(t('texto_llenado_norma_oficial'));
        return;
      }
      guardarDatosExpediente();
    }
  };

  useEffect(() => {
    if (datosPersonales.loaded && !datosPersonalesTemp.loaded) {
      setDatosPersonalesTemp(datosPersonales);
      if (
        datosPersonales.ocupacion === 'EMPLEADO'
        || datosPersonales.ocupacion === 'PENSIONADO'
        || datosPersonales.ocupacion === 'JUBILADO'
      ) {
        setOcupacionShow(true);
      }
      if (datosPersonales.tipoOcupacion.id) {
        setTiempoOcupacionShow(true);
      }
    }
  }, [datosPersonales]);
  /** Efecto para llenar la edad */
  useEffect(() => {
    if (
      datosPersonalesTemp.fechaNac.dia
      && datosPersonalesTemp.fechaNac.mes
      && datosPersonalesTemp.fechaNac.anio
    ) {
      if (
        !validarFecha(
          datosPersonalesTemp.fechaNac.anio,
          datosPersonalesTemp.fechaNac.mes,
          datosPersonalesTemp.fechaNac.dia,
        )
        || !validarFechaEsAnterior(
          datosPersonalesTemp.fechaNac.anio,
          datosPersonalesTemp.fechaNac.mes,
          datosPersonalesTemp.fechaNac.dia,
        )
      ) {
        setFechaField({
          dia: {
            error: true,
            helperText: ' ',
          },
          mes: {
            error: true,
            helperText: t('fecha_invalida'),
          },
          anio: {
            error: true,
            helperText: ' ',
          },
        });
        setEdad(edadInitial);
      } else {
        const edadObj = edadCalculada(
          datosPersonalesTemp.fechaNac.anio,
          datosPersonalesTemp.fechaNac.mes,
          datosPersonalesTemp.fechaNac.dia,
        );
        setEdad(edadObj);
        setFechaField({
          dia: {
            error: false,
            helperText: '',
          },
          mes: {
            error: false,
            helperText: '',
          },
          anio: {
            error: false,
            helperText: '',
          },
        });
      }
    }
  }, [
    datosPersonalesTemp.fechaNac.anio,
    datosPersonalesTemp.fechaNac.mes,
    datosPersonalesTemp.fechaNac.dia,
  ]);
  useEffect(() => {
    if (idPaciente === -1) {
      setDatosPersonalesTemp({
        ...datosPersonalesTemp,
        noPoseeDocId: !!sinDocId,
        docId,
      });
    } else if (idPaciente > 0 && !tipoEmpleado) {
      saveTipoEmpleado();
    }
  }, [idPaciente]);
  /** Hooks */
  /** Efecto al cargar la pagina */
  // Hacer una petición múltiple
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    getCatalogoNacionalidad().then((result: ICatStrEstandar[]) => {
      setCatNacionalidad(result);
    });
    getCatalogoGrupoEtnico().then((result: ICatalogosEstandar[]) => {
      setCatGrupoEtnico(result);
    });
    getCatalogoTipoIdentificacion().then((result: ICatalogosEstandar[]) => {
      setCatTipoIdentificacion(result);
    });
    getCatalogoEscolaridad().then((result: ICatalogosEstandar[]) => {
      setCatEscolaridad(result);
    });
    getCatalogoTipoOcupacion().then((result: ICatalogosEstandar[]) => {
      setCatTipoOcupacion(result);
    });
    getCatalogoTiempoOcupacion().then((result: ICatalogosEstandar[]) => {
      setCatTiempoOcupacion(result);
    });
    getCatalogoReligion().then((result: ICatalogosEstandar[]) => {
      setCatReligion(result);
    });
    getCatalogoEstadoCivil().then((result: ICatalogosEstandar[]) => {
      setCatEstadoCivil(result);
    });
  }, []);

  return (
    <div id="perfil-paciente" aria-labelledby="perfil-paciente">
      <AlertDialog
        open={alertOpen}
        titulo={t('_atencion_')}
        descripcion={alertMensaje}
        nombreCancelar={t('regresar')}
        nombreAceptar={t('guardar')}
        callBackAceptar={guardarDatosExpediente}
        callBackClose={handleAlertClose}
      />
      <AccesoPIN
        open={modalPin}
        callBackAceptar={() => setModalPin(false)}
        callBackClose={() => setModalPin(false)}
        usuario={docId}
        pin={pinStr}
      />
      <DialogoPassword
        open={showDialog}
        callBackClose={() => {
          setShowDialog(!showDialog);
        }}
      />
      <div className="p-4 bg-white container mx-auto shadow rounded-b-md border-solid border border-gray-100">
        {hayCambios && <ResumenCambios cambios={cambiosUsuario} />}
        <form>
          <div className="grid grid-cols-4 gap-x-4 gap-y-2 mb-6 mt-4 items-center">
            <h3 className="text-gray-600 font-medium m-0">{`${t('nombre(s)')}*`}</h3>
            <h3 className="text-gray-600 font-medium m-0">{`${t('primer_apellido')}*`}</h3>
            <h3 className="text-gray-600 font-medium m-0">{t('segundo_apellido')}</h3>
            <h3 className="text-gray-600 font-medium m-0">{`${t('sexo')}*`}</h3>
            <TextField
              disabled={hayCambios}
              variant="outlined"
              onChange={handleNombreChange}
              value={datosPersonalesTemp.nombre}
              error={nombreField.error}
              helperText={nombreField.helperText}
              FormHelperTextProps={{ className: classes.absoluteBottom }}
              fullWidth
              inputProps={{ maxLength: 50, autoComplete: 'off', 'aria-autocomplete': 'none' }}
            />
            <TextField
              disabled={hayCambios}
              variant="outlined"
              onChange={handleChangePrimerApellido}
              value={datosPersonalesTemp.primerApellido}
              error={primerApellidoField.error}
              helperText={primerApellidoField.helperText}
              FormHelperTextProps={{ className: classes.absoluteBottom }}
              fullWidth
              inputProps={{ maxLength: 50, autoComplete: 'off', 'aria-autocomplete': 'none' }}
            />
            <TextField
              disabled={hayCambios}
              variant="outlined"
              onChange={handleChangeSegundoApellido}
              value={datosPersonalesTemp.segundoApellido}
              error={segundoApellidoField.error}
              helperText={segundoApellidoField.helperText}
              FormHelperTextProps={{ className: classes.absoluteBottom }}
              fullWidth
              inputProps={{ maxLength: 50, autoComplete: 'off', 'aria-autocomplete': 'none' }}
            />
            <FormControl fullWidth>
              <RadioGroup
                row
                aria-label="sexo"
                name="sexo"
                value={datosPersonalesTemp.sexo}
                onChange={handleChangeSexo}
              >
                <FormControlLabel
                  disabled={hayCambios}
                  value="H"
                  control={<Radio color="primary" />}
                  label={t('hombre')}
                />
                <FormControlLabel
                  disabled={hayCambios}
                  value="M"
                  control={<Radio color="primary" />}
                  label={t('mujer')}
                />
              </RadioGroup>
            </FormControl>
          </div>
          <div className="grid grid-cols-4 gap-x-4 gap-y-2 mb-6">
            <h3 className="text-gray-600 font-medium m-0 col-span-2">
              {`${t('fecha_nacimiento')}*`}
            </h3>
            <h3 className="text-gray-600 font-medium m-0">{`${t('nacionalidad')}*`}</h3>
            <h3 className="text-gray-600 font-medium m-0">{t('grupo_etnolinguistico')}</h3>
            <div className="col-span-2 grid grid-cols-12 gap-2">
              <div className="col-span-2">
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="label-dia">{t('dia')}</InputLabel>
                  <Select
                    disabled={hayCambios}
                    labelId="label-dia"
                    id="select-dia"
                    value={datosPersonalesTemp.fechaNac.dia}
                    onChange={(e) => handleChangeDate(e, 'dia')}
                    label={t('dia')}
                    error={fechaField.dia.error}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 375,
                        },
                      },
                    }}
                    displayEmpty
                  >
                    <MenuItem value={0} disabled>
                      <span className="text-gray-400 font-light">{t('selecciona')}</span>
                    </MenuItem>
                    {constDia.map((diaSingle) => (
                      <MenuItem value={diaSingle} key={diaSingle}>
                        {diaSingle}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className="col-span-4">
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="label-mes">{t('mes')}</InputLabel>
                  <Select
                    disabled={hayCambios}
                    labelId="label-mes"
                    id="select-mes"
                    value={datosPersonalesTemp.fechaNac.mes}
                    onChange={(e) => handleChangeDate(e, 'mes')}
                    label={t('mes')}
                    error={fechaField.mes.error}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 375,
                        },
                      },
                    }}
                  >
                    <MenuItem value={0} disabled>
                      <span className="text-gray-400 font-light">{t('selecciona')}</span>
                    </MenuItem>
                    {constMes.map((mesSingle, indx) => (
                      <MenuItem value={indx + 1} key={mesSingle}>
                        {mesSingle}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText className={classes.absoluteBottom}>
                    {fechaField.mes.helperText}
                  </FormHelperText>
                </FormControl>
              </div>
              <div className="col-span-3">
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="label-año">{t('anio')}</InputLabel>
                  <Select
                    disabled={hayCambios}
                    labelId="label-año"
                    id="select-año"
                    value={datosPersonalesTemp.fechaNac.anio}
                    onChange={(e) => handleChangeDate(e, 'anio')}
                    label={t('anio')}
                    error={fechaField.anio.error}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 375,
                        },
                      },
                    }}
                  >
                    <MenuItem value={0} disabled>
                      <span className="text-gray-400 font-light">{t('selecciona')}</span>
                    </MenuItem>
                    {constAnio().map((anioSingle) => (
                      <MenuItem key={anioSingle} value={anioSingle}>
                        {anioSingle}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className="col-span-3 ml-4">
                <InputDisabled
                  disabled
                  label={t('edad')}
                  value={edad.edad ? edad.edad.toString() : ''}
                  type="number"
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {edad.periodo && t(edad.periodo)}
                      </InputAdornment>
                    ),
                  }}
                  variant="standard"
                  fullWidth
                />
              </div>
            </div>
            <Autocomplete
              disabled={hayCambios}
              options={catNacionalidad}
              getOptionLabel={(option) => option.label}
              noOptionsText={t('sin_resultados')}
              value={datosPersonalesTemp.nacionalidad}
              onChange={(e, nV) => handleChangeNacionalidad(e, nV)}
              fullWidth
              renderInput={(params) => (
                <TextField {...params} placeholder={t('selecciona')} variant="outlined" />
              )}
            />
            <Autocomplete
              disabled={hayCambios}
              options={catGrupoEtnico}
              getOptionLabel={(option) => option.label}
              fullWidth
              noOptionsText={t('sin_resultados')}
              value={datosPersonalesTemp.grupoEtnico}
              onChange={(e, nV) => handleChangeGrupoEtnico(e, nV)}
              renderInput={(params) => (
                <TextField {...params} placeholder={t('selecciona')} variant="outlined" />
              )}
            />
          </div>
          <div className="grid grid-cols-4 gap-6 mb-6">
            <div>
              <h3 className="text-gray-600 font-medium mt-0 mb-2">{t('estado_civil')}</h3>
              <Autocomplete
                disabled={hayCambios}
                options={catEstadoCivil}
                getOptionLabel={(option) => option.label}
                fullWidth
                noOptionsText={t('sin_resultados')}
                value={datosPersonalesTemp.estadoCivil}
                onChange={(e, nV) => handleChangeEstadoCivil(e, nV)}
                renderInput={(params) => (
                  <TextField {...params} placeholder={t('selecciona')} variant="outlined" />
                )}
              />
            </div>
            <div>
              <h3 className="text-gray-600 font-medium mt-0 mb-2">{t('escolaridad')}</h3>
              <Autocomplete
                disabled={hayCambios}
                options={catEscolaridad}
                getOptionLabel={(option) => option.label}
                fullWidth
                noOptionsText={t('sin_resultados')}
                value={datosPersonalesTemp.escolaridad}
                onChange={(e, nV) => handleChangeEscolaridad(e, nV)}
                renderInput={(params) => (
                  <TextField {...params} placeholder={t('selecciona')} variant="outlined" />
                )}
              />
            </div>
            <div>
              <h3 className="text-gray-600 font-medium mt-0 mb-2">{t('religion')}</h3>
              <Autocomplete
                disabled={hayCambios}
                options={catReligion}
                getOptionLabel={(option) => option.label}
                fullWidth
                noOptionsText={t('sin_resultados')}
                value={datosPersonalesTemp.religion}
                onChange={(e, nV) => handleChangeReligion(e, nV)}
                renderInput={(params) => (
                  <TextField {...params} placeholder={t('selecciona')} variant="outlined" />
                )}
              />
            </div>
            {otraReligionShow && (
              <div>
                <h3 className="text-gray-600 font-medium mt-0 mb-2">{t('otra_especifica')}</h3>
                <TextField
                  disabled={hayCambios}
                  variant="outlined"
                  fullWidth
                  onChange={handleChangeOtraReligion}
                  value={datosPersonalesTemp.otraReligion}
                  inputProps={{ autoComplete: 'off', 'aria-autocomplete': 'none' }}
                />
              </div>
            )}
          </div>
          <div className="grid grid-cols-4 mb-6 gap-x-4 gap-y-2">
            <div className="col-span-2 grid grid-cols-3 gap-x-4 gap-y-2 items-center">
              <h3 className="text-gray-600 font-medium m-0 col-span-2">
                {docId.startsWith('PACI-') ? `${t('usuario')}*` : `${t(`doc_id_${pais}`)}*`}
              </h3>
              <div className="col-span-2">
                <TextField
                  variant="outlined"
                  value={datosPersonalesTemp.docId}
                  onChange={handleChangeDocId}
                  fullWidth
                  error={docIdField.error}
                  helperText={docIdField.helperText}
                  disabled
                  onBlur={handleBlurDocId}
                  inputProps={{
                    maxLength: docIdLongitudMax(pais),
                    autoComplete: 'off',
                    'aria-autocomplete': 'none',
                    className: 'uppercase',
                  }}
                  FormHelperTextProps={{ className: classes.absoluteBottom }}
                />
              </div>
              <FormControl fullWidth>
                <FormControlLabel
                  control={<Checkbox checked={datosPersonalesTemp.noPoseeDocId} color="primary" />}
                  label={t(`no_posee_doc_id_${pais}`)}
                  disabled
                />
              </FormControl>
            </div>
            {sinDocId && (
              <>
                <div>
                  <h3 className="text-gray-600 font-medium mt-0 mb-2">
                    {t('tipo_identificacion')}
                  </h3>
                  <Autocomplete
                    disabled={hayCambios}
                    options={catTipoIdentificacion}
                    getOptionLabel={(option) => option.label}
                    fullWidth
                    noOptionsText={t('sin_resultados')}
                    value={datosPersonalesTemp.tipoIdentificacion}
                    onChange={(e, nV) => handleChangeTipoIdentificacion(e, nV)}
                    renderInput={(params) => (
                      <TextField {...params} placeholder={t('selecciona')} variant="outlined" />
                    )}
                  />
                </div>
                <div>
                  <h3 className="text-gray-600 font-medium mt-0 mb-2">{t('numero_documento')}</h3>
                  <TextField
                    disabled={hayCambios}
                    variant="outlined"
                    value={datosPersonalesTemp.numeroDocumento}
                    onChange={handleChangeNumeroDocumento}
                    fullWidth
                    inputProps={{ maxLength: 20, autoComplete: 'off', 'aria-autocomplete': 'none' }}
                  />
                </div>
              </>
            )}
          </div>
          <div className="grid grid-cols-2 gap-4 mb-6">
            <div>
              <h3 className="text-gray-600 font-medium mt-0 mb-2">{t('ocupacion')}</h3>
              <RadioGroup
                row
                aria-label="ocupacion"
                name="ocupacion"
                value={datosPersonalesTemp.ocupacion}
                onChange={handleChangeOcupacion}
              >
                <FormControlLabel
                  disabled={hayCambios}
                  value="EMPLEADO"
                  control={<Radio color="primary" />}
                  label={t('empleado')}
                  onClick={handleOcupacionShow}
                />
                <FormControlLabel
                  disabled={hayCambios}
                  value="PENSIONADO"
                  control={<Radio color="primary" />}
                  label={t('pensionado')}
                  onClick={handleOcupacionShow}
                />
                <FormControlLabel
                  disabled={hayCambios}
                  value="DESEMPLEADO"
                  control={<Radio color="primary" />}
                  label={t('desempleado')}
                  onClick={handleOcupacionHide}
                />
                <FormControlLabel
                  disabled={hayCambios}
                  value="JUBILADO"
                  control={<Radio color="primary" />}
                  label={t('jubilado')}
                  onClick={handleOcupacionShow}
                />
              </RadioGroup>
            </div>
            {ocupacionShow && (
              <>
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <h3 className="text-gray-600 font-medium mt-0 mb-2">{t('tipo_ocupacion')}</h3>
                    <Autocomplete
                      disabled={hayCambios}
                      options={catTipoOcupacion}
                      getOptionLabel={(option) => option.label}
                      fullWidth
                      noOptionsText={t('sin_resultados')}
                      value={datosPersonalesTemp.tipoOcupacion}
                      onChange={(e, nV) => handleChangeTipoOcupacion(e, nV)}
                      renderInput={(params) => (
                        <TextField {...params} placeholder={t('selecciona')} variant="outlined" />
                      )}
                    />
                  </div>
                  {tiempoOcupacionShow && (
                    <div>
                      <h3 className="text-gray-600 font-medium mt-0 mb-2">
                        {t('tiempo_ocupacion')}
                      </h3>
                      <Autocomplete
                        disabled={hayCambios}
                        options={catTiempoOcupacion}
                        getOptionLabel={(option) => option.label}
                        fullWidth
                        noOptionsText={t('sin_resultados')}
                        value={datosPersonalesTemp.tiempoOcupacion}
                        onChange={(e, nV) => handleChangeTiempoOcupacion(e, nV)}
                        renderInput={(params) => (
                          <TextField {...params} placeholder={t('selecciona')} variant="outlined" />
                        )}
                      />
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
          <div className="grid grid-cols-2 gap-6">
            <div className="grid grid-cols-2 gap-4">
              <div>
                <h3 className="text-gray-600 font-medium mt-0 mb-2">
                  {`${t('telefono_celular')}*`}
                </h3>
                <PhoneInput
                  preferredCountries={[pais, 'us']}
                  country={pais}
                  localization={es}
                  enableSearch
                  searchPlaceholder={t('buscar')}
                  searchNotFound={t('sin_resultados')}
                  inputStyle={{ width: '100%' }}
                  value={`${datosPersonalesTemp.celular.codigo}${datosPersonalesTemp.celular.numero}`}
                  onChange={(value, country: ICountryPhoneInput) => {
                    const { dialCode } = country;
                    const nuevoCel = value.slice(dialCode.length);
                    setDatosPersonalesTemp({
                      ...datosPersonalesTemp,
                      celular: {
                        numero: nuevoCel,
                        codigo: country.dialCode,
                      },
                    });
                  }}
                  onBlur={(e) => {
                    if (e.target.value === '') {
                      setDatosPersonalesTemp({
                        ...datosPersonalesTemp,
                        celular: {
                          numero: datosPersonalesTemp.celular.numero,
                          codigo: '',
                        },
                      });
                    }
                  }}
                  disabled={refCelularField || datosPersonalesTemp.noTieneCelular || hayCambios}
                />
              </div>
              <div>
                <h3 className="text-gray-600 font-medium mt-0 mb-2">{t('telefono_fijo')}</h3>
                <PhoneInput
                  disabled={hayCambios}
                  preferredCountries={[pais, 'us']}
                  country={pais}
                  localization={es}
                  enableSearch
                  searchPlaceholder={t('buscar')}
                  searchNotFound={t('sin_resultados')}
                  inputStyle={{ width: '100%' }}
                  value={`${datosPersonalesTemp.fijo.codigo}${datosPersonalesTemp.fijo.numero}`}
                  onChange={(value, country: ICountryPhoneInput) => {
                    const { dialCode } = country;
                    const nuevoTel = value.slice(dialCode.length);
                    setDatosPersonalesTemp({
                      ...datosPersonalesTemp,
                      fijo: {
                        numero: nuevoTel,
                        codigo: country.dialCode,
                      },
                    });
                  }}
                  onBlur={(e) => {
                    if (e.target.value === '') {
                      setDatosPersonalesTemp({
                        ...datosPersonalesTemp,
                        fijo: {
                          numero: datosPersonalesTemp.fijo.numero,
                          codigo: '',
                        },
                      });
                    }
                  }}
                />
              </div>
            </div>
            {!esPaciente && (
              <div>
                <h3 className="text-gray-600 font-medium mt-0 mb-2">{`${t('email')}*`}</h3>
                <TextField
                  variant="outlined"
                  type="email"
                  value={datosPersonalesTemp.email}
                  onChange={handleChangeEmail}
                  fullWidth
                  error={emailField.error}
                  helperText={emailField.helperText}
                  FormHelperTextProps={{ className: classes.absoluteBottom }}
                  disabled={
                    esPaciente || refEmailField || datosPersonalesTemp.noTieneEmail || hayCambios
                  }
                  inputProps={{
                    autoComplete: 'off',
                    'aria-autocomplete': 'none',
                    maxLength: '100',
                  }}
                  onBlur={handleBlurEmail}
                />
              </div>
            )}
          </div>
          <div className="grid grid-cols-4 gap-6">
            <div className="col-span-2 grid grid-cols-2 gap-4">
              <div className="flex justify-end">
                <FormControlLabel
                  disabled={hayCambios}
                  control={(
                    <Checkbox
                      checked={datosPersonalesTemp.noTieneCelular}
                      color="primary"
                      onChange={handleToggleDisableCelular}
                    />
                  )}
                  label={t('no_tiene')}
                  className={classes.noMarginDer}
                />
              </div>
              <div />
            </div>
            {!esPaciente && (
              <>
                <div />
                <div className="flex justify-end">
                  <FormControlLabel
                    disabled={hayCambios}
                    control={(
                      <Checkbox
                        checked={datosPersonalesTemp.noTieneEmail}
                        color="primary"
                        onChange={handleToggleDisableEmail}
                      />
                    )}
                    label={t('no_tiene')}
                    className={classes.noMarginDer}
                  />
                </div>
              </>
            )}
          </div>
          {esPaciente && (
            <>
              <div className="grid grid-cols-5 gap-5">
                <div className="col-span-2">
                  <h3 className="text-gray-600 font-medium mt-0 mb-2">{`${t('email')}*`}</h3>
                  <TextField
                    variant="outlined"
                    type="email"
                    value={email}
                    fullWidth
                    disabled
                    inputProps={{
                      autoComplete: 'off',
                      'aria-autocomplete': 'none',
                      maxLength: '100',
                    }}
                  />
                </div>
                <div className="flex items-end">
                  <Button
                    color="primary"
                    onClick={() => {
                      setShowDialog(!showDialog);
                    }}
                    className={classes.noTextTranform}
                    startIcon={<LockIcon />}
                  >
                    {t('medico_perfil_identificacion_cambiar')}
                  </Button>
                </div>
                <div className="col-span-2">
                  <h3 className="text-gray-600 font-medium mt-0 mb-2">
                    {`${t('email_recuperacion')}*`}
                  </h3>
                  <TextField
                    variant="outlined"
                    type="email"
                    value={datosPersonalesTemp.email}
                    onChange={handleChangeEmail}
                    fullWidth
                    error={emailField.error}
                    helperText={emailField.helperText}
                    FormHelperTextProps={{ className: classes.absoluteBottom }}
                    disabled={refEmailField || datosPersonalesTemp.noTieneEmail}
                    inputProps={{
                      autoComplete: 'off',
                      'aria-autocomplete': 'none',
                      maxLength: '100',
                    }}
                    onBlur={handleBlurEmail}
                  />
                </div>
              </div>
              <div className="flex justify-end">
                <FormControlLabel
                  control={(
                    <Checkbox
                      checked={datosPersonalesTemp.noTieneEmail}
                      color="primary"
                      onChange={handleToggleDisableEmail}
                    />
                  )}
                  label={t('no_tiene')}
                  className={classes.noMarginDer}
                />
              </div>
            </>
          )}
          <div className="flex flex-wrap justify-end my-2">
            {idPaciente > 0 ? (
              <div />
            ) : (
              <div className="w-full text-right">
                <FormControlLabel
                  control={(
                    <Checkbox
                      checked={avisoPaciente}
                      onChange={handleChangeAviso}
                      color="primary"
                    />
                  )}
                  label={(
                    <span>
                      <span>
                        {`${t(
                          esPaciente ? 'he_leido_y_acepto_el' : 'el_paciente_leyo_y_acepto_el',
                        )} `}
                      </span>
                      <a
                        href="https://medipraxi.com/aviso-de-privacidad/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {t('aviso_de_privacidad')}
                        *
                      </a>
                    </span>
                  )}
                />
              </div>
            )}
            <span className="text-gray-500 text-right">
              *&nbsp;
              {t('campos_obligatorios')}
            </span>
          </div>
          <div className="flex justify-between items-center">
            {esPaciente || (datosPersonalesTemp.comoEntero.length > 0 && idPaciente > 0) ? (
              <div />
            ) : (
              <div className="flex w-full">
                <div className="w-max">
                  <h3 className="text-gray-600 font-medium mt-0 mb-2">
                    {t('_como_se_entero_el_paciente_de_mi_consultorio_')}
                  </h3>
                  <FormControl component="fieldset" fullWidth>
                    <RadioGroup
                      row
                      aria-label="entero"
                      name="entero"
                      value={datosPersonalesTemp.comoEntero}
                      onChange={handleChangeComoEntero}
                    >
                      <FormControlLabel
                        disabled={hayCambios}
                        value="REFERENCIA-AMIGO"
                        control={<Radio color="primary" />}
                        label={t('referencia_de_amigo')}
                        onClick={handleReferenciaHide}
                      />
                      <FormControlLabel
                        disabled={hayCambios}
                        value="PUBLICIDAD"
                        control={<Radio color="primary" />}
                        label={t('publicidad')}
                        onClick={handleReferenciaHide}
                      />
                      <FormControlLabel
                        disabled={hayCambios}
                        value="REFERENCIA-COLEGA"
                        control={<Radio color="primary" />}
                        label={t('referencia_de_colega')}
                        onClick={handleReferenciaHide}
                      />
                      <FormControlLabel
                        disabled={hayCambios}
                        value="OTRO"
                        control={<Radio color="primary" />}
                        label={t('otro')}
                        onClick={handleReferenciaShow}
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
                {referenciaShow && (
                  <div className="w-1/3 mr-4">
                    <h3 className="text-gray-600 font-medium mt-0 mb-2">{t('especifica')}</h3>
                    <TextField
                      disabled={hayCambios}
                      variant="outlined"
                      fullWidth
                      value={datosPersonalesTemp.otroComoEntero}
                      onChange={handleChangeOtroComoEntero}
                    />
                  </div>
                )}
              </div>
            )}
            <BotonesGuardar
              hayCambios={hayCambios}
              aceptarCallback={guardarDatosDeUsuarioAExpediente}
              cancelarCallback={guardarDatosDeExpedienteAUsuario}
              guardarCallback={handleSubmitForm}
              guardarDisable={verificarErrorValidacionGeneral}
              continuar={esPaciente}
            />
          </div>
        </form>
      </div>
    </div>
  );
}

export default DatosPersonales;
