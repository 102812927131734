import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  RadioGroup,
  FormControl,
  FormControlLabel,
  Radio,
  InputLabel,
  Select,
  MenuItem,
  // TextField,
  IconButton,
  Button,
  TextField,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { Autocomplete } from '@material-ui/lab';
import { constDia, constMes, constAnio } from '../../../constants/fechas';
import { IFechaObj } from '../../../common/types';
import { IDatosIncapacidad } from './types';
// import { useSelector, useDispatch } from 'react-redux';
// import { setLoading } from '../../../actions/loading/actions';
// import { RootState } from '../../../store';
// import { setSnackComplete } from '../../../actions/snackbar/types';

const Incapacidades = () => {
  const { t } = useTranslation();
  // const dispatch = useDispatch();
  // const abortController = new AbortController();
  // const { signal } = abortController;
  // const { idMedico } = useSelector((state: RootState) => state.Me);
  // const { idOrganizacion } = useSelector((state: RootState) => state.Organizaciones.organizacion);
  // const { consultorios } = useSelector((state: RootState) => state.Consultorios);
  const [catalogoTipoIncidencia] = useState<string[]>([
    'Enfermedad general',
    'Riesgo de trabajo',
    'Maternidad',
    'Licencia 140 Bis',
  ]);
  const [catalogoRamoSeguro] = useState<string[]>([
    'Riesgo de trabajo',
    'Enfermedad general',
    'Maternidad prenatal',
    'Maternidad enlace',
    'Maternidad postnatal',
    'Licencia 140 Bis',
  ]);
  const [catalogoTipoRiesgo] = useState<string[]>([
    'Accidente de trabajo',
    'De trayecto (ida al trabajo)',
    'De trayecto (regreso a casa)',
    'Enfermedad profesional',
  ]);
  const [catalogoSecuela] = useState<string[]>([
    'Ninguna',
    'Incapacidad temporal',
    'Valuación inicial provisional',
    'Valuación inicial definitiva',
    'Defunción',
    'Recaída',
    'Valuación posterior a la fecha de alta',
    'Revaluación provisional',
    'Recaída sin alta médica',
    'Revaluación definitiva',
  ]);
  const [catalogoControlIncapacidad] = useState<string[]>([
    'Ninguna',
    'Única',
    'Inicial',
    'Subsecuente',
    'Alta médica o ST-2',
    'Valuación o ST-3',
    'Defunción o ST-3',
  ]);
  const [tipo, setTipo] = useState<'interna' | 'imss'>('interna');
  const [fecha, setFecha] = useState<IFechaObj>({ dia: '', mes: '', anio: '' });
  const [tipoIncidencia, setTipoIncidencia] = useState<string>('');
  const [folio, setFolio] = useState<string>('');
  const [diasIncapacidad, setDiasIncapacidad] = useState<string>('');
  const [fechaInicio, setFechaInicio] = useState<IFechaObj>({ dia: '', mes: '', anio: '' });
  const [ramoSeguro, setRamoSeguro] = useState<string>('');
  const [tipoRiesgo, setTipoRiesgo] = useState<string>('');
  const [secuela, setSecuela] = useState<string>('');
  const [controlIncapacidad, setControlIncapacidad] = useState<string>('');
  const [listaIncapacidades, setListaIncapacidades] = useState<IDatosIncapacidad[]>([
    {
      tipo: 'imss',
      fecha: { dia: '01', mes: '08', anio: '2024' },
      tipoIncidencia: 'antecedentes_laborales_incidencia_enfermedad',
      folio: 'SA0001OC',
      diasIncapacidad: 5,
      fechaInicio: { dia: '01', mes: '08', anio: '2024' },
      ramoSeguro: 'antecedentes_laborales_incidencia_enfermedad',
      tipoRiesgo: '',
      secuela: '',
      controlIncapacidad: 'ninguna',
    },
    {
      tipo: 'interna',
      fecha: { dia: '15', mes: '07', anio: '2024' },
      tipoIncidencia: 'antecedentes_laborales_incidencia_enfermedad',
      folio: '00000027',
      diasIncapacidad: 4,
      fechaInicio: { dia: '15', mes: '07', anio: '2024' },
      ramoSeguro: 'antecedentes_laborales_incidencia_enfermedad',
      tipoRiesgo: '',
      secuela: '',
      controlIncapacidad: 'ninguna',
    },
  ]);

  const handleChangeFecha = (event: React.ChangeEvent<{ value: unknown }>, tp: string) => {
    const dataUpd = { ...fecha };
    const valAdd = (event.target.value as string).toString();
    if (tp === 'dia') {
      dataUpd.dia = valAdd;
    } else if (tp === 'mes') {
      dataUpd.mes = valAdd;
    } else if (tp === 'anio') {
      dataUpd.anio = valAdd;
    }
    setFecha(dataUpd);
  };

  const handleChangeFechaInicio = (event: React.ChangeEvent<{ value: unknown }>, tp: string) => {
    const dataUpd = { ...fechaInicio };
    const valAdd = (event.target.value as string).toString();
    if (tp === 'dia') {
      dataUpd.dia = valAdd;
    } else if (tp === 'mes') {
      dataUpd.mes = valAdd;
    } else if (tp === 'anio') {
      dataUpd.anio = valAdd;
    }
    setFechaInicio(dataUpd);
  };

  return (
    <div className="px-2 pb-0 bg-white container mx-auto rounded-t-md border-solid border border-gray-100 shadow">
      <h1 className="text-blue-800 font-normal m-2 text-center">Incapacidades</h1>
      <div className="mt-4 p-4 bg-white container mx-auto shadow rounded-b-md border-solid border border-gray-100">
        <div className="my-2 grid justify-end">
          <FormControl fullWidth>
            <RadioGroup
              row
              aria-label="tipo"
              name="tipo"
              value={tipo}
              onChange={(e) => setTipo(e.target.value as 'interna' | 'imss')}
            >
              <FormControlLabel
                value="interna"
                control={<Radio color="primary" />}
                label="Interna"
              />
              <FormControlLabel
                value="imss"
                control={<Radio color="primary" />}
                label="IMSS"
              />
            </RadioGroup>
          </FormControl>
        </div>
        <div className="grid grid-cols-3 gap-x-4 gap-y-2 mb-6">
          <h3 className="text-gray-600 font-medium m-0">Fecha</h3>
          <h3 className="text-gray-600 font-medium m-0">Tipo de incidencia</h3>
          <h3 className="text-gray-600 font-medium m-0">{`Folio ${tipo === 'imss' ? 'IMSS' : 'interno'}`}</h3>
          <div className="grid grid-cols-12 gap-2">
            <div className="col-span-2">
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="label-dia">{t('dia')}</InputLabel>
                <Select
                  labelId="label-dia"
                  id="select-dia"
                  value={fecha.dia}
                  onChange={(e) => handleChangeFecha(e, 'dia')}
                  label={t('dia')}
                  // error={fechaField.dia.error}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 375,
                      },
                    },
                  }}
                  displayEmpty
                >
                  <MenuItem value={0} disabled>
                    <span className="text-gray-400 font-light">{t('selecciona')}</span>
                  </MenuItem>
                  {constDia.map((diaSingle) => (
                    <MenuItem value={diaSingle} key={diaSingle}>
                      {diaSingle}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className="col-span-4">
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="label-mes">{t('mes')}</InputLabel>
                <Select
                  labelId="label-mes"
                  id="select-mes"
                  value={fecha.mes}
                  onChange={(e) => handleChangeFecha(e, 'mes')}
                  label={t('mes')}
                  // error={fechaField.mes.error}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 375,
                      },
                    },
                  }}
                >
                  <MenuItem value={0} disabled>
                    <span className="text-gray-400 font-light">{t('selecciona')}</span>
                  </MenuItem>
                  {constMes.map((mesSingle, indx) => (
                    <MenuItem value={indx + 1} key={mesSingle}>
                      {mesSingle}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className="col-span-3">
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="label-año">{t('anio')}</InputLabel>
                <Select
                  labelId="label-año"
                  id="select-año"
                  value={fecha.anio}
                  onChange={(e) => handleChangeFecha(e, 'anio')}
                  label={t('anio')}
                  // error={fechaField.anio.error}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 375,
                      },
                    },
                  }}
                >
                  <MenuItem value={0} disabled>
                    <span className="text-gray-400 font-light">{t('selecciona')}</span>
                  </MenuItem>
                  {constAnio().map((anioSingle) => (
                    <MenuItem key={anioSingle} value={anioSingle}>
                      {anioSingle}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="mr-4">
            <Autocomplete
              options={catalogoTipoIncidencia}
              getOptionLabel={(option) => option}
              fullWidth
              noOptionsText={t('sin_resultados')}
              value={tipoIncidencia}
              onChange={(e, nV) => setTipoIncidencia(nV || '')}
              renderInput={(params) => (
                <TextField {...params} placeholder={t('selecciona')} variant="outlined" />
              )}
            />
          </div>
          {tipo === 'imss' ? (
            <TextField
              variant="outlined"
              onChange={(e) => setFolio(e.target.value as string)}
              value={folio}
              fullWidth
              inputProps={{ maxLength: 50, autoComplete: 'off', 'aria-autocomplete': 'none' }}
            />
          ) : <span className="text-gray-400 font-medium m-0 pt-4">####</span>}
        </div>
        <div className="grid grid-cols-3 gap-x-4 gap-y-2 mb-6">
          <h3 className="text-gray-600 font-medium m-0">Días autorizados</h3>
          <h3 className="text-gray-600 font-medium m-0">Fecha de inicio</h3>
          <h3 className="text-gray-600 font-medium m-0">Ramo de seguro</h3>
          <div className="pr-4">
            <TextField
              variant="outlined"
              onChange={(e) => setDiasIncapacidad(e.target.value as string)}
              value={diasIncapacidad}
              inputProps={{ maxLength: 3, autoComplete: 'off', 'aria-autocomplete': 'none' }}
            />
          </div>
          <div className="grid grid-cols-12 gap-2">
            <div className="col-span-2">
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="label-dia">{t('dia')}</InputLabel>
                <Select
                  labelId="label-dia"
                  id="select-dia"
                  value={fechaInicio.dia}
                  onChange={(e) => handleChangeFechaInicio(e, 'dia')}
                  label={t('dia')}
                  // error={fechaField.dia.error}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 375,
                      },
                    },
                  }}
                  displayEmpty
                >
                  <MenuItem value={0} disabled>
                    <span className="text-gray-400 font-light">{t('selecciona')}</span>
                  </MenuItem>
                  {constDia.map((diaSingle) => (
                    <MenuItem value={diaSingle} key={diaSingle}>
                      {diaSingle}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className="col-span-4">
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="label-mes">{t('mes')}</InputLabel>
                <Select
                  labelId="label-mes"
                  id="select-mes"
                  value={fechaInicio.mes}
                  onChange={(e) => handleChangeFechaInicio(e, 'mes')}
                  label={t('mes')}
                  // error={fechaField.mes.error}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 375,
                      },
                    },
                  }}
                >
                  <MenuItem value={0} disabled>
                    <span className="text-gray-400 font-light">{t('selecciona')}</span>
                  </MenuItem>
                  {constMes.map((mesSingle, indx) => (
                    <MenuItem value={indx + 1} key={mesSingle}>
                      {mesSingle}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className="col-span-3">
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="label-año">{t('anio')}</InputLabel>
                <Select
                  labelId="label-año"
                  id="select-año"
                  value={fechaInicio.anio}
                  onChange={(e) => handleChangeFechaInicio(e, 'anio')}
                  label={t('anio')}
                  // error={fechaField.anio.error}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 375,
                      },
                    },
                  }}
                >
                  <MenuItem value={0} disabled>
                    <span className="text-gray-400 font-light">{t('selecciona')}</span>
                  </MenuItem>
                  {constAnio().map((anioSingle) => (
                    <MenuItem key={anioSingle} value={anioSingle}>
                      {anioSingle}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
          <Autocomplete
            options={catalogoRamoSeguro}
            getOptionLabel={(option) => option}
            fullWidth
            noOptionsText={t('sin_resultados')}
            value={ramoSeguro}
            onChange={(e, nV) => setRamoSeguro(nV || '')}
            renderInput={(params) => (
              <TextField {...params} placeholder={t('selecciona')} variant="outlined" />
            )}
          />
        </div>
        <div className="grid grid-cols-3 gap-x-4 gap-y-2 mb-6">
          <h3 className="text-gray-600 font-medium m-0">Tipo de riesgo</h3>
          <h3 className="text-gray-600 font-medium m-0">Secuela</h3>
          <h3 className="text-gray-600 font-medium m-0">Control de incapacidad</h3>
          <Autocomplete
            options={catalogoTipoRiesgo}
            getOptionLabel={(option) => option}
            fullWidth
            noOptionsText={t('sin_resultados')}
            value={tipoRiesgo}
            onChange={(e, nV) => setTipoRiesgo(nV || '')}
            renderInput={(params) => (
              <TextField {...params} placeholder={t('selecciona')} variant="outlined" />
            )}
          />
          <Autocomplete
            options={catalogoSecuela}
            getOptionLabel={(option) => option}
            fullWidth
            noOptionsText={t('sin_resultados')}
            value={secuela}
            onChange={(e, nV) => setSecuela(nV || '')}
            renderInput={(params) => (
              <TextField {...params} placeholder={t('selecciona')} variant="outlined" />
            )}
          />
          <Autocomplete
            options={catalogoControlIncapacidad}
            getOptionLabel={(option) => option}
            fullWidth
            noOptionsText={t('sin_resultados')}
            value={controlIncapacidad}
            onChange={(e, nV) => setControlIncapacidad(nV || '')}
            renderInput={(params) => (
              <TextField {...params} placeholder={t('selecciona')} variant="outlined" />
            )}
          />
        </div>
        <div className="text-right pt-4">
          <Button
            // disabled={!campaniaNombre || !correoAsunto}
            variant="contained"
            color="primary"
            onClick={() => {
              setListaIncapacidades([
                ...listaIncapacidades,
                {
                  tipo,
                  fecha,
                  tipoIncidencia,
                  folio,
                  diasIncapacidad: parseInt(diasIncapacidad, 10),
                  fechaInicio,
                  ramoSeguro,
                  tipoRiesgo,
                  secuela,
                  controlIncapacidad,
                },
              ]);
              setTipo('interna');
              setFecha({ dia: '', mes: '', anio: '' });
              setTipoIncidencia('');
              setFolio('');
              setDiasIncapacidad('');
              setFechaInicio({ dia: '', mes: '', anio: '' });
              setRamoSeguro('');
              setTipoRiesgo('');
              setSecuela('');
              setControlIncapacidad('');
              setFecha({ dia: '', mes: '', anio: '' });
            }}
          >
            {t('guardar')}
          </Button>
        </div>
      </div>
      <div className="border-solid border-0 border-t mt-4 border-gray-400">
        <h1 className="text-blue-800 font-normal text-center m-4">
          Lista de incapacidades
        </h1>
        {listaIncapacidades.map((incapacidad: IDatosIncapacidad) => (
          <div className="shadow-md border border-solid border-gray-100 rounded mb-4 flex justify-between relative">
            <div className="border-0 border-solid border-blue-500 rounded border-l-8 p-4 w-full">
              <h3 className="font-normal text-gray-500 m-0">
                {`${incapacidad.tipo.toUpperCase()} - ${incapacidad.folio}`}
              </h3>
              <h4 className="font-normal text-gray-500 m-0">
                {`Fecha: ${incapacidad.fecha.dia}-${incapacidad.fecha.mes}-${incapacidad.fecha.anio}`}
              </h4>
              <h4 className="font-normal text-gray-500 m-0">
                {`Tipo de incidencia: ${t(incapacidad.tipoIncidencia)}`}
              </h4>
              <h4 className="font-normal text-gray-500 m-0">
                {`Días de incapacidad: ${incapacidad.diasIncapacidad}`}
              </h4>
              <h4 className="font-normal text-gray-500 m-0">
                {`Fecha de inicio: ${incapacidad.fechaInicio}`}
              </h4>
              <h4 className="font-normal text-gray-500 m-0">
                {`Tipo de riesgo: ${incapacidad.tipoRiesgo}`}
              </h4>
              <h4 className="font-normal text-gray-500 m-0">
                {`Secuela: ${incapacidad.secuela}`}
              </h4>
              <h4 className="font-normal text-gray-500 m-0">
                {`Control de incapacidad: ${incapacidad.controlIncapacidad}`}
              </h4>
            </div>
            <div className="min-w-max self-center">
              <IconButton aria-label="edit" color="primary" onClick={() => {}}>
                <EditIcon />
              </IconButton>
              <IconButton
                aria-label="delete"
                color="primary"
                onClick={() => {}}
              >
                <DeleteIcon />
              </IconButton>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Incapacidades;
