import React from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { IListaAccidentesLaboralesProps } from './types';

function ListaAccidentesLaborales({
  fecha,
  causa,
  naturaleza,
  incidencia,
  comentarios,
  inx,
  actualizarValorArrayAccidentesLaborales,
  borrarValorArrayAccidentesLaborales,
  discapacidad,
  diasIncapacidad,
}: IListaAccidentesLaboralesProps) {
  const { t } = useTranslation();
  let txtRadioTitulo = '';
  if (discapacidad && discapacidad === 'sin') {
    txtRadioTitulo = t('antecedentes_laborales_accidente_incapacidad_sin');
  } else if (discapacidad && discapacidad === 'temporal') {
    txtRadioTitulo = t('antecedentes_laborales_accidente_incapacidad_temporal');
  } else if (discapacidad && discapacidad === 'parcial') {
    txtRadioTitulo = t('antecedentes_laborales_accidente_incapacidad_parcial');
  } else if (discapacidad && discapacidad === 'total') {
    txtRadioTitulo = t('antecedentes_laborales_accidente_incapacidad_total');
  }
  return (
    <div className="shadow-md border border-solid border-gray-200 rounded mb-4 flex justify-between items-center">
      <div className="w-full">
        <div className="flex flex-row w-full pt-2">
          <h3 className="m-0 font-normal text-blue-900 pl-4">
            {`${t('antecedentes_laborales_accidente')} ${inx + 1}`}
          </h3>
          <h3 className="m-0 font-normal text-gray-900 pl-4">
            {`${t('antecedentes_laborales_accidente_fecha')}: ${fecha}`}
          </h3>
        </div>
        <p className="m-0 pl-4 pt-2 font-normal text-gray-500">
          <b className="text-gray-900">{`${t('antecedentes_laborales_accidente_causa')}: `}</b>
          {causa}
        </p>
        <p className="m-0 pl-4 pt-2 font-normal text-gray-500">
          <b className="text-gray-900">{`${t('antecedentes_laborales_accidente_tipo')}: `}</b>
          {naturaleza}
        </p>
        {incidencia && (
          <p className="m-0 pl-4 pt-2 font-normal text-gray-500">
            <b className="text-gray-900">{`${t('antecedentes_laborales_incidencia_tipo')}: `}</b>
            {t(incidencia)}
          </p>
        )}
        {discapacidad && (
          <p className="m-0 pl-4 pt-2 font-normal text-gray-500">
            <b className="text-gray-900">
              {`${t('antecedentes_laborales_accidente_incapacidad')}: `}
            </b>
            {txtRadioTitulo}
          </p>
        )}
        {diasIncapacidad && (
          <p className="m-0 pl-4 pt-2 font-normal text-gray-500">
            <b className="text-gray-900">{`${t('incapacidad_dias')}: `}</b>
            {diasIncapacidad || ''}
          </p>
        )}
        <p className="m-0 pl-4 py-2 font-normal text-gray-500">
          <b className="text-gray-900">{`${t('comentarios')}: `}</b>
          {comentarios}
        </p>
      </div>
      <div className="min-w-max self-center">
        <IconButton
          aria-label="edit"
          onClick={() => actualizarValorArrayAccidentesLaborales(inx)}
          color="primary"
        >
          <EditIcon />
        </IconButton>
        <IconButton
          aria-label="delete"
          color="primary"
          onClick={() => borrarValorArrayAccidentesLaborales(inx)}
        >
          <DeleteIcon />
        </IconButton>
      </div>
    </div>
  );
}

export default ListaAccidentesLaborales;
