import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  IconButton,
  Button,
  RadioGroup,
  FormControl,
  MenuItem,
  Select,
  FormControlLabel,
  Radio,
  TextField,
  Slider,
  Checkbox,
  CircularProgress,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { useSelector, useDispatch } from 'react-redux';
import DateDayjsUtils from '@date-io/dayjs';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import CalendarTodayRoundedIcon from '@material-ui/icons/CalendarTodayRounded';
import {
  Chart as ChartJS, ArcElement, Tooltip, Legend,
} from 'chart.js';
import { setLoading } from '../../../actions/loading/actions';
import { RootState } from '../../../store';
import { generarXlsReporteEpidemiologia } from '../../../utils/documents';
import {
  getCatCie10Palabra,
  getBusinessDoctorReporteEpidemiologia,
} from '../../../utils/getCatalogos';
import Tabla from '../Tabla';
import { setSnackComplete } from '../../../actions/snackbar/types';
import { ICatalogosEstandar } from '../../../common/types';
import { ReactComponent as Excel2021Icon } from '../../../assets/icons/Excel2021.svg';
import { campaniaInitial, IObjCampania } from '../types';
import { IEpidemiologiaProps } from './types';

ChartJS.register(ArcElement, Tooltip, Legend);
dayjs.locale('es');

const Epidemiologia = (props: IEpidemiologiaProps) => {
  const {
    tipoPacientes,
    datoPeticionGeneral,
    // catAgemRep,
    // setCatAgemRep,
    catTipoOcupacionRep,
    catCIE10,
    setCatCIE10,
    // openCIE10,
    // setOpenCIE10,
    loadingCIE10,
    setLoadingCIE10,
    inputCIE10,
    setInputCIE10,
    datosReporteEpidemiologia,
    setDatosReporteEpidemiologia,
    filtrosReporteEpidemiologia,
    setFiltrosReporteEpidemiologia,
    resumenFiltros,
    setResumenFiltros,
    seleccionarTodo,
    setSeleccionarTodo,
    listaCampanias,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const abortController = new AbortController();
  const { signal } = abortController;
  const { idMedico } = useSelector((state: RootState) => state.Me);
  const { idOrganizacion } = useSelector((state: RootState) => state.Organizaciones.organizacion);
  const { consultorios } = useSelector((state: RootState) => state.Consultorios);
  const [campaniaSeleccionada, setCampaniaSeleccionada] = useState<IObjCampania>(campaniaInitial);

  dayjs.extend(isSameOrBefore);
  dayjs.extend(isSameOrAfter);

  const columnasReporteEpidemiologia = [
    { Header: t('pacientes-paciente'), accessor: 'nombre' },
    { Header: t('sexo'), accessor: 'sexo' },
    { Header: t('edad'), accessor: 'edad' },
    { Header: t('diagnostico'), accessor: 'diagnosticos' },
    { Header: t('ocupacion'), accessor: 'ocupacion' },
  ];
  const catalogoConsultorio = consultorios.concat([
    { id: -1, label: `${t('business_todos_consultorios')}` },
  ]);

  const findReporteEpidemiologia = () => {
    dispatch(setLoading(true));
    getBusinessDoctorReporteEpidemiologia(
      idMedico,
      idOrganizacion,
      filtrosReporteEpidemiologia.consultorio,
      tipoPacientes,
      `${dayjs(filtrosReporteEpidemiologia.fecha.start).format('YYYYMMDD')},${dayjs(
        filtrosReporteEpidemiologia.fecha.end,
      ).format('YYYYMMDD')}`,
      filtrosReporteEpidemiologia.edad.toString(),
      filtrosReporteEpidemiologia.sexo,
      // filtrosReporteEpidemiologia.agees.map((agee) => agee.cve_agee).toString(),
      // filtrosReporteEpidemiologia.agems.map((agem) => agem.cve_agem).toString(),
      filtrosReporteEpidemiologia.diagnosticos.map((dg) => dg.id).toString(),
      filtrosReporteEpidemiologia.ocupaciones.map((oc) => oc.id).toString(),
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.code === 200) {
          setDatosReporteEpidemiologia(result.datos);
          const resumen = [
            `Fecha: ${dayjs(filtrosReporteEpidemiologia.fecha.start).format(
              'DD/MM/YYYY',
            )} - ${dayjs(filtrosReporteEpidemiologia.fecha.end).format('DD/MM/YYYY')}`,
            `Consultorio: ${
              catalogoConsultorio.find((c) => c && c.id === filtrosReporteEpidemiologia.consultorio)
                ?.label
            }`,
            `Edad: entre ${filtrosReporteEpidemiologia.edad[0]} y ${filtrosReporteEpidemiologia.edad[1]} años`,
            `Sexo: ${t(filtrosReporteEpidemiologia.sexo)}`,
          ];
          if (filtrosReporteEpidemiologia.diagnosticos.length > 0) {
            resumen.push(
              `Diagnostico(s): ${filtrosReporteEpidemiologia.diagnosticos
                .map((dg) => dg.label)
                .join(' / ')}`,
            );
          }
          if (filtrosReporteEpidemiologia.ocupaciones.length > 0) {
            resumen.push(
              `Ocupacion(es): ${filtrosReporteEpidemiologia.ocupaciones
                .map((oc) => oc.label)
                .join(' / ')}`,
            );
          }
          setResumenFiltros(resumen.join(' | '));
        } else {
          setDatosReporteEpidemiologia([]);
        }
        dispatch(setLoading(false));
      })
      .catch(() => dispatch(setLoading(false)));
  };
  // cambios del reporte de epidemiología
  // const handleChangeAgeeReporte = (event: any, newValue: ICatalogoAGEE[] | []) => {
  //   setFiltrosReporteEpidemiologia({
  //     ...filtrosReporteEpidemiologia,
  //     agee: newValue,
  //   });
  //   setSelAGEM(null);
  //   if (newValue.length === 1) {
  //     getCatalogoAgem(newValue[0].cve_agee).then((result: ICatalogoAGEM[]) => {
  //       setCatAgemRep(result);
  //     });
  //   }
  // };
  // const handleChangeAgemReporte = (event: any, newValue: ICatalogoAGEM[] | []) => {
  //   setFiltrosReporteEpidemiologia({
  //     ...filtrosReporteEpidemiologia,
  //     agem: newValue,
  //   });
  // };

  // para buscar los diagnósticos en el filtro del reporte epidemiológico
  useEffect(() => {
    if (inputCIE10.length > 2) {
      setLoadingCIE10(true);
      getCatCie10Palabra(inputCIE10, signal)
        .then((response) => response.json())
        .then((result) => {
          setCatCIE10(result.code === 200 ? result.datos : []);
          setLoadingCIE10(false);
        })
        .catch(() => {
          setCatCIE10([]);
          setLoadingCIE10(false);
        });
    }
    return () => abortController.abort();
  }, [inputCIE10]);

  return (
    <>
      <h1 className="text-blue-800 font-normal m-0 text-center pb-4">
        {t('business_reportes_epidemiologia')}
      </h1>
      <div className="flex flex-row my-2">
        <div className="w-3/12 mr-4">
          <h4 className="text-gray-500 font-normal mb-2">{`${t('business_rango_tiempo')}`}</h4>
          <div className="flex flex-row items-end">
            <div className="m-0 mr-2">
              <MuiPickersUtilsProvider locale="es" utils={DateDayjsUtils}>
                <KeyboardDatePicker
                  value={filtrosReporteEpidemiologia.fecha.start}
                  onChange={(e: any) => {
                    if (
                      e
                      && filtrosReporteEpidemiologia.fecha.end
                      && dayjs(e.toDate()).isSameOrBefore(filtrosReporteEpidemiologia.fecha.end)
                    ) {
                      const dato = { ...filtrosReporteEpidemiologia };
                      dato.fecha.start = new Date(e.toDate());
                      setFiltrosReporteEpidemiologia(dato);
                    } else if (e) {
                      const dato = { ...filtrosReporteEpidemiologia };
                      dato.fecha.start = new Date(e.toDate());
                      setFiltrosReporteEpidemiologia(dato);
                    }
                  }}
                  maxDate={filtrosReporteEpidemiologia.fecha.end}
                  format="DD/MM/YYYY"
                  label={t('calendar-desde')}
                  invalidDateMessage={t('calendar-error-fecha')}
                  keyboardIcon={<CalendarTodayRoundedIcon color="primary" />}
                />
              </MuiPickersUtilsProvider>
            </div>
            <div className="m-0 mr-2">
              <MuiPickersUtilsProvider locale="es" utils={DateDayjsUtils}>
                <KeyboardDatePicker
                  value={filtrosReporteEpidemiologia.fecha.end}
                  onChange={(e) => {
                    if (
                      e
                      && dayjs(e.toDate()).isSameOrAfter(filtrosReporteEpidemiologia.fecha.start)
                    ) {
                      const dato = { ...filtrosReporteEpidemiologia };
                      dato.fecha.end = new Date(e.toDate());
                      setFiltrosReporteEpidemiologia(dato);
                    } else if (e) {
                      dispatch(
                        setSnackComplete({
                          open: true,
                          severity: 'error',
                          mensaje: `${t('calendar-hasta-hora')}`,
                        }),
                      );
                    }
                  }}
                  minDate={filtrosReporteEpidemiologia.fecha.start}
                  maxDate={new Date()}
                  format="DD/MM/YYYY"
                  label={t('calendar-hasta')}
                  minDateMessage={t('calendar-hasta-error')}
                  invalidDateMessage={t('calendar-error-fecha')}
                  keyboardIcon={<CalendarTodayRoundedIcon color="primary" />}
                />
              </MuiPickersUtilsProvider>
            </div>
          </div>
        </div>
        <div className="w-5/12 mr-4">
          <h4 className="text-gray-500 font-normal mb-2">{`${t('consultorio')}`}</h4>
          <FormControl variant="outlined" fullWidth>
            {consultorios.length === 1 ? (
              <p className="m-2">{consultorios[0].label}</p>
            ) : (
              <Select
                value={filtrosReporteEpidemiologia.consultorio}
                onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                  setFiltrosReporteEpidemiologia({
                    ...filtrosReporteEpidemiologia,
                    consultorio: Number(event.target.value),
                  });
                }}
                displayEmpty
              >
                <MenuItem value={0} disabled>
                  <span className="text-gray-400 font-light">{t('selecciona')}</span>
                </MenuItem>
                {catalogoConsultorio.map((singleConsultorio: ICatalogosEstandar) => (
                  <MenuItem value={singleConsultorio.id} key={singleConsultorio.id}>
                    {singleConsultorio.label}
                  </MenuItem>
                ))}
              </Select>
            )}
          </FormControl>
        </div>
        {/* <div className="w-4/12 mr-4">
          <h4 className="text-gray-500 font-normal mb-2">{t(`${geo.agee.label}`)}</h4>
          <FormControl variant="outlined" fullWidth>
            <Autocomplete
              multiple
              options={catAGEE}
              getOptionLabel={(option) => option.nom_agee}
              fullWidth
              noOptionsText={t('sin_resultados')}
              value={filtrosReporteEpidemiologia.agees || []}
              onChange={(e, nV) => handleChangeAgeeReporte(e, nV)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder={t(`${geo.agee.label}`)}
                  variant="outlined"
                />
              )}
            />
          </FormControl>
        </div>
        <div className="w-4/12 mr-4">
          <h4 className="text-gray-500 font-normal mb-2">{t(`${geo.agem.label}`)}</h4>
          <FormControl variant="outlined" fullWidth>
            <Autocomplete
              multiple
              options={catAgemRep}
              getOptionLabel={(option) => option.nom_agem}
              fullWidth
              disabled={filtrosReporteEpidemiologia.agee.length !== 1}
              noOptionsText={t('sin_resultados')}
              value={filtrosReporteEpidemiologia.agems || []}
              onChange={(e, nV) => handleChangeAgemReporte(e, nV)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder={t(`${geo.agem.label}`)}
                  variant="outlined"
                />
              )}
            />
          </FormControl>
        </div> */}
        <div className="w-4/12 mr-4">
          <h4 className="text-gray-500 font-normal mb-2">{`${t('edad')}`}</h4>
          <Slider
            value={filtrosReporteEpidemiologia.edad}
            getAriaValueText={(val: number) => val.toString()}
            onChange={(event, newValue: number | number[]) => {
              setFiltrosReporteEpidemiologia({
                ...filtrosReporteEpidemiologia,
                edad: newValue as number[],
              });
            }}
            aria-labelledby="escala-lineal"
            // valueLabelDisplay="on"
            valueLabelDisplay="auto"
            step={1}
            min={0}
            max={120}
            marks={[
              { value: 0, label: '0' },
              { value: 60, label: '60' },
              { value: 120, label: '120' },
            ]}
          />
        </div>
      </div>
      <div className="flex flex-row">
        <div className="w-3/12 mr-4">
          <h4 className="text-gray-500 font-normal mb-2">{`${t('sexo')}`}</h4>
          <FormControl variant="outlined" fullWidth>
            <RadioGroup
              row
              aria-label="filtroRepSexo"
              name="filtroRepSexo"
              value={filtrosReporteEpidemiologia.sexo}
              onChange={(event) => {
                setFiltrosReporteEpidemiologia({
                  ...filtrosReporteEpidemiologia,
                  sexo: event.target.value as string,
                });
              }}
            >
              <FormControlLabel
                value="mujer"
                control={<Radio color="primary" />}
                label={t('mujer')}
              />
              <FormControlLabel
                value="hombre"
                control={<Radio color="primary" />}
                label={t('hombre')}
              />
              <FormControlLabel
                value="ambos"
                control={<Radio color="primary" />}
                label={t('ambos')}
              />
            </RadioGroup>
          </FormControl>
        </div>
        <div className="w-5/12 mr-4">
          <h4 className="text-gray-500 font-normal mb-2">{`${t('diagnostico')}`}</h4>
          <FormControl variant="outlined" fullWidth>
            <Autocomplete
              multiple
              // open={openCIE10}
              // onOpen={() => {
              //   setOpenCIE10(true);
              // }}
              // onClose={() => {
              //   setOpenCIE10(false);
              // }}
              getOptionLabel={(option) => option.label}
              options={catCIE10}
              loading={loadingCIE10}
              loadingText="Buscando..."
              noOptionsText={
                inputCIE10.length <= 2 ? t('introduce-tres-caracteres') : t('sin_resultados')
              }
              value={filtrosReporteEpidemiologia.diagnosticos}
              onChange={(e, nV) => {
                setFiltrosReporteEpidemiologia({
                  ...filtrosReporteEpidemiologia,
                  diagnosticos: nV,
                });
              }}
              onInputChange={(e, newInputValue) => {
                if (newInputValue.length < 3) {
                  setCatCIE10([]);
                }
                setInputCIE10(newInputValue);
              }}
              fullWidth
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder={t('selecciona')}
                  variant="outlined"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {loadingCIE10 ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
            />
          </FormControl>
        </div>
        <div className="w-4/12 mr-4">
          <h4 className="text-gray-500 font-normal mb-2">{`${t('ocupacion')}`}</h4>
          <FormControl variant="outlined" fullWidth>
            <Autocomplete
              multiple
              options={catTipoOcupacionRep}
              getOptionLabel={(option) => option.label}
              fullWidth
              noOptionsText={t('sin_resultados')}
              value={filtrosReporteEpidemiologia.ocupaciones}
              onChange={(e, nV) => {
                setFiltrosReporteEpidemiologia({
                  ...filtrosReporteEpidemiologia,
                  ocupaciones: nV,
                });
              }}
              renderInput={(params) => (
                <TextField {...params} placeholder={t('ocupacion')} variant="outlined" />
              )}
            />
          </FormControl>
        </div>
      </div>
      <div className="flex justify-between items-center pt-4">
        <div className="text-left">
          <h3 className="m-0 mr-4 text-gray-800 font-normal mt-4">{resumenFiltros}</h3>
        </div>
        <Button
          variant="contained"
          color="primary"
          onClick={() => findReporteEpidemiologia()}
          // disabled={!filtrosReporteEpidemiologia.fecha.start || !filtrosReporteEpidemiologia.fecha.end}
        >
          {t('buscar')}
        </Button>
      </div>
      <div className="border-solid border-0 border-t mt-4 border-gray-400">
        <div className="flex flex-row w-full my-2">
          <div className="flex flex-row justify-between w-4/5">
            <div className="self-center w-1/5">
              <FormControlLabel
                control={(
                  <Checkbox
                    checked={seleccionarTodo}
                    color="primary"
                    onChange={(e) => setSeleccionarTodo(e.target.checked)}
                  />
                )}
                label={t('seleccionar_todos')}
              />
            </div>
            <div className="ml-2 w-3/5">
              <FormControl variant="outlined" fullWidth>
                <Autocomplete
                  options={listaCampanias}
                  getOptionLabel={(option) => option.campaniaNombre}
                  fullWidth
                  noOptionsText={t('sin_resultados')}
                  value={campaniaSeleccionada}
                  onChange={(e, nV) => setCampaniaSeleccionada(nV || campaniaInitial)}
                  renderInput={(params) => (
                    <TextField {...params} placeholder={t('seleccione')} variant="outlined" />
                  )}
                />
              </FormControl>
            </div>
            <div className="self-center ml-2 w-1/5">
              <Button
                variant="contained"
                color="primary"
                onClick={() => {}}
              >
                {t('business_campanias_enviar')}
              </Button>
            </div>
          </div>
          <div className="flex flex-row justify-end w-1/5">
            <h4 className="text-gray-500 font-normal">{t('business_exportar_excel')}</h4>
            <IconButton
              aria-label="edit"
              disabled
              onClick={() => {
                if (datosReporteEpidemiologia.length === 0) {
                  dispatch(
                    setSnackComplete({
                      open: true,
                      severity: 'error',
                      mensaje: t('business_exportar_excel_sin_datos'),
                    }),
                  );
                } else {
                  const sendData = {
                    idMedico,
                    idConsultorio: filtrosReporteEpidemiologia.consultorio,
                    start: dayjs(datoPeticionGeneral.start).format('YYYYMMDD'),
                    end: dayjs(datoPeticionGeneral.end).format('YYYYMMDD'),
                  };
                  generarXlsReporteEpidemiologia(sendData);
                }
              }}
              color="primary"
            >
              <Excel2021Icon />
            </IconButton>
          </div>
        </div>
        <Tabla
          columns={columnasReporteEpidemiologia}
          firstColumnIsTitle
          data={datosReporteEpidemiologia}
          pageCount={datosReporteEpidemiologia.length / 20}
          pageSize={20}
        />
      </div>
    </>
  );
};

export default Epidemiologia;
