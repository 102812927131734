import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton } from '@material-ui/core/';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import DeleteDialog from '../../../../../../../components/DeleteDialog';
import { RootState } from '../../../../../../../store';
import {
  deleteDictamenesTipoExamen,
  deleteDictamenesOcupacion,
  deleteDictamenesRestriccion,
  deleteDictamenesCausaNoAptitud,
} from '../../../../../../../utils/deleteInfo';
import { IListaCatalogosProps } from './types';
import { ICatalogosCombinados } from '../../types';
import { setRequest } from '../../../../../../../actions/request/types';

function ListaCatalogos({
  tipoCatalogo,
  listaCatalogo,
  catTiposExamen,
  setCatTiposExamen,
  catPuestos,
  setCatPuestos,
  catRestricciones,
  setCatRestricciones,
  catCausasNoAptitud,
  setCatCausasNoAptitud,
  setObjCatalogo,
}: IListaCatalogosProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { idMedico } = useSelector((state: RootState) => state.Me);
  const { idPaciente } = useSelector((state: RootState) => state.BasicosPaciente);
  const { idOrganizacion } = useSelector((state: RootState) => state.Organizaciones.organizacion);
  const [index, setIndex] = useState<number>(-1);
  const [deleteAlert, setDeleteAlert] = useState<boolean>(false);
  const [editAlert, setEditAlert] = useState<boolean>(false);

  const handleDeleteAlertClose = (
    event: React.SyntheticEvent | React.MouseEvent,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setDeleteAlert(false);
  };
  const handleEditAlertClose = (
    event: React.SyntheticEvent | React.MouseEvent,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setEditAlert(false);
  };
  const editCatalogo = () => {
    let obj: ICatalogosCombinados = {
      id: 0,
      label: '',
      categoria: { id: 0, label: '' },
      source: 'medipraxi',
      inUse: false,
    };
    if (tipoCatalogo === 'tipo_examen') {
      obj = { ...catTiposExamen[index] };
    } else if (tipoCatalogo === 'puesto') {
      obj = { ...catPuestos[index] };
    } else if (tipoCatalogo === 'restricciones') {
      obj = { ...catRestricciones[index] };
    } else if (tipoCatalogo === 'causas_no_aptitud') {
      obj = { ...catCausasNoAptitud[index] };
    }
    setObjCatalogo(obj);
  };

  const deleteCatalogo = () => {
    const dataToSend = {
      idMedico,
      idPaciente,
      idOrganizacion,
    };
    let requestFunction;
    if (tipoCatalogo === 'tipo_examen') {
      requestFunction = deleteDictamenesTipoExamen({
        ...dataToSend,
        idTipoExamen: catTiposExamen[index].id,
      });
    } else if (tipoCatalogo === 'puesto') {
      requestFunction = deleteDictamenesOcupacion({
        ...dataToSend,
        idOcupacion: catPuestos[index].id,
      });
    } else if (tipoCatalogo === 'restricciones') {
      requestFunction = deleteDictamenesRestriccion({
        ...dataToSend,
        idRestriccion: catRestricciones[index].id,
      });
    } else if (tipoCatalogo === 'causas_no_aptitud') {
      requestFunction = deleteDictamenesCausaNoAptitud({
        ...dataToSend,
        idCausaNoAptitud: catCausasNoAptitud[index].id,
      });
    }
    dispatch(
      setRequest({
        type: 'delete',
        requestFunction,
        successFunction: () => {
          if (tipoCatalogo === 'tipo_examen') {
            const nuevaLista = [...catTiposExamen];
            nuevaLista.splice(index, 1);
            setCatTiposExamen(nuevaLista);
          } else if (tipoCatalogo === 'puesto') {
            const nuevaLista = [...catPuestos];
            nuevaLista.splice(index, 1);
            setCatPuestos(nuevaLista);
          } else if (tipoCatalogo === 'restricciones') {
            const nuevaLista = [...catRestricciones];
            nuevaLista.splice(index, 1);
            setCatRestricciones(nuevaLista);
          } else if (tipoCatalogo === 'causas_no_aptitud') {
            const nuevaLista = [...catCausasNoAptitud];
            nuevaLista.splice(index, 1);
            setCatCausasNoAptitud(nuevaLista);
          }
        },
      }),
    );
  };

  return (
    <>
      <DeleteDialog
        open={deleteAlert}
        titulo={t('_atencion_')}
        descripcion={t('_seguro_que_deseas_eliminar_el_registro_')}
        callBackAceptar={deleteCatalogo}
        callBackClose={handleDeleteAlertClose}
      />
      <DeleteDialog
        open={editAlert}
        titulo={t('_atencion_')}
        descripcion={t('si_quieres_editar_el_registro_se_borrara_el_que_estas_creando')}
        callBackAceptar={editCatalogo}
        callBackClose={handleEditAlertClose}
      />
      <div className="mt-0 bg-white rounded p-4 shadow">
        <h2 className="text-blue-800 font-normal">{t('lista_de_opciones')}</h2>
        <div className="pt-4">
          {listaCatalogo.map((cat: ICatalogosCombinados, inx: number) => (
            <>
              {cat.source === 'propio' && (
                <div className="shadow-md border border-solid border-gray-100 rounded mb-4 flex justify-between relative">
                  <div className="border-0 border-solid border-blue-500 rounded border-l-8 p-4 w-full">
                    {tipoCatalogo === 'tipo_examen' && cat.categoria.id > 0 && (
                      <h4 className="font-normal m-0 text-gray-600">{cat.categoria.label}</h4>
                    )}
                    <h3 className="font-normal m-0 text-gray-500">{cat.label}</h3>
                  </div>
                  {!cat.inUse && (
                    <div className="min-w-max self-center">
                      <IconButton
                        aria-label="edit"
                        color="primary"
                        onClick={() => {
                          setIndex(inx);
                          setEditAlert(true);
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        aria-label="delete"
                        color="primary"
                        onClick={() => {
                          setIndex(inx);
                          setDeleteAlert(true);
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </div>
                  )}
                </div>
              )}
            </>
          ))}
        </div>
      </div>
    </>
  );
}

export default ListaCatalogos;
