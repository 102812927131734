import { ThunkDispatch } from 'redux-thunk';
import { edadCalculada } from '../../common/functions';
import { IEdad } from '../../common/types';
import {
  IAlergias,
  IObjEstudios,
  PacienteActionTypes,
  SET_PACIENTE,
  REMOVE_PACIENTE,
  PACIENTE_ERROR,
  PACIENTE_DIA,
  PACIENTE_MES,
  PACIENTE_ANIO,
  PACIENTE_NOMBRE,
  PACIENTE_SEXO,
  PACIENTE_ID,
  PACIENTE_ID_USUARIO,
  PACIENTE_DOC_ID,
  PACIENTE_SIN_DOC_ID,
  PACIENTE_USUARIO,
  PACIENTE_EDAD,
  PACIENTE_GESTACION,
  PACIENTE_TIPO_EMPLEADO,
  PACIENTE_NUMERO_EMPLEADO,
  PACIENTE_ALERGIAS,
  PACIENTE_TELEFONOS,
} from './types';
import {
  getDatosGeneralesPaciente,
  getDatosGeneralesUsuarioPaciente,
  getToken,
} from '../../utils/common';

export function setPaciente(
  nombre: string,
  sexo: string,
  dia: string,
  mes: string,
  anio: string,
  idPaciente: number,
  idUsuario: number,
  docId: string,
  sinDocId: boolean | null,
  usuario: string,
  edad: IEdad,
  gestacion: string,
  tipoEmpleado: string,
  numeroEmpleado: string,
  alergias: IAlergias[],
  telefonos: string[],
  showCervicoUterino: boolean,
  existsEstudios: IObjEstudios,
): PacienteActionTypes {
  return {
    type: SET_PACIENTE,
    payload: {
      nombre,
      sexo,
      dia,
      mes,
      anio,
      idPaciente,
      idUsuario,
      docId,
      sinDocId,
      usuario,
      edad,
      gestacion,
      tipoEmpleado,
      numeroEmpleado,
      alergias,
      telefonos,
      showCervicoUterino,
      existsEstudios,
    },
  };
}

export function resetPaciente(): PacienteActionTypes {
  return {
    type: REMOVE_PACIENTE,
  };
}

export function pacienteError(error: string): PacienteActionTypes {
  return {
    type: PACIENTE_ERROR,
    payload: error,
  };
}

export const getPaciente = (idPaciente: number, idMedico: number, idOrganizacion: number) => (dispatch: ThunkDispatch<{}, {}, PacienteActionTypes>) => {
  if (idPaciente > 0) {
    fetch(getDatosGeneralesPaciente(idPaciente, idMedico, idOrganizacion), {
      method: 'GET',
      headers: { Authorization: `Bearer ${getToken()}` },
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.code === 200) {
          dispatch(
            setPaciente(
              result.datos[0].NOMBRE,
              result.datos[0].SEXO,
              result.datos[0].dia,
              result.datos[0].mes,
              result.datos[0].anio,
              result.datos[0].ID,
              result.datos[0].ID_USUARIO,
              result.datos[0].DOC_ID,
              result.datos[0].SIN_DOC_ID,
              result.datos[0].USUARIO,
              edadCalculada(result.datos[0].anio, result.datos[0].mes, result.datos[0].dia),
              !result.datos[0].GESTACION ? '' : result.datos[0].GESTACION,
              result.datos[0].tipoEmpleado || '',
              result.datos[0].NUMERO_EMPLEADO || '',
              result.datos[0].alergias,
              result.datos[0].telefonos,
              result.datos[0].showCervicoUterino,
              result.datos[0].existsEstudios,
            ),
          );
        } else {
          dispatch(pacienteError(`${result.msg}`));
        }
      })
      .catch((error) => {
        dispatch(pacienteError(error.toString()));
      });
  }
};

export const getUsuarioPaciente = (idPaciente: number) => (dispatch: ThunkDispatch<{}, {}, PacienteActionTypes>) => {
  fetch(getDatosGeneralesUsuarioPaciente(idPaciente), {
    method: 'GET',
    headers: { Authorization: `Bearer ${getToken()}` },
  })
    .then((response) => response.json())
    .then((result) => {
      if (result.code === 200) {
        dispatch(
          setPaciente(
            result.datos[0].NOMBRE,
            result.datos[0].SEXO,
            result.datos[0].dia,
            result.datos[0].mes,
            result.datos[0].anio,
            result.datos[0].ID,
            result.datos[0].ID_USUARIO,
            result.datos[0].DOC_ID,
            result.datos[0].SIN_DOC_ID,
            result.datos[0].USUARIO,
            edadCalculada(result.datos[0].anio, result.datos[0].mes, result.datos[0].dia),
            !result.datos[0].GESTACION ? '' : result.datos[0].GESTACION,
            result.datos[0].tipoEmpleado || '',
            result.datos[0].NUMERO_EMPLEADO || '',
            result.datos[0].alergias,
            result.datos[0].telefonos,
            result.datos[0].showCervicoUterino,
            result.datos[0].existsEstudios,
          ),
        );
      } else {
        dispatch(pacienteError(`${result.msg}`));
      }
    })
    .catch((error) => {
      dispatch(pacienteError(error.toString()));
    });
};

export function setPacienteDia(dia: string): PacienteActionTypes {
  return {
    type: PACIENTE_DIA,
    payload: dia,
  };
}

export function setPacienteMes(mes: string): PacienteActionTypes {
  return {
    type: PACIENTE_MES,
    payload: mes,
  };
}

export function setPacienteAnio(anio: string): PacienteActionTypes {
  return {
    type: PACIENTE_ANIO,
    payload: anio,
  };
}

export function setPacienteSexo(sexo: string): PacienteActionTypes {
  return {
    type: PACIENTE_SEXO,
    payload: sexo,
  };
}

export function setPacienteDocId(docId: string): PacienteActionTypes {
  return {
    type: PACIENTE_DOC_ID,
    payload: docId,
  };
}

export function setPacienteSinDocId(check: boolean): PacienteActionTypes {
  return {
    type: PACIENTE_SIN_DOC_ID,
    payload: check,
  };
}

export function setPacienteNombre(nombre: string): PacienteActionTypes {
  return {
    type: PACIENTE_NOMBRE,
    payload: nombre,
  };
}

export function setPacienteUsuario(usuario: string): PacienteActionTypes {
  return {
    type: PACIENTE_USUARIO,
    payload: usuario,
  };
}

export function setPacienteID(id: number): PacienteActionTypes {
  return {
    type: PACIENTE_ID,
    payload: id,
  };
}

export function setPacienteIdUsuario(id: number): PacienteActionTypes {
  return {
    type: PACIENTE_ID_USUARIO,
    payload: id,
  };
}

export function setPacienteEdad(edad: IEdad): PacienteActionTypes {
  return {
    type: PACIENTE_EDAD,
    payload: edad,
  };
}

export function setPacienteGestacion(gestacion: string): PacienteActionTypes {
  return {
    type: PACIENTE_GESTACION,
    payload: gestacion,
  };
}

export function setPacienteTipoEmpleado(tipoEmpleado: string): PacienteActionTypes {
  return {
    type: PACIENTE_TIPO_EMPLEADO,
    payload: tipoEmpleado,
  };
}

export function setPacienteNumeroEmpleado(numeroEmpleado: string): PacienteActionTypes {
  return {
    type: PACIENTE_NUMERO_EMPLEADO,
    payload: numeroEmpleado,
  };
}

export function setPacienteAlergias(alergias: IAlergias[]): PacienteActionTypes {
  return {
    type: PACIENTE_ALERGIAS,
    payload: alergias,
  };
}

export function setPacienteTelefonos(telefonos: string[]): PacienteActionTypes {
  return {
    type: PACIENTE_TELEFONOS,
    payload: telefonos,
  };
}
