export const SET_ID = 'SET_ID';
export const ACREDITADO = 'ACREDITADO';
export const DESACREDITADO = 'DESACREDITADO';
export const SUSCRITO = 'SUSCRITO';
export const SINSUSCRIPCION = 'SINSUBSCRIPCION';
export const SET_AUTH = 'SET_AUTH';
export const RESET_AUTH = 'RESET_AUTH';

export interface IAuth {
  id: string;
  acreditado: boolean | null;
  suscrito: boolean | null;
}

interface SetIDAction {
  type: typeof SET_ID;
  payload: string;
}

interface SetSuscripcionAction {
  type: typeof SUSCRITO;
}

interface SetSinSuscripcionAction {
  type: typeof SINSUSCRIPCION;
}

interface SetAcreditadoAction {
  type: typeof ACREDITADO;
}

interface SetNoAcreditadoAction {
  type: typeof DESACREDITADO;
}

interface SetAuthAction {
  type: typeof SET_AUTH;
  payload: IAuth;
}

interface ResetAuthAction {
  type: typeof RESET_AUTH;
}

export type AuthActionTypes =
  | SetIDAction
  | SetSuscripcionAction
  | SetSinSuscripcionAction
  | SetAcreditadoAction
  | SetNoAcreditadoAction
  | SetAuthAction
  | ResetAuthAction;
