import fileSaver from 'file-saver';
import {
  getExcelLaboratorio,
  getPDFConsulta,
  getXlsPacientesDiaURL,
  getXlsPacientesEdadSexoURL,
  getXlsPacientesDiagnosticosSexoURL,
  getXlsPacientesDistribucionGeograficaURL,
  getXlsReporteEpidemiologiaURL,
} from './commonUrl';
import { getToken } from './commonStore';

export async function generarXlsPaciente(objToSend: any) {
  const token = getToken();
  await fetch(getExcelLaboratorio(objToSend.idPaciente, objToSend.idLaboratorio, objToSend.txt), {
    method: 'GET',
    headers: { Authorization: `Bearer ${token}` },
  })
    .then((response: any) => {
      response.arrayBuffer().then((buffer: any) => {
        const blob = new Blob([buffer], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        fileSaver.saveAs(blob, 'resultados.xlsx');
      });
    })
    .catch((error) => ({ error }));
  return {};
}

export async function generarXlsPacientesDia(objToSend: any) {
  const {
    idMedico, idConsultorio, idOrganizacion, tipoPacientes, start, end,
  } = objToSend;
  const token = getToken();
  await fetch(getXlsPacientesDiaURL(idMedico, idConsultorio, idOrganizacion, tipoPacientes, start, end), {
    method: 'GET',
    headers: { Authorization: `Bearer ${token}` },
  })
    .then((response: any) => {
      response.arrayBuffer().then((buffer: any) => {
        const blob = new Blob([buffer], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        fileSaver.saveAs(blob, 'pacientes_dia.xlsx');
      });
    })
    .catch((error) => ({ error }));
  return {};
}

export async function generarXlsPacientesEdadSexo(objToSend: any) {
  const {
    idMedico, idConsultorio, tipoPacientes, start, end, tipo,
  } = objToSend;
  const token = getToken();
  await fetch(getXlsPacientesEdadSexoURL(idMedico, idConsultorio, tipoPacientes, start, end, tipo), {
    method: 'GET',
    headers: { Authorization: `Bearer ${token}` },
  })
    .then((response: any) => {
      response.arrayBuffer().then((buffer: any) => {
        const blob = new Blob([buffer], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        fileSaver.saveAs(blob, 'pacientes_edad_sexo.xlsx');
      });
    })
    .catch((error) => ({ error }));
  return {};
}

export async function generarXlsPacientesDiagnosticosSexo(objToSend: any) {
  const {
    idMedico, idConsultorio, tipoPacientes, start, end,
  } = objToSend;
  const token = getToken();
  await fetch(getXlsPacientesDiagnosticosSexoURL(idMedico, idConsultorio, tipoPacientes, start, end), {
    method: 'GET',
    headers: { Authorization: `Bearer ${token}` },
  })
    .then((response: any) => {
      response.arrayBuffer().then((buffer: any) => {
        const blob = new Blob([buffer], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        fileSaver.saveAs(blob, 'pacientes_diagnostico_sexo.xlsx');
      });
    })
    .catch((error) => ({ error }));
  return {};
}

export async function generarXlsPacientesDistribucionGeografica(objToSend: any) {
  const {
    idMedico, idOrganizacion, idConsultorio, tipoPacientes, start, end, agee, agem,
  } = objToSend;
  const token = getToken();
  await fetch(
    getXlsPacientesDistribucionGeograficaURL(idMedico, idOrganizacion, idConsultorio, tipoPacientes, start, end, agee, agem),
    {
      method: 'GET',
      headers: { Authorization: `Bearer ${token}` },
    },
  )
    .then((response: any) => {
      response.arrayBuffer().then((buffer: any) => {
        const blob = new Blob([buffer], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        fileSaver.saveAs(blob, 'pacientes_distribucion_geografica.xlsx');
      });
    })
    .catch((error) => ({ error }));
  return {};
}

export async function generarXlsReporteEpidemiologia(objToSend: any) {
  const {
    idMedico, idConsultorio, tipoPacientes, start, end,
  } = objToSend;
  const token = getToken();
  await fetch(getXlsReporteEpidemiologiaURL(idMedico, idConsultorio, tipoPacientes, start, end), {
    method: 'GET',
    headers: { Authorization: `Bearer ${token}` },
  })
    .then((response: any) => {
      response.arrayBuffer().then((buffer: any) => {
        const blob = new Blob([buffer], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        fileSaver.saveAs(blob, 'reporte_epidemiologia.xlsx');
      });
    })
    .catch((error) => ({ error }));
  return {};
}

export async function generarPDFResumen(objToSend: any) {
  const token = getToken();
  await fetch(getPDFConsulta(objToSend.idConsulta, objToSend.tipoSolicitud), {
    method: 'GET',
    headers: { Authorization: `Bearer ${token}` },
  })
    .then((response: any) => {
      response.arrayBuffer().then((buffer: any) => {
        const blob = new Blob([buffer], {
          type: 'application/pdf',
        });
        fileSaver.saveAs(blob, `consulta_${objToSend.idConsulta}.pdf`);
      });
    })
    .catch((error) => ({ error }));
  return {};
}
