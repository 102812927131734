import {
  EscolaridadURL,
  EstadoCivilURL,
  EspecialidadMedicinaURL,
  GrupoEtnicoURL,
  NacionalidadURL,
  ReligionURL,
  TipoOcupacionURL,
  CausaAccidenteURL,
  NaturalezaLesionURL,
  TipoIncidenciaURL,
  TiempoOcupacionURL,
  TipoIdentificacionURL,
  getCatAgee,
  getCatAgem,
  getCatAgel,
  getCatAgea,
  Parentesco,
  AseguradorasURL,
  DiscapacidadGradoURL,
  DiscapacidadOrigenURL,
  DiscapacidadTipoURL,
  UnidadesURL,
  EscuelasMedicinaURL,
  LadasURL,
  MCCategoriasURL,
  MCRangoEdadURL,
  AnimalesURL,
  EjerciciosURL,
  SitioMedicionURL,
  HospDuracionURL,
  SecuelasURL,
  Cie9URL,
  Cie10SexoURL,
  Cie10RangoURL,
  Cie10MaxURL,
  Cie10SexoPalabraURL,
  DiagnosticosGeneralesSexoURL,
  AntecedentesParentescoURL,
  AlergiasURL,
  InmunizacionesURL,
  VacunaNombreURL,
  VacunaDosisURL,
  PublicKeyURL,
  LaboratoriosURL,
  IdiomasMedicinaURL,
  AreaMedicinaURL,
  LaboratoriosResultadoURL,
  LaboratoriosUnidadesURL,
  getLaboratoriosUsuario,
  GenericoURL,
  PatenteURL,
  PacientesTablaURL,
  PacientesTablaBusquedaURL,
  PacientesAgendaBusquedaURL,
  CatFarmaFrecuenciaURL,
  CatFarmaPeriodoURL,
  CatFarmaUnidad,
  CatFarmaVia,
  ObtenerViasGenerico,
  ObtenerIndicationGenerico,
  ObtenerViasPatente,
  GenericoIDURL,
  FarmacologicoMedicamentosActuales,
  getCatalogoCie10Palabra,
  getCatalogoCie10Query,
  getMcpa,
  getCatalogoCie10SexoQuery,
  getCatalogoCie10QueryCategoria,
  getEnfermedadesListasEnfermedades,
  getEstablecimientosSalud,
  perfilMedicoObtenerConsultorios,
  perfilMedicoConsultoriosSimplificados,
  perfilMedicoObtenerConsultorio,
  perfilMedicoObtenerOrganizacion,
  perfilMedicoOrganizacionesSimple,
  perfilMedicoObtenerMe,
  getCatalogoCie10QueryCategoriaSexo,
  dictamenesMedLab,
  CategoriasExamenURL,
  TiposExamenURL,
  OcupacionesURL,
  DictamenesURL,
  RestriccionesURL,
  CausasNoAptitudURL,
  antecedentesFamiliaresObtenerListaFamiliares,
  perfilMedicoDatosIdentificacion,
  getEnfermedadesArrEnfermedadesReferidas,
  FarmacologicoMedicamentosAnteriores,
  FarmacologicoMedicamentosLista,
  ObtenerUnidadFormaGenerico,
  FarmacologicoRecetasMedicamentos,
  FarmacologicoRecetaMedicamento,
  consultasMedicasObtenerConsultaAbierta,
  consultasMedicasObtenerConsulta,
  getPerfilPacienteDatos,
  getPerfilPacienteDatosUsuario,
  getPerfilUsuarioPacienteDatos,
  // getPerfilUsuarioPacienteDatosEmail,
  TransfusionesPacienteURL,
  VerificarTokenPacienteURL,
  HospitalizacionesPacienteURL,
  consultasMedicasExistenciaPaciente,
  builderURLBuscarVariables,
  builderURLBuilderGetVariableMedico,
  builderURLBuilderGetVariableMedipraxi,
  builderURLObtenerFormatoMotivosTop,
  builderURLBuscarFormatoMotivosPropios,
  builderURLBuscarFormatoMotivosCompartidos,
  builderURLObtenerFormatoConsultaPropio,
  builderURLObtenerFormatoConsultaMedipraxi,
  builderURLBuilderVariablesTop,
  MCPAURLBuscar,
  builderURLBuscarFormatoMotivosMedipraxi,
  getPerfilPacienteDatosGenerales,
  getPerfilPacienteDatosUbicacion,
  getDetectionLungCancerURL,
  antecedentesNoPatologicosObtenerVivienda,
  antecedentesNoPatologicosObtenerHigiene,
  antecedentesNoPatologicosObtenerAlimentacion,
  antecedentesNoPatologicosObtenerHistoriaSexual,
  antecedentesNoPatologicosObtenerGinecoObstetricos,
  antecedentesNoPatologicosObtenerToxicomania,
  antecedentesNoPatologicosObtenerActividadFisica,
  getLaboratoriosDocumentos,
  getInformacionCustomerURL,
  getTableGloboriskURL,
  getTableGloboriskUsuarioURL,
  buscarVerificarSubscripcion,
  recetasTablaIni,
  consultasMedicasTabla,
  consultasMedicasTablaIni,
  getInformacionSubscriptionURL,
  getInformacionMetodosPagoURL,
  businessDoctorObtenerDatosURL,
  businessDoctorPacientesEdadSexoURL,
  businessDoctorPacientesDistribucionGeograficaURL,
  businessDoctorReporteEpidemiologiaURL,
  getValueMedicinaPreventivaURL,
  getRiesgoGloboriskURL,
  getRiesgoAAAURL,
  getAudiometriaURL,
  getExamenMedicoPeriodicoURL,
  getValueMedicinaPreventivaUsuarioURL,
  operationsDoctorPacientesCumpleURL,
  operationsDoctorObtenerDatosURL,
  QuimicosURL,
  getAntecedentesLaboralesSinAntecedentesURL,
  getListaAntecedentesLaboralesURL,
  getTableroPacienteEvaluacionMedidasURL,
  getTableroPacienteEvaluacionMedidasUsuarioURL,
  getColesterolPacienteURL,
  getColesterolUsuarioPacienteURL,
  getDiagnosticosTableroPacienteURL,
  getDiagnosticosTableroUsuarioPacienteURL,
  getMedicinaPreventivaTableroPacienteURL,
  getMedicinaPreventivaTableroUsuarioPacienteURL,
  getAntecedentesLaboralesURL,
  getAntecedentesLaboralesUsuarioPacienteURL,
  getTratamientosTableroPacienteURL,
  getTratamientosTableroUsuarioPacienteURL,
  getAntecedenteLaboralEmpresaURL,
  getAntecedenteLaboralEmpresaUsuarioPacienteURL,
  getTratamientoTableroPacienteURL,
  getTratamientoTableroUsuarioPacienteURL,
  CfdiURL,
  TipoEstablecimientoURL,
  TablaTipoRolesURL,
  TablaSeccionesURL,
  obtenerSAMiOrganizacionOrganizacionesURL,
  obtenerSAMiOrganizacionLicenciasURL,
  obtenerSAMiOrganizacionAdministradoresURL,
  obtenerSAMiOrganizacionValidacionDocIdURL,
  obtenerMiOrganizacionOrganizacionURL,
  obtenerMiOrganizacionAdministradorURL,
  obtenerMiOrganizacionConsultoriosURL,
  obtenerMiOrganizacionRolesURL,
  obtenerOrganizacionEquipoMedicoLicenciasURL,
  obtenerMiOrganizacionAltasBajasUsuarioURL,
  obtenerMiOrganizacionAltasBajasPacienteURL,
  obtenerMiOrganizacionNuevoTokenPacientesURL,
  getEditLaboratorio,
} from './commonUrl';
import { getToken } from './commonStore';

/*
 * Cuando un formulario de desmota teniendo un use effect en uso se genera el error:
 *   To fix, Can't perform a React state
 *   update on an unmounted component. This is a no-op,
 *   but it indicates a memory leak in your application.
 *   To fix, cancel all subscriptions and asynchronous
 *   tasks in a useEffect cleanup function.
 * Esto se debe a que la promesa tratará de setear el state del componente ya desmontado.
 * La mejor manera de solucionar esto es abortando la promesa, y para ello se utiliza
 * la api de ABORT CONTROLLER. Este crea un objeto usando el constructor AbortController(),
 * del cual obtenemos un signal que podemos utilizar para abortar la promesa del fetch.
 * https://developer.mozilla.org/en-US/docs/Web/API/AbortController
 */

async function getCatalogo(url: string, signal?: AbortSignal) {
  const response = await fetch(url, { headers: { Authorization: `Bearer ${getToken()}` }, signal });
  if (response.status === 200) {
    const data = await response.json();
    if (data.code === 200) {
      return data.datos;
    }
  }
  return [];
}

function getFetch(url: string, signal?: AbortSignal) {
  return fetch(url, { headers: { Authorization: `Bearer ${getToken()}` }, signal });
}

export function getPublicKeyFetch(signal: AbortSignal) {
  return getFetch(PublicKeyURL(), signal);
}

export async function getPublicKey(signal?: AbortSignal) {
  const response = await fetch(PublicKeyURL(), {
    headers: { Authorization: `Bearer ${getToken()}` },
    signal,
  });
  if (response.status === 200) {
    const data = await response.json();
    if (data.code === 200) {
      return data.key;
    }
    return '';
  }
  return '';
}

export function getEjercicios() {
  return getCatalogo(EjerciciosURL());
}

export function getAlergias() {
  return getFetch(AlergiasURL());
}

export function getCatalogoAlergias() {
  return getFetch(AlergiasURL());
}

export function getBusinessDoctorObtenerDatos(
  idMedico: number,
  idOrganizacion: number,
  idConsultorio: number,
  tipoPacientes: string,
  start: string,
  end: string,
  tipo: string,
  agee: string,
  agem: string,
  periodoMax: boolean,
) {
  return getFetch(businessDoctorObtenerDatosURL(idMedico, idOrganizacion, idConsultorio, tipoPacientes, start, end, tipo, agee, agem, periodoMax));
}

export function getBusinessDoctorPacientesEdadSexoAnio(
  idMedico: number,
  idConsultorio: number,
  tipoPacientes: string,
  start: string,
  end: string,
  tipo: string,
) {
  return getFetch(businessDoctorPacientesEdadSexoURL(idMedico, idConsultorio, tipoPacientes, start, end, tipo));
}

export function getBusinessDoctorPacientesDistribucionGeografica(
  idMedico: number,
  idOrganizacion: number,
  idConsultorio: number,
  tipoPacientes: string,
  start: string,
  end: string,
  agee: string,
  agem: string,
) {
  return getFetch(
    businessDoctorPacientesDistribucionGeograficaURL(
      idMedico,
      idOrganizacion,
      idConsultorio,
      tipoPacientes,
      start,
      end,
      agee,
      agem,
    ),
  );
}

export function getBusinessDoctorReporteEpidemiologia(
  idMedico: number,
  idOrganizacion: number,
  idConsultorio: number,
  tipoPacientes: string,
  fecha: string,
  edad: string,
  sexo: string,
  // agees: string,
  // agems: string,
  diagnosticos: string,
  ocupaciones: string,
) {
  return getFetch(
    businessDoctorReporteEpidemiologiaURL(
      idMedico,
      idOrganizacion,
      idConsultorio,
      tipoPacientes,
      fecha,
      edad,
      sexo,
      // agees,
      // agems,
      diagnosticos,
      ocupaciones,
    ),
  );
}

export function getOperationsDoctorObtenerDatos(idMedico: number, idConsultorio: number, tipoPacientes: string) {
  return getFetch(operationsDoctorObtenerDatosURL(idMedico, idConsultorio, tipoPacientes));
}

export function getOperationsDoctorPacientesCumple(
  idMedico: number,
  idConsultorio: number,
  tipoPacientes: string,
) {
  return getFetch(operationsDoctorPacientesCumpleURL(idMedico, idConsultorio, tipoPacientes));
}

export function getCatalogoGrupoEtnico() {
  return getCatalogo(GrupoEtnicoURL());
}

export function getCatalogoNacionalidad() {
  return getCatalogo(NacionalidadURL());
}

export function getCatalogoTipoIdentificacion() {
  return getCatalogo(TipoIdentificacionURL());
}

export function getCatalogoEscolaridad() {
  return getCatalogo(EscolaridadURL());
}

export function getCatalogoTipoOcupacion() {
  return getCatalogo(TipoOcupacionURL());
}

export function getCatalogoCausaAccidente() {
  return getCatalogo(CausaAccidenteURL());
}

export function getCatalogoNaturalezaLesion() {
  return getCatalogo(NaturalezaLesionURL());
}

export function getCatalogoTipoIncidencia() {
  return getCatalogo(TipoIncidenciaURL());
}

export function getCatalogoQuimicos() {
  return getCatalogo(QuimicosURL());
}

export function getCatalogoTiempoOcupacion() {
  return getCatalogo(TiempoOcupacionURL());
}

export function getCatalogoReligion() {
  return getCatalogo(ReligionURL());
}

export function getCatalogoEstadoCivil() {
  return getCatalogo(EstadoCivilURL());
}

export function getCatalogoAgee(idMedico: number) {
  return getCatalogo(getCatAgee(idMedico));
}

export function getCatalogoCfdi() {
  return getCatalogo(CfdiURL());
}

export function getFethCatalogoAgee(idMedico: number, signal?: AbortSignal) {
  return getCatalogo(getCatAgee(idMedico), signal);
}

export function getCatalogoAgem(idAgee: string, signal?: AbortSignal) {
  return getCatalogo(getCatAgem(idAgee), signal);
}

export function getCatalogoAgel(idAgee: string, idAgem: string, signal?: AbortSignal) {
  return getCatalogo(getCatAgel(idAgee, idAgem), signal);
}

export function getCatalogoAgea(
  idAgee: string,
  idAgem: string,
  idAgel: string,
  signal?: AbortSignal,
) {
  return getCatalogo(getCatAgea(idAgee, idAgem, idAgel), signal);
}

export function getCatalogoParentesco() {
  return getCatalogo(Parentesco());
}

export function getCatalogoAseguradoras(agep: string) {
  return getCatalogo(AseguradorasURL(agep));
}

export function getCatalogoDiscapacidadTipo() {
  return getCatalogo(DiscapacidadTipoURL());
}

export function getCatalogoDiscapacidadOrigen() {
  return getCatalogo(DiscapacidadOrigenURL());
}

export function getCatalogoDiscapacidadGrado() {
  return getCatalogo(DiscapacidadGradoURL());
}

export function getCatalogoUnidades() {
  return getCatalogo(UnidadesURL());
}

export function getCatalogoEscuelaMedicina(idMedico: number) {
  return getCatalogo(EscuelasMedicinaURL(idMedico));
}

export function getCatalogoEspecialiadadMedicina() {
  return getCatalogo(EspecialidadMedicinaURL());
}

export function getCatalogoAreaMedicina() {
  return getCatalogo(AreaMedicinaURL());
}

export function getCatalogoIdiomasMedicina() {
  return getCatalogo(IdiomasMedicinaURL());
}

export function getCatalogoLadas() {
  return getCatalogo(LadasURL());
}

export function getCatalogoCategoriasMC() {
  return getCatalogo(MCCategoriasURL());
}

export function getCatalogoLaboratorios() {
  return getCatalogo(LaboratoriosURL());
}

export function getCatalogoLaboratoriosResultado() {
  return getCatalogo(LaboratoriosResultadoURL());
}

export function getCatalogoLaboratoriosUnidades() {
  return getCatalogo(LaboratoriosUnidadesURL());
}

export function getCatalogoSecuelas() {
  return getCatalogo(SecuelasURL());
}

export function getCatalogoRangoEdadMC() {
  return getCatalogo(MCRangoEdadURL());
}

export function getCatLaboratoriosPaciente(
  idMedico: number,
  idPaciente: number,
  idConsultorio: number,
) {
  return getCatalogo(getLaboratoriosUsuario(idMedico, idPaciente, idConsultorio));
}

export function getLaboratorio(
  idPaciente: number,
  idLaboratorio: string,
  idMedico: number,
  idConsultorio: number,
) {
  return getFetch(getEditLaboratorio(idPaciente, idLaboratorio, idMedico, idConsultorio));
}

export function getAnimales() {
  return getCatalogo(AnimalesURL());
}

export function getCatHospDuracion() {
  return getCatalogo(HospDuracionURL());
}

export function getCatCie9(id: string, sexo: string) {
  return getCatalogo(Cie9URL(id, sexo));
}

export function getCatCie10Sexo(sexo: string) {
  return getCatalogo(Cie10SexoURL(sexo));
}

export function getCatCie10SexoPalabra(sexo: string, palabra: string) {
  return getCatalogo(Cie10SexoPalabraURL(sexo, palabra));
}

export function getCatCie10Rango(sexo: string, selector: string, numero: number) {
  return getCatalogo(Cie10RangoURL(sexo, selector, numero));
}

export function getCatCie10Max(sexo: string, selector: string, numero: number) {
  return getCatalogo(Cie10MaxURL(sexo, selector, numero));
}

export function getCatDiagnosticosGeneralesSexo(sexo: string) {
  return getCatalogo(DiagnosticosGeneralesSexoURL(sexo));
}

export function getInmunizaciones(
  id: string,
  edad: string,
  idMedico: string,
  idConsulta: string,
  idConsultorio: number,
) {
  return getCatalogo(InmunizacionesURL(id, edad, idMedico, idConsulta, idConsultorio));
}

export function getCatVacunaNombre() {
  return getCatalogo(VacunaNombreURL());
}

export function getCatVacunaDosis() {
  return getCatalogo(VacunaDosisURL());
}

export function getSitioMedicion() {
  return getCatalogo(SitioMedicionURL());
}

export function getDataMCPA(
  idMedico: number,
  idPaciente: number,
  idConsulta: number,
  idConsultorio: number,
) {
  return getFetch(getMcpa(idMedico, idPaciente, idConsulta, idConsultorio));
}

export function getSAOrganizacionOrganizaciones(idMedico: number, idOrganizacion: number) {
  return getFetch(obtenerSAMiOrganizacionOrganizacionesURL(idMedico, idOrganizacion));
}

export function getSAOrganizacionLicencias(
  idMedico: number,
  emailSend: string,
  idOrganizacion: number,
) {
  return getFetch(obtenerSAMiOrganizacionLicenciasURL(idMedico, emailSend, idOrganizacion));
}

export function getSAMiOrganizacionAdministradores(idMedico: number, idOrganizacion: number) {
  return getFetch(obtenerSAMiOrganizacionAdministradoresURL(idMedico, idOrganizacion));
}

export function getSAMiOrganizacionValidacionDocId(
  idMedico: number,
  idOrganizacion: number,
  docId: string,
) {
  return getFetch(obtenerSAMiOrganizacionValidacionDocIdURL(idMedico, idOrganizacion, docId));
}

export function getOrganizacionConsultorios(idMedico: number, idOrganizacion: number) {
  return getFetch(obtenerMiOrganizacionConsultoriosURL(idMedico, idOrganizacion));
}

export function getOrganizacionRoles(idMedico: number, idOrganizacion: number) {
  return getFetch(obtenerMiOrganizacionRolesURL(idMedico, idOrganizacion));
}

export function getOrganizacionEquipoMedicoLicencias(
  idMedico: number,
  idOrganizacion: number,
  emailSend: string,
) {
  return getFetch(obtenerOrganizacionEquipoMedicoLicenciasURL(idMedico, idOrganizacion, emailSend));
}

export function getCatalogoTipoEstablecimiento() {
  return getCatalogo(TipoEstablecimientoURL());
}

export function getCatalogoTablaTipoRoles() {
  return getCatalogo(TablaTipoRolesURL());
}

export function getCatalogoTablaSecciones() {
  return getCatalogo(TablaSeccionesURL());
}

export function getCatFarmacologicoGenerico(
  idMedico: number,
  searchTerm: string,
  signal: AbortSignal,
) {
  return getFetch(GenericoURL(idMedico, searchTerm), signal);
}

export function getFarmacologicoGenerico(idMedico: number, id: number) {
  return getFetch(GenericoIDURL(idMedico, id));
}

export function getCatFarmacologicoPatente(
  idMedico: number,
  searchTerm: string,
  signal: AbortSignal,
) {
  return getFetch(PatenteURL(idMedico, searchTerm), signal);
}

export function getDictamenMedLab(
  idPaciente: number,
  idMedico: number,
  idOrganizacion: number,
  idConsulta: number,
) {
  return getFetch(dictamenesMedLab(idPaciente, idMedico, idOrganizacion, idConsulta));
}

export function getCatalogoCategoriaExamen() {
  return getFetch(CategoriasExamenURL());
}

export function getCatalogoTipoExamen(idOrganizacion: number) {
  return getFetch(TiposExamenURL(idOrganizacion));
}

export function getCatalogoOcupacion(idOrganizacion: number) {
  return getFetch(OcupacionesURL(idOrganizacion));
}

export function getCatalogoDictamen() {
  return getFetch(DictamenesURL());
}

export function getCatalogoRestricciones(idOrganizacion: number) {
  return getFetch(RestriccionesURL(idOrganizacion));
}

export function getCatalogoCausasNoAptitud(idOrganizacion: number) {
  return getFetch(CausasNoAptitudURL(idOrganizacion));
}

export function getPacientesTabla(
  idMedico: number,
  idOrganizacion: number,
  idConsultorio: number,
  tablaInicio: number,
  ordenarPor: string,
) {
  return getFetch(
    PacientesTablaURL(idMedico, idOrganizacion, idConsultorio, tablaInicio, ordenarPor),
  );
}

export function getTableroPacienteEvaluacionMedidas(
  idMedico: number,
  idPaciente: number,
  idUsuario: number,
) {
  return getFetch(getTableroPacienteEvaluacionMedidasURL(idMedico, idPaciente, idUsuario));
}

export function getTableroPacienteEvaluacionMedidasUsuario(idPaciente: number, idUsuario: number) {
  return getFetch(getTableroPacienteEvaluacionMedidasUsuarioURL(idPaciente, idUsuario));
}

export function getColesterolPaciente(idMedico: number, idPaciente: number) {
  return getFetch(getColesterolPacienteURL(idMedico, idPaciente));
}

export function getColesterolUsuarioPaciente(idPaciente: number) {
  return getFetch(getColesterolUsuarioPacienteURL(idPaciente));
}

export function getMiOrganizacionOrganizacion(idMedico: number, idOrganizacion: number) {
  return getFetch(obtenerMiOrganizacionOrganizacionURL(idMedico, idOrganizacion));
}

export function getMiOrganizacionAdministrador(idMedico: number, idOrganizacion: number) {
  return getFetch(obtenerMiOrganizacionAdministradorURL(idMedico, idOrganizacion));
}

export function getMiOrganizacionAltasBajasUsuario(idMedico: number, idOrganizacion: number) {
  return getFetch(obtenerMiOrganizacionAltasBajasUsuarioURL(idMedico, idOrganizacion));
}

export function getMiOrganizacionAltasBajasPaciente(idMedico: number, idOrganizacion: number) {
  return getFetch(obtenerMiOrganizacionAltasBajasPacienteURL(idMedico, idOrganizacion));
}

export function getMiOrganizacionNuevoTokenPacientes(
  idMedico: number,
  idOrganizacion: number,
  tipo: string,
) {
  return getFetch(obtenerMiOrganizacionNuevoTokenPacientesURL(idMedico, idOrganizacion, tipo));
}

export function getDiagnosticosTableroPaciente(idMedico: number, idPaciente: number) {
  return getFetch(getDiagnosticosTableroPacienteURL(idMedico, idPaciente));
}

export function getDiagnosticosTableroUsuarioPaciente(idPaciente: number, idUsuario: number) {
  return getFetch(getDiagnosticosTableroUsuarioPacienteURL(idPaciente, idUsuario));
}

export function getMedicinaPreventivaTableroPaciente(
  idMedico: number,
  idPaciente: number,
  idUsuario: number,
) {
  return getFetch(getMedicinaPreventivaTableroPacienteURL(idMedico, idPaciente, idUsuario));
}

export function getMedicinaPreventivaTableroUsuarioPaciente(idPaciente: number, idUsuario: number) {
  return getFetch(getMedicinaPreventivaTableroUsuarioPacienteURL(idPaciente, idUsuario));
}

export function getAntecedentesLaborales(idMedico: number, idPaciente: number) {
  return getFetch(getAntecedentesLaboralesURL(idMedico, idPaciente));
}

export function getAntecedentesLaboralesUsuarioPaciente(idPaciente: number) {
  return getFetch(getAntecedentesLaboralesUsuarioPacienteURL(idPaciente));
}

export function getTratamientosTableroPaciente(idMedico: number, idPaciente: number) {
  return getFetch(getTratamientosTableroPacienteURL(idMedico, idPaciente));
}

export function getTratamientosTableroUsuarioPaciente(idPaciente: number) {
  return getFetch(getTratamientosTableroUsuarioPacienteURL(idPaciente));
}

export function getAntecedenteLaboralEmpresa(
  idMedico: number,
  idPaciente: number,
  idEmpresa: number,
) {
  return getFetch(getAntecedenteLaboralEmpresaURL(idMedico, idPaciente, idEmpresa));
}

export function getAntecedenteLaboralEmpresaUsuarioPaciente(idPaciente: number, idEmpresa: number) {
  return getFetch(getAntecedenteLaboralEmpresaUsuarioPacienteURL(idPaciente, idEmpresa));
}

export function getTratamientoTableroPaciente(
  idMedico: number,
  idPaciente: number,
  idMedicamento: number,
) {
  return getFetch(getTratamientoTableroPacienteURL(idMedico, idPaciente, idMedicamento));
}

export function getTratamientoTableroUsuarioPaciente(idPaciente: number, idMedicamento: number) {
  return getFetch(getTratamientoTableroUsuarioPacienteURL(idPaciente, idMedicamento));
}

export function getPacientesTablaBusqueda(
  idMedico: number,
  idOrganizacion: number,
  idConsultorio: number,
  nombreString: string,
  ordenarPor: string,
) {
  return getFetch(
    PacientesTablaBusquedaURL(idMedico, idOrganizacion, idConsultorio, nombreString, ordenarPor),
  );
}

export function getPacientesAgendaBusqueda(
  idMedico: number,
  idOrganizacion: number,
  idConsultorio: number,
  nombreString: string,
  signal: AbortSignal,
) {
  return getFetch(
    PacientesAgendaBusquedaURL(idMedico, idOrganizacion, idConsultorio, nombreString),
    signal,
  );
}

export function getCatFarmacologicoPeriodo(signal: AbortSignal) {
  return getFetch(CatFarmaPeriodoURL(), signal);
}

export function getCatFarmacologicoFrecuencia(signal: AbortSignal) {
  return getFetch(CatFarmaFrecuenciaURL(), signal);
}

export function getCatFarmacologicoUnidad() {
  return getFetch(CatFarmaUnidad());
}

export function getCatFarmacologicoVia() {
  return getFetch(CatFarmaVia());
}

export function getFarmacologicoViasGenerico(idMedico: number, id: number, signal: AbortSignal) {
  return getFetch(ObtenerViasGenerico(idMedico, id), signal);
}

export function getFarmacologicoUnidadFormaGenerico(
  idMedico: number,
  id: number,
  signal: AbortSignal,
) {
  return getFetch(ObtenerUnidadFormaGenerico(idMedico, id), signal);
}

export function getFarmacologicoViasPatente(idMedico: number, id: number, signal: AbortSignal) {
  return getFetch(ObtenerViasPatente(idMedico, id), signal);
}

export function getFarmacologicoIndicationGenerico(id: number) {
  return getFetch(ObtenerIndicationGenerico(id));
}

export function getDataTablaGloborisk(
  sexo: number,
  diabetico: number,
  fumador: number,
  edad: number,
  conColesterol: boolean,
  idMedico: number,
  idPaciente: number,
  idConsultorio: number | null,
) {
  return getFetch(
    getTableGloboriskURL(
      sexo,
      diabetico,
      fumador,
      edad,
      conColesterol,
      idMedico,
      idPaciente,
      idConsultorio || null,
    ),
  );
}

export function getDataTablaGloboriskUsuario(
  sexo: number,
  fumador: number,
  edad: number,
  idPaciente: number,
) {
  return getFetch(getTableGloboriskUsuarioURL(sexo, fumador, edad, idPaciente));
}

export function getRiesgoGloborisk(
  sexo: number,
  diabetico: number,
  fumador: number,
  presionArterial: number,
  colesterol: number,
  edad: number,
  idMedico: number,
  idPaciente: number,
  idConsultorio: number,
) {
  return getFetch(
    getRiesgoGloboriskURL(
      sexo,
      diabetico,
      fumador,
      presionArterial,
      colesterol,
      edad,
      idMedico,
      idPaciente,
      idConsultorio,
    ),
  );
}

export function getRiesgoAAA(idMedico: number, idPaciente: number, idConsultorio: number) {
  return getFetch(getRiesgoAAAURL(idMedico, idPaciente, idConsultorio));
}

export function getAudiometria(idMedico: number, idPaciente: number, idConsultorio: number) {
  return getFetch(getAudiometriaURL(idMedico, idPaciente, idConsultorio));
}

export function getExamenMedicoPeriodico(
  idMedico: number,
  idPaciente: number,
  idConsultorio: number,
) {
  return getFetch(getExamenMedicoPeriodicoURL(idMedico, idPaciente, idConsultorio));
}

export function getListaMedicamentosActuales(
  idPaciente: number,
  idMedico: number,
  idConsultorio: number,
) {
  return getFetch(FarmacologicoMedicamentosActuales(idPaciente, idMedico, idConsultorio));
}

export function getListaMedicamentosAnteriores(idPaciente: number) {
  return getFetch(FarmacologicoMedicamentosAnteriores(idPaciente));
}

export function getListaMedicametosLista(
  idMedico: number,
  idPaciente: number,
  idConsultorio: number,
) {
  return getFetch(FarmacologicoMedicamentosLista(idMedico, idPaciente, idConsultorio));
}

export async function getCatalogoReligionQuery(query: string, token: string) {
  const response = await fetch(`${ReligionURL()}?q=${query}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  if (response.status === 200) {
    const data = await response.json();
    return data.datos;
  }
  return [];
}

export function getAntecedentesParentesco(signal: AbortSignal) {
  return getCatalogo(AntecedentesParentescoURL(), signal);
}

export function getCatCie10Palabra(inputCIE10: string, signal: AbortSignal) {
  return getFetch(getCatalogoCie10Palabra(inputCIE10), signal);
}

export function getCatCie10Query(
  inputCIE10: string,
  sexo: string,
  periodoEdad: string,
  edad: number,
  signal: AbortSignal,
) {
  return getFetch(getCatalogoCie10Query(inputCIE10, sexo, periodoEdad, edad), signal);
}

export function getCatCie10SexoQuery(inputCIE10: string, sexo: string, signal: AbortSignal) {
  return getFetch(getCatalogoCie10SexoQuery(inputCIE10, sexo), signal);
}

export function getCatCie10QueryCategoria(
  idPaciente: number,
  inputCIE10: string,
  sexo: string,
  periodoEdad: string,
  edad: number,
  signal: AbortSignal,
) {
  return getFetch(
    getCatalogoCie10QueryCategoria(idPaciente, inputCIE10, sexo, periodoEdad, edad),
    signal,
  );
}

export function getCatCie10QueryCategoriaSexo(
  inputCIE10: string,
  sexo: string,
  signal: AbortSignal,
) {
  return getFetch(getCatalogoCie10QueryCategoriaSexo(inputCIE10, sexo), signal);
}

export function getEnfermedadesListaEnfermedades(
  idPaciente: number,
  idMedico: number,
  idConsultorio: number,
) {
  return getFetch(getEnfermedadesListasEnfermedades(idPaciente, idMedico, idConsultorio));
}

export function getCatEstablecimientoSalud(agee: string, agem: string) {
  return getFetch(getEstablecimientosSalud(agee, agem));
}

export function getPerfilMedicoConsultorios(
  idMedico: number,
  idOrganizacion: number,
  signal?: AbortSignal,
) {
  return getFetch(perfilMedicoObtenerConsultorios(idMedico, idOrganizacion), signal);
}

export function getPacienteUbicacion(idMedico: number, idPaciente: number, idConsultorio: number) {
  return getFetch(getPerfilPacienteDatosUbicacion(idMedico, idPaciente, idConsultorio));
}

export function getPerfilMedicoConsultorio(idMedico: number, idConsultorio: number) {
  return getFetch(perfilMedicoObtenerConsultorio(idMedico, idConsultorio));
}

export function getPerfilMedicoConsultoriosSimple(idMedico: number, idOrganizacion: number) {
  return getFetch(perfilMedicoConsultoriosSimplificados(idMedico, idOrganizacion));
}

export function getPerfilMedicoOrganizacion(idMedico: number, idOrganizacion: number) {
  return getFetch(perfilMedicoObtenerOrganizacion(idMedico, idOrganizacion));
}

export function getPerfilMedicoOrganizacionesSimple(idMedico: number) {
  return getFetch(perfilMedicoOrganizacionesSimple(idMedico));
}

export function getPerfilMedicoMe(email: string) {
  return getFetch(perfilMedicoObtenerMe(email));
}

export function getPerfilMedicoDatosId(email: string) {
  return getFetch(perfilMedicoDatosIdentificacion(email));
}

export function getAntecedentesFamiliaresListaFamiliares(
  idPaciente: number,
  idMedico: number,
  idConsultorio: number,
) {
  return getFetch(
    antecedentesFamiliaresObtenerListaFamiliares(idPaciente, idMedico, idConsultorio),
  );
}

export function getEnfermedadesListaEnfermedadesReferidas(
  idPaciente: number,
  idMedico: number,
  idConsultorio: number,
) {
  return getFetch(getEnfermedadesArrEnfermedadesReferidas(idPaciente, idMedico, idConsultorio));
}

export function getFarmacologicoRecetasMedicamentos(
  idConsulta: number,
  idPaciente: number,
  idMedico: number,
  idConsultorio: number,
) {
  return getFetch(
    FarmacologicoRecetasMedicamentos(idConsulta, idPaciente, idMedico, idConsultorio),
  );
}

export function getFarmacologicoRecetaMedicamento(
  idReceta: number,
  idMedico: number,
  idPaciente: number,
  idConsultorio: number,
) {
  return getFetch(FarmacologicoRecetaMedicamento(idReceta, idMedico, idPaciente, idConsultorio));
}

export function getConsultasObtenerConsultaAbierta(idMedico: number, idConsultorio: number) {
  return getFetch(consultasMedicasObtenerConsultaAbierta(idMedico, idConsultorio));
}

export function getConsultasObtenerConsulta(
  idConsulta: number,
  idMedico: number,
  idPaciente: number,
  idConsultorio: number,
) {
  return getFetch(consultasMedicasObtenerConsulta(idConsulta, idMedico, idPaciente, idConsultorio));
}

export function getListaTransfusiones(idMedico: number, idPaciente: number, idConsultorio: number) {
  return getFetch(TransfusionesPacienteURL(idMedico, idPaciente, idConsultorio));
}

export function getListaHospitalizaciones(
  idMedico: number,
  idPaciente: number,
  idConsultorio: number,
) {
  return getFetch(HospitalizacionesPacienteURL(idMedico, idPaciente, idConsultorio));
}

export function getListaVariablesBuilder(idMedico: number, busqueda: string) {
  return getFetch(builderURLBuscarVariables(idMedico, busqueda));
}

export function getVariableBuilderMedico(idMedico: number, idVariable: number) {
  return getFetch(builderURLBuilderGetVariableMedico(idMedico, idVariable));
}

export function getVariableBuilderMedipraxi(idMedico: number, idVariable: number) {
  return getFetch(builderURLBuilderGetVariableMedipraxi(idMedico, idVariable));
}

export function getBuilderFormatosConsultaTop(idMedico: number) {
  return getFetch(builderURLObtenerFormatoMotivosTop(idMedico));
}

export function getBuilderFormatosConsultaMedipraxi(
  idMedico: number,
  motivoNombre: string,
  motivoCategoria: number,
  motivoRango: number,
  motivoSexo: number,
  motivoConsulta: number,
  pagina: number,
) {
  return getFetch(
    builderURLBuscarFormatoMotivosMedipraxi(
      idMedico,
      motivoNombre,
      motivoCategoria,
      motivoRango,
      motivoSexo,
      motivoConsulta,
      pagina,
    ),
  );
}

export function getBuilderFormatosConsultaPropios(
  idMedico: number,
  motivoNombre: string,
  motivoCategoria: number,
  motivoRango: number,
  motivoSexo: number,
  motivoConsulta: number,
  pagina: number,
) {
  return getFetch(
    builderURLBuscarFormatoMotivosPropios(
      idMedico,
      motivoNombre,
      motivoCategoria,
      motivoRango,
      motivoSexo,
      motivoConsulta,
      pagina,
    ),
  );
}

export function getBuilderFormatosConsultaCompartidos(
  idMedico: number,
  idOrganizacion: number,
  motivoNombre: string,
  motivoCategoria: number,
  motivoRango: number,
  motivoSexo: number,
  motivoConsulta: number,
  pagina: number,
) {
  return getFetch(
    builderURLBuscarFormatoMotivosCompartidos(
      idMedico,
      idOrganizacion,
      motivoNombre,
      motivoCategoria,
      motivoRango,
      motivoSexo,
      motivoConsulta,
      pagina,
    ),
  );
}

export function getExistenciaPaciente(
  idMedico: number,
  idConsultorio: number,
  idOrganizacion: number,
  docId: string,
) {
  return getFetch(
    consultasMedicasExistenciaPaciente(idMedico, idConsultorio, idOrganizacion, docId),
  );
}

export function verificarTokenPaciente(
  idMedico: number,
  docId: string,
  acceso: string,
  idConsultorio: string,
  idOrganizacion: string,
) {
  return getFetch(
    VerificarTokenPacienteURL(idMedico, docId, idConsultorio, idOrganizacion, acceso),
  );
}

export function getBuilderFormatoConsultaPropio(idFormato: number, idMedico: number) {
  return getFetch(builderURLObtenerFormatoConsultaPropio(idFormato, idMedico));
}

export function getBuilderFormatoConsultaMedipraxi(idFormato: number, idMedico: number) {
  return getFetch(builderURLObtenerFormatoConsultaMedipraxi(idFormato, idMedico));
}

export function getListaVariablesTopMedico(idMedico: number) {
  return getFetch(builderURLBuilderVariablesTop(idMedico));
}

export function getMCPABusquedaListaMC(
  tipoConsulta: number,
  categoria: number,
  sexo: number,
  rangoEdad: number,
  idMedico: number,
  idOrganizacion: number,
) {
  return getFetch(
    MCPAURLBuscar(tipoConsulta, categoria, sexo, rangoEdad, idMedico, idOrganizacion),
  );
}

export function getTablaRecetasPDFIni(
  idMedico: number,
  idPaciente: number,
  idConsultorio: number,
  idOrganizacion: number,
) {
  return getFetch(recetasTablaIni(idMedico, idPaciente, idConsultorio, idOrganizacion));
}

export function getTablaConsultasPDF(
  idMedico: number,
  idPaciente: number,
  idConsultorio: number,
  idOrganizacion: number,
  fechaIni: string,
  fechaFin: string,
) {
  return getFetch(
    consultasMedicasTabla(idMedico, idPaciente, idConsultorio, idOrganizacion, fechaIni, fechaFin),
  );
}

export function getTablaConsultasPDFIni(
  idMedico: number,
  idPaciente: number,
  idConsultorio: number,
  idOrganizacion: number,
) {
  return getFetch(consultasMedicasTablaIni(idMedico, idPaciente, idConsultorio, idOrganizacion));
}

export function getDatosPaciente(
  idMedico: number,
  idPaciente: number,
  idConsultorio: number,
  idConsulta: number,
) {
  return getFetch(getPerfilPacienteDatos(idMedico, idPaciente, idConsultorio, idConsulta));
}

export function getPacienteDatosUsuario(idMedico: number, idPaciente: number, idUsuario: number) {
  return getFetch(getPerfilPacienteDatosUsuario(idMedico, idPaciente, idUsuario));
}

export function getDatosUsuarioPaciente(idUsuario: number) {
  return getFetch(getPerfilUsuarioPacienteDatos(idUsuario));
}

// export function getDatosUsuarioPacienteEmail(email: string) {
//   return getFetch(getPerfilUsuarioPacienteDatosEmail(email));
// }

export function getPacienteDatosPersonales(
  idPaciente: number,
  idMedico: number,
  idConsulta: number,
) {
  return getFetch(getPerfilPacienteDatosGenerales(idMedico, idPaciente, idConsulta));
}

export function getValueMedicinaPreventiva(
  idPaciente: number,
  idConsulta: number,
  idMedico: number,
  idConsultorio: number,
) {
  return getFetch(getValueMedicinaPreventivaURL(idPaciente, idConsulta, idMedico, idConsultorio));
}

export function getDetectionLungCancer(
  idMedico: number,
  idPaciente: number,
  idConsultorio: number,
) {
  return getFetch(getDetectionLungCancerURL(idMedico, idPaciente, idConsultorio));
}

export function getValueMedicinaPreventivaUsuario(idUsuario: number) {
  return getFetch(getValueMedicinaPreventivaUsuarioURL(idUsuario));
}

export function getAntecedentesNoPatologicosVivienda(
  idMedico: number,
  idPaciente: number,
  idConsultorio: number,
) {
  return getFetch(antecedentesNoPatologicosObtenerVivienda(idMedico, idPaciente, idConsultorio));
}
export function getAntecedentesNoPatologicosHigiene(
  idMedico: number,
  idPaciente: number,
  idConsultorio: number,
) {
  return getFetch(antecedentesNoPatologicosObtenerHigiene(idMedico, idPaciente, idConsultorio));
}
export function getAntecedentesNoPatologicosAlimentacion(
  idMedico: number,
  idPaciente: number,
  idConsultorio: number,
) {
  return getFetch(
    antecedentesNoPatologicosObtenerAlimentacion(idMedico, idPaciente, idConsultorio),
  );
}
export function getAntecedentesNoPatologicosActividadFisica(
  idMedico: number,
  idPaciente: number,
  idConsultorio: number,
) {
  return getFetch(
    antecedentesNoPatologicosObtenerActividadFisica(idMedico, idPaciente, idConsultorio),
  );
}
export function getAntecedentesNoPatologicosToxicomania(
  idMedico: number,
  idPaciente: number,
  idConsultorio: number,
) {
  return getFetch(antecedentesNoPatologicosObtenerToxicomania(idMedico, idPaciente, idConsultorio));
}
export function getSinAntecedentesLaborales(idPaciente: number, idMedico: number) {
  return getFetch(getAntecedentesLaboralesSinAntecedentesURL(idPaciente, idMedico));
}
export function getListaAntecedentesLaborales(idPaciente: number, idMedico: number) {
  return getCatalogo(getListaAntecedentesLaboralesURL(idPaciente, idMedico));
}
export function getAntecedentesNoPatologicosGinecoObstetricos(
  idMedico: number,
  idPaciente: number,
  idConsultorio: number,
  idConsulta: number,
) {
  return getFetch(
    antecedentesNoPatologicosObtenerGinecoObstetricos(
      idMedico,
      idPaciente,
      idConsultorio,
      idConsulta,
    ),
  );
}
export function getAntecedentesNoPatologicosHistoriaSexual(
  idMedico: number,
  idPaciente: number,
  idConsultorio: number,
  idConsulta: number,
) {
  return getFetch(
    antecedentesNoPatologicosObtenerHistoriaSexual(idMedico, idPaciente, idConsultorio, idConsulta),
  );
}

export function getPlanLaboratorioArchivos(
  idPaciente: number,
  idMedico: number,
  idConsultorio: number,
  pagina: number,
) {
  return getFetch(getLaboratoriosDocumentos(idPaciente, idMedico, idConsultorio, pagina));
}

// aqui quiza poner el id del medico? o su correo?
export function getInfoCustomer(customer: string) {
  return getFetch(getInformacionCustomerURL(customer));
}

export function getInfoSubscription(customer: string) {
  return getFetch(getInformacionSubscriptionURL(customer));
}

export function getInfoMetodosPago(customer: string) {
  return getFetch(getInformacionMetodosPagoURL(customer));
}

export function verificarSubscripcionConekta(email: string, customer: string) {
  return getFetch(buscarVerificarSubscripcion(email, customer));
}
