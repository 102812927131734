/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDrop } from 'react-dnd';
import { useTranslation } from 'react-i18next';
import MedicamentoActual from '../MedicamentoActual';
import MedicamentoReceta from '../MedicamentoReceta';
import { IItemType, IListaMedicamentosProps, ItemTypes } from './types';
import TabRecetas from './TabsRecetas';
import VerDetalleMedicamento from './VerDetalleMedicamento';
import ConfirmDialog from '../../../../../../components/ConfirmDialog';
import { IListaRecetas, IMedicamento, medicamentoInitial } from '../types';

const renderMedicamentoActual = (
  medicamento: IMedicamento,
  index: number,
  onDropMedicamento: (item: IItemType) => void,
  tipoMedicamento: string,
  handleEliminarMedicamento: (id: number) => void,
  handleEditarMedicamento: (medicamento: IMedicamento) => void,
  handleVerDetalleMedicamento: (medicamento: IMedicamento) => void,
) => (
  <MedicamentoActual
    key={index}
    medicamento={medicamento}
    index={index}
    onDropMedicamento={onDropMedicamento}
    tipoMedicamento={tipoMedicamento}
    handleEliminarMedicamento={handleEliminarMedicamento}
    handleEditarMedicamento={handleEditarMedicamento}
    handleVerDetalleMedicamento={handleVerDetalleMedicamento}
  />
);

const renderMedicamentoReceta = (
  medicamento: IMedicamento,
  index: number,
  onDropMedicamento: (item: IItemType) => void,
  tipoMedicamento: string,
  onMoveMedicamento: (dragIndex: number, hoverIndex: number) => void,
  handleQuitarMedicamento: (id: number) => void,
  handleEditarMedicamento: (medicamento: IMedicamento) => void,
  handleVerDetalleMedicamento: (medicamento: IMedicamento) => void,
) => (
  <MedicamentoReceta
    key={medicamento.id}
    medicamento={medicamento}
    index={index}
    onDropMedicamento={onDropMedicamento}
    tipoMedicamento={tipoMedicamento}
    onMoveMedicamento={onMoveMedicamento}
    handleQuitarMedicamento={handleQuitarMedicamento}
    handleEditarMedicamento={handleEditarMedicamento}
    handleVerDetalleMedicamento={handleVerDetalleMedicamento}
  />
);

function ListaMedicamentos({
  tratamientosActualesConsulta,
  setTratamientos,
  recetas,
  setRecetas,
  indiceReceta,
  setIndiceReceta,
  setOpenMEMR,
  setIDMedicamentoEliminar,
  setOpenMQMR,
  setIDMedicamentoQuitar,
  setOpenModalEditar,
  setMedicamentoAEditar,
}: IListaMedicamentosProps) {
  const { t } = useTranslation();
  const [confirmOpen, setConfirmOpen] = useState<boolean>(false);
  const [openDetalle, setOpenDetalle] = useState<boolean>(false);
  const [medicamentoAVerDetalle, setMedicamentoAVerDetalle] = useState<IMedicamento>(medicamentoInitial);

  const [{ canDrop, isOver }, dropMedicamento] = useDrop({
    accept: ItemTypes.MEDICAMENTO_LISTA,
    drop: () => ({ name: 'medicamento' }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const onDropMedicamento = useCallback(
    (item: IItemType) => {
      if (item && item.type === 'medicamento_lista') {
        if (tratamientosActualesConsulta[item.index].idReceta > 0) {
          setConfirmOpen(true);
          return;
        }
        setRecetas((prev) => prev.map((receta: IListaRecetas, inx: number) => (
          inx === indiceReceta
            ? { ...receta, actualizado: true }
            : receta
        )));
        setTratamientos((prev) => prev.map((med: IMedicamento) => (
          med.id === tratamientosActualesConsulta[item.index].id
            ? { ...med, idReceta: recetas[indiceReceta].idBD }
            : med
        )));
      }
    },
    [tratamientosActualesConsulta, indiceReceta],
  );
  const onMoveMedicamento = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      setTratamientos((prev) => {
        const newArray = [...prev];
        const [ordenado] = newArray.splice(dragIndex, 1);
        newArray.splice(hoverIndex, 0, ordenado);
        return newArray;
      });
    },
    [tratamientosActualesConsulta, indiceReceta],
  );

  const handleConfirmClose = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setConfirmOpen(false);
  };

  const handleEliminarMedicamento = (idMedicamento: number) => {
    setIDMedicamentoEliminar(idMedicamento);
    setOpenMEMR(true);
  };

  const handleQuitarMedicamento = (idMedicamento: number) => {
    setIDMedicamentoQuitar(idMedicamento);
    setOpenMQMR(true);
  };

  const handleEditarMedicamentoActual = (medicamento: IMedicamento) => {
    setMedicamentoAEditar(medicamento);
    setOpenModalEditar(true);
  };

  const handleVerDetalleMedicamentoActual = (medicamento: IMedicamento) => {
    setMedicamentoAVerDetalle(medicamento);
    setOpenDetalle(true);
  };

  const handleDetalleClose = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenDetalle(false);
  };

  return (
    <div className="">
      <VerDetalleMedicamento
        open={openDetalle}
        handleDetalleClose={handleDetalleClose}
        medicamentoAVerDetalle={medicamentoAVerDetalle}
      />
      <ConfirmDialog
        open={confirmOpen}
        titulo="¡Atención!"
        descripcion={t('farmacologico_medicamento_duplicado_actual')}
        nombreCancelar={t('aceptar')}
        callBackClose={handleConfirmClose}
      />
      <div className="grid grid-cols-2 gap-y-2 gap-x-4">
        <h3 className="text-blue-800 font-medium m-0">{t('lista_de_medicamentos_actuales')}</h3>
        <h3 className="text-blue-800 font-medium m-0">
          {t('medicamento_que_deseo_incluir_en_esta_receta')}
        </h3>
        <span className="text-gray-500">
          {t('instrucciones__arrastra_los_medicamentos_de_izquierda_a_derecha')}
        </span>
        <TabRecetas
          indiceReceta={indiceReceta}
          setIndiceReceta={setIndiceReceta}
          recetas={recetas}
          setRecetas={setRecetas}
        />
      </div>
      <div className="flex" style={{ height: '750px' }}>
        <div className="w-1/2 overflow-y-auto mr-2">
          {/* eslint-disable-next-line max-len */}
          {tratamientosActualesConsulta.map((medicamento: IMedicamento, index: number) => (
            renderMedicamentoActual(
              medicamento,
              index,
              onDropMedicamento,
              ItemTypes.MEDICAMENTO_LISTA,
              handleEliminarMedicamento,
              handleEditarMedicamentoActual,
              handleVerDetalleMedicamentoActual,
            )
          ))}
        </div>
        <div className="w-1/2 flex">
          <div className="h-full w-4">
            <div className="bg-blue-200 h-full" />
          </div>
          <div className="w-full overflow-y-auto bg-gray-100 px-2" ref={dropMedicamento}>
            {/* eslint-disable-next-line max-len */}
            {tratamientosActualesConsulta.filter((med: IMedicamento) => med.idReceta === recetas[indiceReceta].idBD).map(
              (medicamento: IMedicamento, index: number) => renderMedicamentoReceta(
                medicamento,
                index,
                onDropMedicamento,
                ItemTypes.MEDICAMENTO_RECETA,
                onMoveMedicamento,
                handleQuitarMedicamento,
                handleEditarMedicamentoActual,
                handleVerDetalleMedicamentoActual,
              ),
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ListaMedicamentos;
