import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IFooterProps } from './types';
import { RootState } from '../../../../../../../store';

function FooterReceta({ consulta, consultorio }: IFooterProps) {
  const { rol } = useSelector((state: RootState) => state.Me);
  const { t } = useTranslation();
  return (
    <div className={`${consulta ? 'absolute bottom-0' : ''} w-full`}>
      <div className="text-center mt-6">
        <div className="border-0 border-solid border-gray-400 border-b w-64 inline-block" />
        <p className="m-0">
          {() => {
            if (rol.tipo === 'medico') {
              return t('medico_perfil_firma_medico');
            }
            if (rol.tipo === 'enfermero') {
              return t('enfermera_perfil_firma');
            }
            return '';
          }}
        </p>
      </div>
      <div className="w-full pl-4">
        <div className="flex items-center">
          <span className="ml-2">
            {consultorio.clinicaHospital.id && consultorio.clinicaHospital.id.length
              ? consultorio.clinicaHospital.label.slice(14)
              : consultorio.nombre}
          </span>
        </div>
        <div className="flex items-center">
          <span className="ml-2">
            {`${consultorio.telefono1.telefono.length ? t('telefonos') : t('telefono')}: ${
              consultorio.telefono1.telefono
                ? `${consultorio.telefono1.telefono}${
                  consultorio.telefono1.extension
                    ? ` ext. ${consultorio.telefono1.extension}`
                    : ''
                }; `
                : ''
            }${
              consultorio.telefono2.telefono
                ? `${consultorio.telefono2.telefono}${
                  consultorio.telefono2.extension
                    ? ` ext. ${consultorio.telefono2.extension}`
                    : ''
                }; `
                : ''
            }${
              consultorio.telefono3.telefono
                ? `${consultorio.telefono3.telefono}${
                  consultorio.telefono3.extension
                    ? ` ext. ${consultorio.telefono3.extension}`
                    : ''
                };`
                : ''
            }`}
          </span>
        </div>
        {consultorio.correoElectronico && (
          <div className="flex items-center">
            <span className="ml-2">
              {`${t('correo_electronico')}: ${consultorio.correoElectronico.toLowerCase()}`}
            </span>
          </div>
        )}
        <div className="flex items-center">
          <span className="ml-2">
            {`${t('direccion')}: ${consultorio.calle}${
              consultorio.num1 ? ` ${consultorio.num1}.` : ''
            }${consultorio.num2 ? ` ${consultorio.num2}.` : ''}${
              consultorio.agea ? ` ${consultorio.agea!.nom_agea}.` : ''
            }${consultorio.agem ? ` ${consultorio.agem.nom_agem}.` : ''}${
              consultorio.cp ? ` ${consultorio.cp}.` : ''
            }${consultorio.agel ? ` ${consultorio.agel.nom_agel}.` : ''}${
              consultorio.agee ? ` ${consultorio.agee.nom_agee}.` : ''
            }`}
          </span>
        </div>
      </div>
      <div className="bg-blue-500 text-center py-1">
        <span className="text-white">{t('receta_generada_por_medipraxi')}</span>
      </div>
    </div>
  );
}

export default FooterReceta;
