import React, { useState, useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Button,
  RadioGroup,
  Radio,
  FormControlLabel,
  TextField,
  IconButton,
  Select,
  MenuItem,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InfoIcon from '@material-ui/icons/Info';
import SeccionDialog from '../../../components/SeccionDialog';
import { ICatalogosEstandar } from '../../../common/types';
import {
  getCatalogoCategoriasMC,
  getMCPABusquedaListaMC,
  getBuilderFormatoConsultaPropio,
  getBuilderFormatoConsultaMedipraxi,
} from '../../../utils/getCatalogos';
import { RootState } from '../../../store';
import useStyles from './styles';
import { IEdadACSMCPARangoEdad, sexoPacienteACSMCPASexo } from '../../../common/functions';
import { HtmlTooltip } from '../../../utils/ComponentesJSX';
import { setSnackComplete } from '../../../actions/snackbar/types';
import { setLoading } from '../../../actions/loading/actions';
import EditorMCPA from './EditorMCPA';
import { removeUserSession } from '../../../utils/commonStore';
import { resetMe } from '../../../actions/me/actions';
import { limpiarRedux } from '../../../utils/reduxCommon';
import { setAuth } from '../../../actions/auth/actions';
import { removePaciente } from '../../../utils/common';
import { ICatalogoMCPA, IMCPACompleto, IMCPAProps } from './types';

function MCPA(props: IMCPAProps) {
  const { MCPACompleto, setMCPACompleto } = props;
  const { t } = useTranslation();
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { idMedico } = useSelector((state: RootState) => state.Me);
  const { sexo, edad } = useSelector((state: RootState) => state.BasicosPaciente);
  const { idOrganizacion } = useSelector((state: RootState) => state.Organizaciones.organizacion);
  const [MCPACompletoTemp, setMCPACompletoTemp] = useState<IMCPACompleto>({
    id: 0,
    tipoConsulta: 0,
    categoria: { id: 0, label: '' },
    formato: { id: 0, nombre: '', tipo: 'medipraxi' },
    rangoEdad: { id: 0, label: '' },
    sexo: { id: 0, label: '' },
    componentes: [],
    opciones: {
      notasAdicionales: {
        incluido: false,
        comentarios: '',
      },
    },
  });
  /** Catalogos */
  const [catCategoriaMC, setCatCategoriaMC] = useState<Array<ICatalogosEstandar>>([]);
  const [catMotivoConsulta, setCatMotivoConsulta] = useState<Array<ICatalogoMCPA>>([]);
  // manejo del alert
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const [alertMensaje, setAlertMensaje] = useState<React.ReactElement | undefined>(undefined);

  const handleChangeMC = (event: any, newValue: ICatalogoMCPA | null) => {
    if (newValue && newValue.id > 0) {
      if (newValue.tipo === 'medipraxi') {
        dispatch(setLoading(true));
        getBuilderFormatoConsultaMedipraxi(newValue.id, idMedico)
          .then((response) => response.json())
          .then((result) => {
            if (result.code === 200) {
              setMCPACompletoTemp({
                ...result.datos,
                id: 0,
                formato: newValue,
              });
            }
            dispatch(setLoading(false));
          })
          .catch((err) => {
            dispatch(setLoading(false));
            dispatch(
              setSnackComplete({
                open: true,
                severity: 'error',
                mensaje: `${t('message-error-warning')} ${err.toString()}`,
              }),
            );
          });
      } else {
        dispatch(setLoading(true));
        getBuilderFormatoConsultaPropio(newValue.id, idMedico)
          .then((response) => response.json())
          .then((result) => {
            if (result.code === 200) {
              setMCPACompletoTemp({
                ...result.datos,
                id: 0,
                formato: newValue,
              });
            } else if (result.code === 300) {
              // el token y el idMedico no son el mismo
              /* Cerrar sesión */
              removeUserSession();
              dispatch(resetMe());
              limpiarRedux();
              dispatch(setAuth({ id: '', suscrito: false, acreditado: false }));
              history.push('/login');
            } else if (result.code === 301) {
              // el medico no tiene subscripcion activa
              /* Resetear y redirigir al checkout */
              removePaciente();
              limpiarRedux();
              dispatch(setAuth({ id: '', suscrito: false, acreditado: true }));
              history.push('/subscripcion');
            } else if (result.code === 302) {
              // el paciente y el medico no tienen relacion
              /* Resetear redux paciente y datos paciente y redirigir a paciente */
              removePaciente();
              limpiarRedux();
              history.push('/pacientes');
            }
            dispatch(setLoading(false));
          })
          .catch((err) => {
            dispatch(setLoading(false));
            dispatch(
              setSnackComplete({
                open: true,
                severity: 'error',
                mensaje: `${t('message-error-warning')} ${err.toString()}`,
              }),
            );
          });
      }
    }
  };
  const handleAlertClose = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setAlertOpen(false);
  };
  useEffect(() => {
    getCatalogoCategoriasMC().then((result: ICatalogosEstandar[]) => {
      setCatCategoriaMC(result);
    });
  }, []);
  useEffect(() => {
    if (MCPACompleto.id > 0 && MCPACompletoTemp.id <= 0 && catCategoriaMC.length > 0) {
      setMCPACompletoTemp(MCPACompleto);
    }
  }, [MCPACompleto, catCategoriaMC]);

  const handleChangeTipoDeMC = (event: React.ChangeEvent<{ value: unknown }>) => {
    const val = event.target.value as string;
    if (Number(val) && MCPACompletoTemp.categoria) {
      dispatch(setLoading(true));
      getMCPABusquedaListaMC(
        Number(val),
        MCPACompletoTemp.categoria.id,
        sexoPacienteACSMCPASexo(sexo),
        IEdadACSMCPARangoEdad(edad),
        idMedico,
        idOrganizacion,
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.code === 200) {
            setCatMotivoConsulta(result.data);
          } else if (result.code === 300) {
            dispatch(
              setSnackComplete({
                open: true,
                severity: 'error',
                mensaje: t(result.msg),
              }),
            );
            setCatMotivoConsulta([{ id: 0, nombre: t(result.msg), tipo: 'medipraxi' }]);
          } else {
            dispatch(
              setSnackComplete({
                open: true,
                severity: 'info',
                mensaje: t('message-get-error'),
              }),
            );
            setCatMotivoConsulta([]);
          }
          dispatch(setLoading(false));
        })
        .catch((err) => {
          dispatch(setLoading(false));
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'error',
              mensaje: `${t('message-get-error')} ${err.toString()}`,
            }),
          );
        });
    }
    setMCPACompletoTemp({
      ...MCPACompletoTemp,
      tipoConsulta: Number(val) || 0,
      categoria: { id: 0, label: '' },
      formato: { id: 0, nombre: '', tipo: 'medipraxi' },
    });
  };

  const handleChangeCategoria = (event: React.ChangeEvent<{ value: unknown }>) => {
    const val = event.target.value as number;
    if (val) {
      dispatch(setLoading(true));
      getMCPABusquedaListaMC(
        MCPACompletoTemp.tipoConsulta,
        val,
        sexoPacienteACSMCPASexo(sexo),
        IEdadACSMCPARangoEdad(edad),
        idMedico,
        idOrganizacion,
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.code === 200) {
            setCatMotivoConsulta(result.data);
          } else if (result.code === 300) {
            dispatch(
              setSnackComplete({
                open: true,
                severity: 'error',
                mensaje: t(result.msg),
              }),
            );
            setCatMotivoConsulta([{ id: 0, nombre: t(result.msg), tipo: 'medipraxi' }]);
          } else {
            dispatch(
              setSnackComplete({
                open: true,
                severity: 'info',
                mensaje: t('message-get-error'),
              }),
            );
            setCatMotivoConsulta([]);
          }
          dispatch(setLoading(false));
        })
        .catch((err) => {
          dispatch(setLoading(false));
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'error',
              mensaje: `${t('message-get-error')} ${err.toString()}`,
            }),
          );
        });
    }
    setMCPACompletoTemp({
      ...MCPACompletoTemp,
      categoria: catCategoriaMC.find((c) => c.id === val) || { id: 0, label: '' },
      formato: { id: 0, nombre: '', tipo: 'medipraxi' },
    });
  };

  return (
    <div className="shadow-lg bg-white rounded border border-solid border-gray-200 p-4 container mx-auto">
      <SeccionDialog
        open={alertOpen}
        titulo={t('mc/pa-guia').toUpperCase()}
        descripcion={alertMensaje}
        callBackClose={handleAlertClose}
      />
      <div className="relative pb-2">
        <div className="text-center">
          <h1 className="text-blue-800 font-normal m-2">{t('mc/pa-label')}</h1>
        </div>
        <div className="grid grid-cols-12 gap-8 pt-4">
          <div className="col-span-3">
            <div className=" flex items-center">
              <h3 className="text-gray-600 m-0 font-medium">
                {t('tipo_de_consulta')}
                *
              </h3>
              <HtmlTooltip
                title={(
                  <>
                    <p>
                      <b>{t('motivo_primario')}</b>
                      : &nbsp;
                      {t('motivo_primario_instruccion')}
                    </p>
                    <p>
                      <b>{t('motivo_subsecuente')}</b>
                      : &nbsp;
                      {t('motivo_subsecuente_instruccion')}
                    </p>
                  </>
                )}
              >
                <IconButton size="small">
                  <InfoIcon color="primary" fontSize="small" />
                </IconButton>
              </HtmlTooltip>
            </div>
            <RadioGroup
              aria-label={t('tipo_de_consulta')}
              name="tipo-de-motivo-de-consulta"
              value={MCPACompletoTemp.tipoConsulta}
              onChange={handleChangeTipoDeMC}
            >
              <FormControlLabel
                value={1}
                control={<Radio color="primary" />}
                label={t('primario')}
              />
              <FormControlLabel
                value={2}
                control={<Radio color="primary" />}
                label={t('subsecuente')}
              />
            </RadioGroup>
          </div>
          <div className="col-span-3">
            <h3 className="text-gray-600 font-medium m-0 pb-4">
              {t('mc/pa-categoria')}
              *
            </h3>
            <Select
              value={MCPACompletoTemp.categoria.id}
              onChange={handleChangeCategoria}
              fullWidth
              variant="outlined"
              displayEmpty
              disabled={!MCPACompletoTemp.tipoConsulta}
            >
              <MenuItem value={0} disabled>
                <span className="text-gray-300 text-left">{t('selecciona')}</span>
              </MenuItem>
              {catCategoriaMC.map((singleCategoria) => (
                <MenuItem value={singleCategoria.id} key={singleCategoria.id}>
                  {singleCategoria.label}
                </MenuItem>
              ))}
            </Select>
          </div>
          <div className="col-span-3">
            <h3 className="text-gray-600 font-medium m-0 pb-4">{t('mc/pa-consulta')}</h3>
            <div>
              <Autocomplete
                options={catMotivoConsulta}
                getOptionLabel={(option) => (option.tipo === 'medipraxi' ? `${option.nombre} (Medipraxi®)` : option.nombre)}
                noOptionsText={t('sin_resultados')}
                value={MCPACompletoTemp.formato}
                onChange={(e, nV) => handleChangeMC(e, nV)}
                fullWidth
                renderInput={(params) => (
                  <TextField {...params} placeholder={t('selecciona')} variant="outlined" />
                )}
                disabled={!MCPACompletoTemp.tipoConsulta && !MCPACompletoTemp.categoria}
              />
            </div>
          </div>
        </div>
        <div className="flex justify-start mb-4">
          <Button
            color="primary"
            size="small"
            onClick={() => {
              setAlertOpen(true);
              setAlertMensaje(
                <Trans i18nKey="mc/pa-guia-llenado">
                  index0
                  <strong title="index1">index1</strong>
                  <br />
                  <br />
                  index4
                  <br />
                  <br />
                  index7
                  <strong>index8</strong>
                </Trans>,
              );
            }}
            className={classes.noTextTranform}
            startIcon={<InfoIcon />}
          >
            {t('mc/pa-guia')}
          </Button>
        </div>
      </div>
      {MCPACompletoTemp.formato.id > 0 && (
        <EditorMCPA
          mcpa={MCPACompletoTemp}
          setMCPA={setMCPACompletoTemp}
          setMCPACompleto={setMCPACompleto}
        />
      )}
    </div>
  );
}

export default MCPA;
