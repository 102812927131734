/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import {
  FormControl,
  FormControlLabel,
  IconButton,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Button,
} from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import InfoIcon from '@material-ui/icons/Info';
import SearchIcon from '@material-ui/icons/Search';
import CreateIcon from '@material-ui/icons/Create';
import { GreenButton, HtmlTooltip } from '../../utils/ComponentesJSX';
import { ICatalogosEstandar } from '../../common/types';
import {
  getBuilderFormatoConsultaMedipraxi,
  getBuilderFormatoConsultaPropio,
  getBuilderFormatosConsultaMedipraxi,
  getBuilderFormatosConsultaPropios,
  getBuilderFormatosConsultaCompartidos,
  getCatalogoCategoriasMC,
  getCatalogoRangoEdadMC,
} from '../../utils/getCatalogos';
import { compartirFormatoConsulta } from '../../utils/sendInfo';
import { RootState } from '../../store';
import { setLoading } from '../../actions/loading/actions';
import {
  ICatMotivosConsultaMedipraxi,
  ICatMotivosConsultaPropios,
  ICatMotivosConsultaCompartidos,
  IMotivoConsultaCompleto,
} from './types';
import { setSnackComplete } from '../../actions/snackbar/types';
import TablaFMCPropios from './TablaFMCPropios';
import TablaFMCCompartidos from './TablaFMCCompartidos';
import TablaFMCMedipraxi from './TablaFMCMedipraxi';
import ModalMotivoConsulta from '../../components/ModalMotivoConsulta';
import DeleteDialog from '../../components/DeleteDialog';
import { deleteFormatoConsultaEliminar } from '../../utils/deleteInfo';
import EditarFMC from './EditarFMC';
import { editHandleToggleHabilitarUso } from '../../utils/editInfo';
import { removePaciente, removeUserSession } from '../../utils/commonStore';
import { resetMe } from '../../actions/me/actions';
import { limpiarRedux } from '../../utils/reduxCommon';
import { setAuth } from '../../actions/auth/actions';

function FormatosMC() {
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { idMedico, enOrganizacion, secciones } = useSelector((state: RootState) => state.Me);
  const { idOrganizacion } = useSelector((state: RootState) => state.Organizaciones.organizacion);
  const [selFormato, setSelFormato] = useState<string>(
    secciones.length > 0 && secciones.find((valor) => valor.idSeccion === 67)
      ? 'medipraxi'
      : 'mis_formatos',
  );
  const [categoria, setCategoria] = useState<number>(0);
  const [rangoEdad, setRangoEdad] = useState<number>(0);
  const [nombreDelMC, setNombreDelMC] = useState<string>('');
  const [sexoParaElQueAplica, setSexoParaElQueAplica] = useState<number>(0);
  const [tipoDeMC, setTipoDeMC] = useState<number>(0);
  const [modoEditar, setModoEditar] = useState<number>(-1);
  const [modalFMC, setModalFMC] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [componenteUnico, setComponenteUnico] = useState<IMotivoConsultaCompleto>({
    idFormato: 0,
    categoria: { id: 0, label: '' },
    nombreFormato: '',
    rangoEdad: { id: 0, label: '' },
    sexo: { id: 0, label: '' },
    tipoConsulta: { id: 0, label: '' },
    componentes: [],
    opciones: {
      notasAdicionales: {
        incluido: false,
        comentarios: '',
      },
    },
    compartido: false,
  });
  /** Catalogos */
  const [catCategoriaMC, setCatCategoriaMC] = useState<Array<ICatalogosEstandar>>([]);
  const [catRangoEdadMC, setCatRangoEdadMC] = useState<Array<ICatalogosEstandar>>([]);
  const [catMedipraxi, setCatMedipraxi] = useState<ICatMotivosConsultaMedipraxi[]>([]);
  const [catMisFormatos, setCatMisFormatos] = useState<ICatMotivosConsultaPropios[]>([]);
  const [catFormatosCompartidos, setCatFormatosCompartidos] = useState<
  ICatMotivosConsultaCompartidos[]
  >([]);
  const [totalResultadosMisFormatos, setTotalResultadosMisFormatos] = useState<number>(0);
  const [totalResultadosFormatosCompartidos, setTotalResultadosFormatosCompartidos] = useState<number>(0);
  const [totalResultadosMedipraxi, setTotalResultadosMedipraxi] = useState<number>(0);
  const [totalPaginasMisFormatos, setTotalPaginasMisFormatos] = useState<number>(0);
  const [totalPaginasFormatosCompartidos, setTotalPaginasFormatosCompartidos] = useState<number>(0);
  const [totalPaginasMedipraxi, setTotalPaginasMedipraxi] = useState<number>(0);
  const [paginaMisFormatos, setPaginaMisFormatos] = useState<number>(0);
  const [paginaFormatosCompartidos, setPaginaFormatosCompartidos] = useState<number>(0);
  const [paginaMedipraxi, setPaginaMedipraxi] = useState<number>(0);
  const [idFormatoABorrar, setIDFormatoABorrar] = useState<number>(0);

  const handleAlertClose = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenModal(false);
  };

  const handleModalFMCAlertClose = (
    event: React.SyntheticEvent | React.MouseEvent,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setModalFMC(false);
  };

  const handleChangeFormato = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value as string;
    setSelFormato(val);
  };

  const handleChangeCategoria = (event: React.ChangeEvent<{ value: unknown }>) => {
    const val = event.target.value as number;
    setCategoria(val);
  };

  const handleChangeRangoEdad = (event: React.ChangeEvent<{ value: unknown }>) => {
    const val = event.target.value as number;
    setRangoEdad(val);
  };

  const handleChangeNombreDelMC = (event: React.ChangeEvent<{ value: unknown }>) => {
    const val = event.target.value as string;
    setNombreDelMC(val);
  };

  const handleChangeSexoParaElQueAplica = (event: React.ChangeEvent<{ value: unknown }>) => {
    const val = event.target.value as number;
    setSexoParaElQueAplica(Number(val));
  };

  const handleChangeTipoDeMC = (event: React.ChangeEvent<{ value: unknown }>) => {
    const val = event.target.value as number;
    setTipoDeMC(Number(val));
  };

  const handleEditar = (idForm: number) => {
    dispatch(setLoading(true));
    getBuilderFormatoConsultaPropio(idForm, idMedico)
      .then((response) => response.json())
      .then((result) => {
        if (result.code === 200) {
          setComponenteUnico(result.datos);
          setModoEditar(idForm);
        } else if (result.code === 300) {
          // el token y el idMedico no son el mismo
          /* Cerrar sesión */
          removeUserSession();
          dispatch(resetMe());
          limpiarRedux();
          dispatch(setAuth({ id: '', suscrito: false, acreditado: false }));
          history.push('/login');
        } else if (result.code === 301) {
          // el medico no tiene subscripcion activa
          /* Resetear y redirigir al checkout */
          removePaciente();
          limpiarRedux();
          dispatch(setAuth({ id: '', suscrito: false, acreditado: true }));
          history.push('/subscripcion');
        } else if (result.code === 302) {
          // el paciente y el medico no tienen relacion
          /* Resetear redux paciente y datos paciente y redirigir a paciente */
          removePaciente();
          limpiarRedux();
          history.push('/pacientes');
        }
        dispatch(setLoading(false));
      })
      .catch((err) => {
        dispatch(setLoading(false));
        dispatch(
          setSnackComplete({
            open: true,
            severity: 'error',
            mensaje: `${t('message-error-warning')} ${err.toString()}`,
          }),
        );
      });
  };

  const handleCompartir = (idFormato: number) => {
    dispatch(setLoading(true));
    const compartir = !catMisFormatos.find((f) => f.idFormato === idFormato)?.compartido;
    const objEnviar = {
      idMedico,
      idFormato,
      compartir,
    };
    compartirFormatoConsulta(objEnviar)
      .then((response) => response.json())
      .then((result) => {
        if (result.code === 200) {
          const newArray = catMisFormatos;
          const index = newArray.findIndex((f) => f.idFormato === idFormato);
          if (componenteUnico.idFormato === idFormato) {
            setComponenteUnico({
              ...componenteUnico,
              compartido: compartir,
            });
          }
          newArray[index] = {
            ...newArray[index],
            compartido: compartir,
          };
          setCatMisFormatos(newArray);
          dispatch(setLoading(false));
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'success',
              mensaje: compartir
                ? t('formato_compartido_satisfactoriamente')
                : t('formato_descompartido_satisfactoriamente'),
            }),
          );
        } else if (result.code === 400) {
          dispatch(setLoading(false));
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'error',
              mensaje: `${t('message-error')} ${result.msg}`,
            }),
          );
        } else if (result.code === 300) {
          // el token y el idMedico no son el mismo
          /* Cerrar sesión */
          removeUserSession();
          dispatch(resetMe());
          limpiarRedux();
          dispatch(setAuth({ id: '', suscrito: false, acreditado: false }));
          history.push('/login');
        } else if (result.code === 301) {
          // el medico no tiene subscripcion activa
          /* Resetear y redirigir al checkout */
          removePaciente();
          limpiarRedux();
          dispatch(setAuth({ id: '', suscrito: false, acreditado: true }));
          history.push('/subscripcion');
        } else if (result.code === 302) {
          // el paciente y el medico no tienen relacion
          /* Resetear redux paciente y datos paciente y redirigir a paciente */
          removePaciente();
          limpiarRedux();
          history.push('/pacientes');
        }
        dispatch(setLoading(false));
      })
      .catch((err) => {
        dispatch(setLoading(false));
        dispatch(
          setSnackComplete({
            open: true,
            severity: 'error',
            mensaje: `${t('message-error-warning')} ${err.toString()}`,
          }),
        );
      });
  };

  const handleBorrar = (idF: number) => {
    setIDFormatoABorrar(idF);
    setModalFMC(true);
  };

  const handleHabilitarUso = (idF: number) => {
    dispatch(setLoading(true));
    const objEnviar = {
      idFormato: idF,
      idMedico,
    };
    dispatch(
      setSnackComplete({
        open: true,
        severity: 'info',
        mensaje: t('message-info'),
      }),
    );
    editHandleToggleHabilitarUso(objEnviar)
      .then((response) => response.json())
      .then((result) => {
        if (result.code === 200) {
          setCatMedipraxi((preCat) => preCat.map((fMDPX) => {
            if (fMDPX.idFormato === result.data.idFormato) {
              return { ...fMDPX, habilitarUso: result.data.habilitarUso };
            }
            return fMDPX;
          }));
          dispatch(setLoading(false));
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'success',
              mensaje: t('message-update-success'),
            }),
          );
        } else if (result.code === 300) {
          // el token y el idMedico no son el mismo
          /* Cerrar sesión */
          removeUserSession();
          dispatch(resetMe());
          limpiarRedux();
          dispatch(setAuth({ id: '', suscrito: false, acreditado: false }));
          history.push('/login');
        } else if (result.code === 301) {
          // el medico no tiene subscripcion activa
          /* Resetear y redirigir al checkout */
          removePaciente();
          limpiarRedux();
          dispatch(setAuth({ id: '', suscrito: false, acreditado: true }));
          history.push('/subscripcion');
        } else if (result.code === 302) {
          // el paciente y el medico no tienen relacion
          /* Resetear redux paciente y datos paciente y redirigir a paciente */
          removePaciente();
          limpiarRedux();
          history.push('/pacientes');
        } else {
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'error',
              mensaje: `${t('message-update-error')} ${result.msg}`,
            }),
          );
        }
      })
      .catch((err) => {
        dispatch(
          setSnackComplete({
            open: true,
            severity: 'error',
            mensaje: `${t('message-update-error')} ${err.toString()}`,
          }),
        );
      });
  };

  const handleModalFMC = () => {
    dispatch(setLoading(true));
    const objEnviar = {
      idFormato: idFormatoABorrar,
      idMedico,
    };
    dispatch(
      setSnackComplete({
        open: true,
        severity: 'info',
        mensaje: t('message-info'),
      }),
    );
    deleteFormatoConsultaEliminar(objEnviar)
      .then((response) => response.json())
      .then((result) => {
        if (result.code === 200) {
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'success',
              mensaje: t('message-success'),
            }),
          );
          getBuilderFormatosConsultaPropios(
            idMedico,
            nombreDelMC,
            categoria,
            rangoEdad,
            sexoParaElQueAplica,
            tipoDeMC,
            paginaMisFormatos > 1 && (totalResultadosMisFormatos - 1) % 10 === 0
              ? paginaMisFormatos - 1
              : paginaMisFormatos,
          )
            .then((response) => response.json())
            .then((res) => {
              if (res.code === 200) {
                setCatMisFormatos(res.datos.datos);
                setTotalResultadosMisFormatos(res.datos.totalResultados);
                setPaginaMisFormatos(res.datos.pagina);
                setTotalPaginasMisFormatos(res.datos.totalPaginas);
              } else if (result.code === 300) {
                // el token y el idMedico no son el mismo
                /* Cerrar sesión */
                removeUserSession();
                dispatch(resetMe());
                limpiarRedux();
                dispatch(setAuth({ id: '', suscrito: false, acreditado: false }));
                history.push('/login');
              } else if (result.code === 301) {
                // el medico no tiene subscripcion activa
                /* Resetear y redirigir al checkout */
                removePaciente();
                limpiarRedux();
                dispatch(setAuth({ id: '', suscrito: false, acreditado: true }));
                history.push('/subscripcion');
              } else if (result.code === 302) {
                // el paciente y el medico no tienen relacion
                /* Resetear redux paciente y datos paciente y redirigir a paciente */
                removePaciente();
                limpiarRedux();
                history.push('/pacientes');
              }
              dispatch(setLoading(false));
            })
            .catch((err) => {
              dispatch(setLoading(false));
              dispatch(
                setSnackComplete({
                  open: true,
                  severity: 'error',
                  mensaje: `${t('message-error-general')} ${err.toString()}`,
                }),
              );
            });
        } else if (result.code === 300) {
          // el token y el idMedico no son el mismo
          /* Cerrar sesión */
          removeUserSession();
          dispatch(resetMe());
          limpiarRedux();
          dispatch(setAuth({ id: '', suscrito: false, acreditado: false }));
          history.push('/login');
        } else if (result.code === 301) {
          // el medico no tiene subscripcion activa
          /* Resetear y redirigir al checkout */
          removePaciente();
          limpiarRedux();
          dispatch(setAuth({ id: '', suscrito: false, acreditado: true }));
          history.push('/subscripcion');
        } else if (result.code === 302) {
          // el paciente y el medico no tienen relacion
          /* Resetear redux paciente y datos paciente y redirigir a paciente */
          removePaciente();
          limpiarRedux();
          history.push('/pacientes');
        } else {
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'error',
              mensaje: `${t('message-update-error')} ${result.msg}`,
            }),
          );
        }
      })
      .catch((err) => {
        dispatch(
          setSnackComplete({
            open: true,
            severity: 'error',
            mensaje: `${t('message-update-error')} ${err.toString()}`,
          }),
        );
      });
  };

  const handleVerFormatoMedipraxi = (idForm: number) => {
    dispatch(setLoading(true));
    getBuilderFormatoConsultaMedipraxi(idForm, idMedico)
      .then((response) => response.json())
      .then((result) => {
        if (result.code === 200) {
          setComponenteUnico(result.datos);
          setOpenModal(true);
        }
        dispatch(setLoading(false));
      })
      .catch((err) => {
        dispatch(setLoading(false));
        dispatch(
          setSnackComplete({
            open: true,
            severity: 'error',
            mensaje: `${t('message-error-warning')} ${err.toString()}`,
          }),
        );
      });
  };

  const handleVerFormatoPropios = (idForm: number) => {
    dispatch(setLoading(true));
    getBuilderFormatoConsultaPropio(idForm, idMedico)
      .then((response) => response.json())
      .then((result) => {
        if (result.code === 200) {
          setComponenteUnico(result.datos);
          setOpenModal(true);
        } else if (result.code === 300) {
          // el token y el idMedico no son el mismo
          /* Cerrar sesión */
          removeUserSession();
          dispatch(resetMe());
          limpiarRedux();
          dispatch(setAuth({ id: '', suscrito: false, acreditado: false }));
          history.push('/login');
        } else if (result.code === 301) {
          // el medico no tiene subscripcion activa
          /* Resetear y redirigir al checkout */
          removePaciente();
          limpiarRedux();
          dispatch(setAuth({ id: '', suscrito: false, acreditado: true }));
          history.push('/subscripcion');
        } else if (result.code === 302) {
          // el paciente y el medico no tienen relacion
          /* Resetear redux paciente y datos paciente y redirigir a paciente */
          removePaciente();
          limpiarRedux();
          history.push('/pacientes');
        }
        dispatch(setLoading(false));
      })
      .catch((err) => {
        dispatch(setLoading(false));
        dispatch(
          setSnackComplete({
            open: true,
            severity: 'error',
            mensaje: `${t('message-error-warning')} ${err.toString()}`,
          }),
        );
      });
  };

  const handleBusqueda = () => {
    dispatch(setLoading(true));
    if (selFormato === 'mis_formatos') {
      getBuilderFormatosConsultaPropios(
        idMedico,
        nombreDelMC,
        categoria,
        rangoEdad,
        sexoParaElQueAplica,
        tipoDeMC,
        1,
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.code === 200) {
            setCatMisFormatos(result.datos.datos);
            setTotalResultadosMisFormatos(result.datos.totalResultados);
            setPaginaMisFormatos(result.datos.pagina);
            setTotalPaginasMisFormatos(result.datos.totalPaginas);
          } else if (result.code === 300) {
            // el token y el idMedico no son el mismo
            /* Cerrar sesión */
            removeUserSession();
            dispatch(resetMe());
            limpiarRedux();
            dispatch(setAuth({ id: '', suscrito: false, acreditado: false }));
            history.push('/login');
          } else if (result.code === 301) {
            // el medico no tiene subscripcion activa
            /* Resetear y redirigir al checkout */
            removePaciente();
            limpiarRedux();
            dispatch(setAuth({ id: '', suscrito: false, acreditado: true }));
            history.push('/subscripcion');
          } else if (result.code === 302) {
            // el paciente y el medico no tienen relacion
            /* Resetear redux paciente y datos paciente y redirigir a paciente */
            removePaciente();
            limpiarRedux();
            history.push('/pacientes');
          }
          dispatch(setLoading(false));
        })
        .catch((err) => {
          dispatch(setLoading(false));
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'error',
              mensaje: `${t('message-error-general')} ${err.toString()}`,
            }),
          );
        });
    } else if (selFormato === 'formatos_compartidos') {
      getBuilderFormatosConsultaCompartidos(
        idMedico,
        idOrganizacion,
        nombreDelMC,
        categoria,
        rangoEdad,
        sexoParaElQueAplica,
        tipoDeMC,
        1,
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.code === 200) {
            setCatFormatosCompartidos(result.datos.datos);
            setTotalResultadosFormatosCompartidos(result.datos.totalResultados);
            setPaginaFormatosCompartidos(result.datos.pagina);
            setTotalPaginasFormatosCompartidos(result.datos.totalPaginas);
          } else if (result.code === 300) {
            // el token y el idMedico no son el mismo
            /* Cerrar sesión */
            removeUserSession();
            dispatch(resetMe());
            limpiarRedux();
            dispatch(setAuth({ id: '', suscrito: false, acreditado: false }));
            history.push('/login');
          } else if (result.code === 301) {
            // el medico no tiene subscripcion activa
            /* Resetear y redirigir al checkout */
            removePaciente();
            limpiarRedux();
            dispatch(setAuth({ id: '', suscrito: false, acreditado: true }));
            history.push('/subscripcion');
          } else if (result.code === 302) {
            // el paciente y el medico no tienen relacion
            /* Resetear redux paciente y datos paciente y redirigir a paciente */
            removePaciente();
            limpiarRedux();
            history.push('/pacientes');
          }
          dispatch(setLoading(false));
        })
        .catch((err) => {
          dispatch(setLoading(false));
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'error',
              mensaje: `${t('message-error-general')} ${err.toString()}`,
            }),
          );
        });
    } else {
      getBuilderFormatosConsultaMedipraxi(
        idMedico,
        nombreDelMC,
        categoria,
        rangoEdad,
        sexoParaElQueAplica,
        tipoDeMC,
        1,
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.code === 200) {
            setCatMedipraxi(result.datos.datos);
            setTotalResultadosMedipraxi(result.datos.totalResultados);
            setPaginaMedipraxi(result.datos.pagina);
            setTotalPaginasMedipraxi(result.datos.totalPaginas);
          } else if (result.code === 300) {
            // el token y el idMedico no son el mismo
            /* Cerrar sesión */
            removeUserSession();
            dispatch(resetMe());
            limpiarRedux();
            dispatch(setAuth({ id: '', suscrito: false, acreditado: false }));
            history.push('/login');
          } else if (result.code === 301) {
            // el medico no tiene subscripcion activa
            /* Resetear y redirigir al checkout */
            removePaciente();
            limpiarRedux();
            dispatch(setAuth({ id: '', suscrito: false, acreditado: true }));
            history.push('/subscripcion');
          } else if (result.code === 302) {
            // el paciente y el medico no tienen relacion
            /* Resetear redux paciente y datos paciente y redirigir a paciente */
            removePaciente();
            limpiarRedux();
            history.push('/pacientes');
          }
          dispatch(setLoading(false));
        })
        .catch((err) => {
          dispatch(setLoading(false));
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'error',
              mensaje: `${t('message-error-general')} ${err.toString()}`,
            }),
          );
        });
    }
  };

  const handleChangePaginaMedipraxi = (event: React.ChangeEvent<unknown>, value: number) => {
    dispatch(setLoading(true));
    getBuilderFormatosConsultaMedipraxi(
      idMedico,
      nombreDelMC,
      categoria,
      rangoEdad,
      sexoParaElQueAplica,
      tipoDeMC,
      value,
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.code === 200) {
          setCatMedipraxi(result.datos.datos);
          setTotalResultadosMedipraxi(result.datos.totalResultados);
          setPaginaMedipraxi(result.datos.pagina);
          setTotalPaginasMedipraxi(result.datos.totalPaginas);
        } else if (result.code === 300) {
          // el token y el idMedico no son el mismo
          /* Cerrar sesión */
          removeUserSession();
          dispatch(resetMe());
          limpiarRedux();
          dispatch(setAuth({ id: '', suscrito: false, acreditado: false }));
          history.push('/login');
        } else if (result.code === 301) {
          // el medico no tiene subscripcion activa
          /* Resetear y redirigir al checkout */
          removePaciente();
          limpiarRedux();
          dispatch(setAuth({ id: '', suscrito: false, acreditado: true }));
          history.push('/subscripcion');
        } else if (result.code === 302) {
          // el paciente y el medico no tienen relacion
          /* Resetear redux paciente y datos paciente y redirigir a paciente */
          removePaciente();
          limpiarRedux();
          history.push('/pacientes');
        }
        dispatch(setLoading(false));
      })
      .catch((err) => {
        dispatch(setLoading(false));
        dispatch(
          setSnackComplete({
            open: true,
            severity: 'error',
            mensaje: `${t('message-error-general')} ${err.toString()}`,
          }),
        );
      });
  };

  const handleChangePaginaMisFormatos = (event: React.ChangeEvent<unknown>, value: number) => {
    dispatch(setLoading(true));
    getBuilderFormatosConsultaPropios(
      idMedico,
      nombreDelMC,
      categoria,
      rangoEdad,
      sexoParaElQueAplica,
      tipoDeMC,
      value,
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.code === 200) {
          setCatMisFormatos(result.datos.datos);
          setTotalResultadosMisFormatos(result.datos.totalResultados);
          setPaginaMisFormatos(result.datos.pagina);
          setTotalPaginasMisFormatos(result.datos.totalPaginas);
        } else if (result.code === 300) {
          // el token y el idMedico no son el mismo
          /* Cerrar sesión */
          removeUserSession();
          dispatch(resetMe());
          limpiarRedux();
          dispatch(setAuth({ id: '', suscrito: false, acreditado: false }));
          history.push('/login');
        } else if (result.code === 301) {
          // el medico no tiene subscripcion activa
          /* Resetear y redirigir al checkout */
          removePaciente();
          limpiarRedux();
          dispatch(setAuth({ id: '', suscrito: false, acreditado: true }));
          history.push('/subscripcion');
        } else if (result.code === 302) {
          // el paciente y el medico no tienen relacion
          /* Resetear redux paciente y datos paciente y redirigir a paciente */
          removePaciente();
          limpiarRedux();
          history.push('/pacientes');
        }
        dispatch(setLoading(false));
      })
      .catch((err) => {
        dispatch(setLoading(false));
        dispatch(
          setSnackComplete({
            open: true,
            severity: 'error',
            mensaje: `${t('message-error-general')} ${err.toString()}`,
          }),
        );
      });
  };

  const handleChangePaginaFormatosCompartidos = (
    event: React.ChangeEvent<unknown>,
    value: number,
  ) => {
    dispatch(setLoading(true));
    getBuilderFormatosConsultaCompartidos(
      idMedico,
      idOrganizacion,
      nombreDelMC,
      categoria,
      rangoEdad,
      sexoParaElQueAplica,
      tipoDeMC,
      value,
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.code === 200) {
          setCatFormatosCompartidos(result.datos.datos);
          setTotalResultadosFormatosCompartidos(result.datos.totalResultados);
          setPaginaFormatosCompartidos(result.datos.pagina);
          setTotalPaginasFormatosCompartidos(result.datos.totalPaginas);
        } else if (result.code === 300) {
          // el token y el idMedico no son el mismo
          /* Cerrar sesión */
          removeUserSession();
          dispatch(resetMe());
          limpiarRedux();
          dispatch(setAuth({ id: '', suscrito: false, acreditado: false }));
          history.push('/login');
        } else if (result.code === 301) {
          // el medico no tiene subscripcion activa
          /* Resetear y redirigir al checkout */
          removePaciente();
          limpiarRedux();
          dispatch(setAuth({ id: '', suscrito: false, acreditado: true }));
          history.push('/subscripcion');
        } else if (result.code === 302) {
          // el paciente y el medico no tienen relacion
          /* Resetear redux paciente y datos paciente y redirigir a paciente */
          removePaciente();
          limpiarRedux();
          history.push('/pacientes');
        }
        dispatch(setLoading(false));
      })
      .catch((err) => {
        dispatch(setLoading(false));
        dispatch(
          setSnackComplete({
            open: true,
            severity: 'error',
            mensaje: `${t('message-error-general')} ${err.toString()}`,
          }),
        );
      });
  };

  useEffect(() => {
    dispatch(setLoading(true));
    getCatalogoCategoriasMC().then((result: ICatalogosEstandar[]) => {
      setCatCategoriaMC(result);
    });
    getCatalogoRangoEdadMC().then((result: ICatalogosEstandar[]) => {
      setCatRangoEdadMC(result);
    });
    getBuilderFormatosConsultaMedipraxi(
      idMedico,
      nombreDelMC,
      categoria,
      rangoEdad,
      sexoParaElQueAplica,
      tipoDeMC,
      1,
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.code === 200) {
          setCatMedipraxi(result.datos.datos);
          setTotalResultadosMedipraxi(result.datos.totalResultados);
          setPaginaMedipraxi(result.datos.pagina);
          setTotalPaginasMedipraxi(result.datos.totalPaginas);
        } else if (result.code === 300) {
          // el token y el idMedico no son el mismo
          /* Cerrar sesión */
          removeUserSession();
          dispatch(resetMe());
          limpiarRedux();
          dispatch(setAuth({ id: '', suscrito: false, acreditado: false }));
          history.push('/login');
        } else if (result.code === 301) {
          // el medico no tiene subscripcion activa
          /* Resetear y redirigir al checkout */
          removePaciente();
          limpiarRedux();
          dispatch(setAuth({ id: '', suscrito: false, acreditado: true }));
          history.push('/subscripcion');
        } else if (result.code === 302) {
          // el paciente y el medico no tienen relacion
          /* Resetear redux paciente y datos paciente y redirigir a paciente */
          removePaciente();
          limpiarRedux();
          history.push('/pacientes');
        }
        dispatch(setLoading(false));
      })
      .catch((err) => {
        dispatch(setLoading(false));
        dispatch(
          setSnackComplete({
            open: true,
            severity: 'error',
            mensaje: `${t('message-error-general')} ${err.toString()}`,
          }),
        );
      });
    getBuilderFormatosConsultaPropios(
      idMedico,
      nombreDelMC,
      categoria,
      rangoEdad,
      sexoParaElQueAplica,
      tipoDeMC,
      1,
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.code === 200) {
          setCatMisFormatos(result.datos.datos);
          setTotalResultadosMisFormatos(result.datos.totalResultados);
          setPaginaMisFormatos(result.datos.pagina);
          setTotalPaginasMisFormatos(result.datos.totalPaginas);
        } else if (result.code === 300) {
          // el token y el idMedico no son el mismo
          /* Cerrar sesión */
          removeUserSession();
          dispatch(resetMe());
          limpiarRedux();
          dispatch(setAuth({ id: '', suscrito: false, acreditado: false }));
          history.push('/login');
        } else if (result.code === 301) {
          // el medico no tiene subscripcion activa
          /* Resetear y redirigir al checkout */
          removePaciente();
          limpiarRedux();
          dispatch(setAuth({ id: '', suscrito: false, acreditado: true }));
          history.push('/subscripcion');
        } else if (result.code === 302) {
          // el paciente y el medico no tienen relacion
          /* Resetear redux paciente y datos paciente y redirigir a paciente */
          removePaciente();
          limpiarRedux();
          history.push('/pacientes');
        }
        dispatch(setLoading(false));
      })
      .catch((err) => {
        dispatch(setLoading(false));
        dispatch(
          setSnackComplete({
            open: true,
            severity: 'error',
            mensaje: `${t('message-error-general')} ${err.toString()}`,
          }),
        );
      });
    if (enOrganizacion) {
      getBuilderFormatosConsultaCompartidos(
        idMedico,
        idOrganizacion,
        nombreDelMC,
        categoria,
        rangoEdad,
        sexoParaElQueAplica,
        tipoDeMC,
        1,
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.code === 200) {
            setCatFormatosCompartidos(result.datos.datos);
            setTotalResultadosFormatosCompartidos(result.datos.totalResultados);
            setPaginaFormatosCompartidos(result.datos.pagina);
            setTotalPaginasFormatosCompartidos(result.datos.totalPaginas);
          } else if (result.code === 300) {
            // el token y el idMedico no son el mismo
            /* Cerrar sesión */
            removeUserSession();
            dispatch(resetMe());
            limpiarRedux();
            dispatch(setAuth({ id: '', suscrito: false, acreditado: false }));
            history.push('/login');
          } else if (result.code === 301) {
            // el medico no tiene subscripcion activa
            /* Resetear y redirigir al checkout */
            removePaciente();
            limpiarRedux();
            dispatch(setAuth({ id: '', suscrito: false, acreditado: true }));
            history.push('/subscripcion');
          } else if (result.code === 302) {
            // el paciente y el medico no tienen relacion
            /* Resetear redux paciente y datos paciente y redirigir a paciente */
            removePaciente();
            limpiarRedux();
            history.push('/pacientes');
          }
          dispatch(setLoading(false));
        })
        .catch((err) => {
          dispatch(setLoading(false));
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'error',
              mensaje: `${t('message-error-general')} ${err.toString()}`,
            }),
          );
        });
    }
  }, []);
  return modoEditar > 0 ? (
    <EditarFMC
      motivoConsulta={componenteUnico}
      setMotivoConsulta={setComponenteUnico}
      setModoEditar={setModoEditar}
      modoEditar={modoEditar}
      handleCompartir={handleCompartir}
    />
  ) : (
    <div className="container mx-auto">
      <ModalMotivoConsulta
        open={openModal}
        componentes={componenteUnico.componentes}
        categoria={componenteUnico.categoria.label}
        nombreFormato={componenteUnico.nombreFormato}
        rangoEdad={componenteUnico.rangoEdad.label}
        sexo={componenteUnico.sexo.label}
        tipoConsulta={componenteUnico.tipoConsulta.label}
        idFormato={componenteUnico.idFormato}
        opciones={componenteUnico.opciones}
        callBackClose={handleAlertClose}
      />
      <DeleteDialog
        open={modalFMC}
        titulo={t('_atencion_')}
        descripcion={t('_seguro_que_deseas_eliminar_el_formato_')}
        callBackAceptar={handleModalFMC}
        callBackClose={handleModalFMCAlertClose}
      />
      <div className="shadow-lg bg-white rounded py-6 px-10 border border-solid border-gray-200">
        <div>
          <div className="text-center">
            <h1 className="text-blue-800 font-normal text-center m-0">{t('formatos_de_m_c')}</h1>
          </div>
          <p className="text-gray-600">{t('formatos_m_c_instruccion_1')}</p>
          <p className="text-gray-600">{t('formatos_m_c_instruccion_2')}</p>
          <p className="text-gray-600">{t('formatos_m_c_instruccion_3')}</p>
        </div>
        {secciones.length > 0
          && secciones.find((valor) => valor.idSeccion === 67 || valor.idSeccion === 68) && (
            <div>
              <h2 className="text-blue-500 font-normal m-0">{t('buscar_formatos')}</h2>
              <FormControl component="fieldset">
                <RadioGroup row value={selFormato} onChange={handleChangeFormato}>
                  {secciones.length > 0 && secciones.find((valor) => valor.idSeccion === 67) && (
                    <FormControlLabel
                      value="medipraxi"
                      control={<Radio color="primary" />}
                      label={(
                        <span>
                          {t('formatos_de')}
                          &nbsp;
                          <b>
                            {t('medipraxi')}
                            <sup>®</sup>
                          </b>
                        </span>
                      )}
                    />
                  )}
                  {secciones.length > 0 && secciones.find((valor) => valor.idSeccion === 68) && (
                    <FormControlLabel
                      value="mis_formatos"
                      control={<Radio color="primary" />}
                      label={<span>{t('mis_formatos')}</span>}
                    />
                  )}
                  {secciones.length > 0
                    && secciones.find((valor) => valor.idSeccion === 68)
                    && enOrganizacion && (
                      <FormControlLabel
                        value="formatos_compartidos"
                        control={<Radio color="primary" />}
                        label={<span>{t('formatos_compartidos')}</span>}
                      />
                  )}
                </RadioGroup>
              </FormControl>
              <div className="grid grid-cols-12 gap-y-4 mt-2">
                <div className="col-span-3">
                  <h3 className="mb-2 text-gray-600 m-0 font-medium flex items-center">
                    {`${t('selecciona_una_categoria')}*`}
                  </h3>
                  <Select
                    value={categoria}
                    onChange={handleChangeCategoria}
                    fullWidth
                    variant="outlined"
                    displayEmpty
                  >
                    <MenuItem value={0} disabled>
                      <span className="text-gray-300 text-left">{t('selecciona')}</span>
                    </MenuItem>
                    {catCategoriaMC.map((singleCategoria) => (
                      <MenuItem value={singleCategoria.id} key={singleCategoria.id}>
                        {singleCategoria.label}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
                <div className="col-span-1" />
                <div className="col-span-6">
                  <h3 className="mb-2 text-gray-600 m-0 font-medium flex items-center">
                    {`${t('nombre_del_mc')}*`}
                  </h3>
                  <TextField
                    value={nombreDelMC}
                    onChange={handleChangeNombreDelMC}
                    fullWidth
                    variant="outlined"
                    inputProps={{ maxLength: 50, autoComplete: 'off' }}
                  />
                </div>
                <div className="col-span-3">
                  <h3 className="mb-2 text-gray-600 m-0 font-medium flex items-center">
                    {t('rango_de_edad')}
                  </h3>
                  <Select
                    value={rangoEdad}
                    onChange={handleChangeRangoEdad}
                    fullWidth
                    variant="outlined"
                    displayEmpty
                  >
                    <MenuItem value={0} disabled>
                      <span className="text-gray-300 text-left">{t('selecciona')}</span>
                    </MenuItem>
                    {catRangoEdadMC.map((singleRangoEdad) => (
                      <MenuItem value={singleRangoEdad.id} key={singleRangoEdad.id}>
                        {singleRangoEdad.label}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
                <div className="col-span-1" />
                <div className="col-span-4">
                  <h3 className="mb-1 text-gray-600 m-0 font-medium flex items-center">
                    {t('sexo_para_el_que_aplica')}
                  </h3>
                  <RadioGroup
                    aria-label={t('sexo_para_el_que_aplica')}
                    name="sexo-para-el-que-aplica"
                    value={sexoParaElQueAplica}
                    onChange={handleChangeSexoParaElQueAplica}
                    row
                  >
                    <FormControlLabel
                      value={1}
                      control={<Radio color="primary" />}
                      label={t('ambos')}
                    />
                    <FormControlLabel
                      value={2}
                      control={<Radio color="primary" />}
                      label={t('mujer')}
                    />
                    <FormControlLabel
                      value={3}
                      control={<Radio color="primary" />}
                      label={t('hombre')}
                    />
                  </RadioGroup>
                </div>
                <div className="col-span-3">
                  <div className=" flex items-center">
                    <h3 className="text-gray-600 m-0 font-medium">{t('tipo_de_consulta')}</h3>
                    <HtmlTooltip
                      title={(
                        <>
                          <p>
                            <b>{t('motivo_primario')}</b>
                            : &nbsp;
                            {t('motivo_primario_instruccion')}
                          </p>
                          <p>
                            <b>{t('motivo_subsecuente')}</b>
                            : &nbsp;
                            {t('motivo_subsecuente_instruccion')}
                          </p>
                        </>
                      )}
                    >
                      <IconButton size="small">
                        <InfoIcon color="primary" fontSize="small" />
                      </IconButton>
                    </HtmlTooltip>
                  </div>
                  <RadioGroup
                    aria-label={t('tipo_de_consulta')}
                    name="tipo-de-motivo-de-consulta"
                    value={tipoDeMC}
                    onChange={handleChangeTipoDeMC}
                    row
                  >
                    <FormControlLabel
                      value={1}
                      control={<Radio color="primary" />}
                      label={t('primario')}
                    />
                    <FormControlLabel
                      value={2}
                      control={<Radio color="primary" />}
                      label={t('subsecuente')}
                    />
                  </RadioGroup>
                </div>
                <div className="self-center">
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<SearchIcon />}
                    fullWidth
                    disabled={!categoria || !nombreDelMC}
                    onClick={handleBusqueda}
                  >
                    {t('buscar')}
                  </Button>
                </div>
              </div>
            </div>
        )}
        <hr className="mt-4" />
        {secciones.length > 0
          && secciones.find((valor) => valor.idSeccion >= 67 && valor.idSeccion <= 69) && (
            <div>
              {secciones.length > 0
                && secciones.find((valor) => valor.idSeccion === 67 || valor.idSeccion === 68) && (
                  <h2 className="text-blue-500 font-normal m-0">
                    {() => {
                      if (selFormato === 'medipraxi') {
                        return `${t('resultados')} (${totalResultadosMedipraxi})`;
                      }
                      if (selFormato === 'mis_formatos') {
                        return `${t('resultados')} (${totalResultadosMisFormatos})`;
                      }
                      return `${t('resultados')} (${totalResultadosFormatosCompartidos})`;
                    }}
                  </h2>
              )}
              <div className="flex justify-between items-center">
                {secciones.length > 0
                  && secciones.find((valor) => valor.idSeccion === 67 || valor.idSeccion === 68) && (
                    <p className="text-gray-600 font-medium">
                      {() => {
                        if (selFormato === 'medipraxi') {
                          return t('formatos_mc_copy_medipraxi');
                        }
                        if (selFormato === 'mis_formatos') {
                          return t('formatos_mc_copy_propios');
                        }
                        return t('formatos_mc_copy_compartidos');
                      }}
                    </p>
                )}
                {secciones.length > 0 && secciones.find((valor) => valor.idSeccion === 69) && (
                  <div className="flex justify-between items-center">
                    <div className="text-right">
                      <p className="text-gray-600">{t('_deseas_crear_un_formato_nuevo_')}</p>
                      <p className="text-gray-600">{t('selecciona')}</p>
                    </div>
                    <div className="self-center ml-4">
                      <GreenButton
                        variant="contained"
                        color="inherit"
                        startIcon={<CreateIcon />}
                        fullWidth
                        onClick={() => history.push('/personalizacion-formatos-consulta')}
                      >
                        {t('crear_formato')}
                      </GreenButton>
                    </div>
                  </div>
                )}
              </div>
              <div>
                {secciones.length > 0
                  && secciones.find((valor) => valor.idSeccion === 68)
                  && selFormato === 'mis_formatos'
                  && !!catMisFormatos.length && (
                    <>
                      <TablaFMCPropios
                        motivosConsultaPropios={catMisFormatos}
                        enOrganizacion={enOrganizacion}
                        handleEditar={handleEditar}
                        handleBorrar={handleBorrar}
                        handleCompartir={handleCompartir}
                        handleVerFormato={handleVerFormatoPropios}
                      />
                      <div className="flex justify-center mt-4">
                        <Pagination
                          count={totalPaginasMisFormatos}
                          page={paginaMisFormatos}
                          onChange={handleChangePaginaMisFormatos}
                          shape="rounded"
                        />
                      </div>
                    </>
                )}
                {secciones.length > 0
                  && secciones.find((valor) => valor.idSeccion === 68)
                  && selFormato === 'formatos_compartidos'
                  && enOrganizacion
                  && !!catFormatosCompartidos.length && (
                    <>
                      <TablaFMCCompartidos
                        motivosConsultaCompartidos={catFormatosCompartidos}
                        handleVerFormato={handleVerFormatoPropios}
                      />
                      <div className="flex justify-center mt-4">
                        <Pagination
                          count={totalPaginasFormatosCompartidos}
                          page={paginaFormatosCompartidos}
                          onChange={handleChangePaginaFormatosCompartidos}
                          shape="rounded"
                        />
                      </div>
                    </>
                )}
                {secciones.length > 0
                  && secciones.find((valor) => valor.idSeccion === 67)
                  && selFormato === 'medipraxi'
                  && !!catMedipraxi.length && (
                    <>
                      <TablaFMCMedipraxi
                        motivosConsultaMedipraxi={catMedipraxi}
                        handleHabilitarUso={handleHabilitarUso}
                        handleVerFormato={handleVerFormatoMedipraxi}
                      />
                      <div className="flex justify-center mt-4">
                        <Pagination
                          count={totalPaginasMedipraxi}
                          page={paginaMedipraxi}
                          onChange={handleChangePaginaMedipraxi}
                          shape="rounded"
                        />
                      </div>
                    </>
                )}
              </div>
            </div>
        )}
      </div>
    </div>
  );
}

export default FormatosMC;
